import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { isMobileOnly } from 'react-device-detect';
import { getUrlParams } from '../../utils/helper';
import { subdomain } from '../../config';
import BottomPopup from './BottomPopup';
import BottomPopup2 from './BottomPopup2';
import Result from './Result';
import Dynamic from './Dynamic';

const Meta = () => (
  <Helmet>
    <title>Familienurlaub buchen leicht gemacht | holidayfind.de</title>
    <meta name="description" content="Ferienhotels, die Freude machen: Mit holidayfind.de buchen Sie
     Familienurlaub, der alle Wünsche Ihrer Familie erfüllt. Individuell und klimafreundlicher." />
    <meta name="keywords" content="familienurlaub passend, familienurlaub günstig" />
  </Helmet>
);

const Book = ({ location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Bottom = () => !isMobileOnly && (subdomain === 'aktion' ? <BottomPopup /> : <BottomPopup2 />);

  const { g: giata, s: selectioncode } = getUrlParams(location.search);
  if (giata && selectioncode) {
    return (
      <React.Fragment>
        <Meta />
        <Dynamic giata={giata} selectioncode={selectioncode} />
        <Bottom />
      </React.Fragment>
    );
  } else if (location.giata) {
    return (
      <React.Fragment>
        <Meta />
        <Result giata={location.giata} />;
        <Bottom />
      </React.Fragment>
    );
  }

  return <Redirect to="/unsere-angebote" />;
};

Book.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
};

export default Book;
