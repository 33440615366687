import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImageGallery from 'react-image-gallery';
import LinesEllipsis from 'react-lines-ellipsis';
import { add, remove } from '../../../actions/wishlist';
import { show } from '../../../actions/popup';
import Ratings from './Ratings';
import PopupInfo from './PopupInfo';
import ScoreBlock from './ScoreBlock';

const onReflow = (giata, stars) => { // { clamped, text }
  // TODO: use ref
  const htmlH1 = document.getElementById(`hotel_${giata}_title`);
  if (!htmlH1.getElementsByTagName('ul').length) {
    const htmlUl = document.createElement('ul');
    htmlUl.setAttribute('class', 'hotel_starts');

    htmlH1.style.textIndent = 0;
    if (htmlH1.offsetHeight > 50) htmlUl.style.position = 'absolute'; // 2 lines
    stars.forEach(() => htmlUl.appendChild(document.createElement('li')));

    htmlH1.appendChild(htmlUl);
  }
};

const mapStateToProps = ({ wishlist }, { giata, selectioncode }) => ({
  selectioncode,
  isWished: !!wishlist.list.find(item => (
    item.giata === giata && item.selectioncode === selectioncode
  )),
});
const mapDispatchToProps = dispatch => bindActionCreators({
  addItem: add,
  removeItem: remove,
  showPopup: show,
}, dispatch);

const Hotel = ({
  giata, images, map, matchlist, mismatchlist, name, ratings, greenRating, stars, catalogData,
  selectioncode, isWished, addItem, removeItem,
  showScorePopup, showMapPopup, showReviewPopup, showGalleryPopup, showPopup,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const onResize = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  });

  return (
    <div className="hotel_data">
      <div className="hotel_img">
        <ImageGallery
          items={(images || []).map(({ original }, index) => ({
            original: original.replace('size=350', 'size=629'),
            index,
          }))}
          resizeMode="contain"
          lazyLoad
          showFullscreenButton={false}
          showPlayButton={false}
          showIndex
          slideInterval={2000}
          showThumbnails={false}
          additionalClass="app-image-gallery"
          renderItem={({ original, index }) => <img data-index={index} src={original} alt="" />}
          onClick={({ target }) => showGalleryPopup(+target.getAttribute('data-index'))}
        />
      </div>
      <div className="hotel_information">
        <header>
          <LinesEllipsis
            key={width}
            component="h1"
            id={`hotel_${giata}_title`}
            style={{ textIndent: 25 * stars.length }} // space for stars, will be removed later
            text={name}
            maxLine="2"
            onReflow={() => onReflow(giata, stars)}
          />
          {map && <button type="button" className="map_btn" onClick={showMapPopup} />}
        </header>
        <div className="hotel_activity" />
        <div className="hotel_raiting">
          <div className="hotel_like_btn" />
          {(ratings.holidaycheck || ratings.tripadvisor) && <Ratings {...ratings} />}
          {selectioncode && (
            <button
              type="button"
              className={`book_like_btn${isWished ? ' active' : ''}`}
              onClick={() => (isWished ? removeItem(giata, selectioncode) : addItem(giata))}
            />)}
          {greenRating !== undefined && (
            <button
              type="button"
              className={`book_shamrock book_shamrock_${greenRating}`}
              onClick={() => showPopup('climatehotel')}
            />
          )}
          <div className="hotel_comment_btn">
            <button type="button" className="btn" onClick={showReviewPopup}>Bewertungen lesen</button>
          </div>
        </div>
        <div className="clear" />
        <ScoreBlock matchlist={matchlist} mismatchlist={mismatchlist} showScorePopup={showScorePopup} />
        <div className="clear10" />
        <PopupInfo {...catalogData} name={name}/>
        <button
          type="button"
          className="btn hotel_choose_btn"
          onClick={() => {
            const offset = window.$('#frame_block').offset(); // jQuery is already used for OwlCarousel
            if (offset) window.$('html, body').animate({ scrollTop: offset.top + 250 }, 500);
          }}
        >Angebote vergleichen
        </button>
      </div>
      <div className="clear" />
    </div>
  );
};

Hotel.propTypes = {
  giata: PropTypes.string.isRequired,
  selectioncode: PropTypes.string.isRequired,
  isWished: PropTypes.bool.isRequired,
  addItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  showScorePopup: PropTypes.func.isRequired,
  showMapPopup: PropTypes.func.isRequired,
  showReviewPopup: PropTypes.func.isRequired,
  showGalleryPopup: PropTypes.func.isRequired,
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Hotel);
