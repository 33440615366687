import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from '../../actions/popup';

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  showPopup: show,
}, dispatch);

const ClimateFriendly = ({ isShown, showPopup, onClose }) => (isShown ? (
  <div className="popup">
    <div className="popup_block">
      <button type="button" className="close_btn" onClick={onClose} />
      <div className="popup_content" style={{ overflow: 'unset' }}>
        <div className="popup_body tl">
          <div className="sustainable_popup_icon" />
          <h1 className="part_headline logo_shamrock"><span>Klimafreundlicher & individueller reisen</span></h1>
          <p className="list_title list_title_1">So unterstützen wir unsere Kunden, wie sie „ihre“ klimafreundlichste Reise finden:</p>
          <p className="list_item">Durch <button type="button" onClick={() => showPopup('climatemap')}>
            <span className="bright_green">Hinweise,</span>
          </button> welche <b>Urlaubsländer</b> dafür die besten Werte ausweisen.</p>
          <p className="list_item">Durch <button type="button" onClick={() => showPopup('climatehotel')}>
            <span className="bright_green">Kennzeichnung</span>
          </button> der <b>Hotels/Ressorts,</b> die bereits auf klimafreundliche Bewirtschaftung achten.</p>
          <p className="list_item">Durch ein <button type="button" onClick={() => showPopup('climateairline')}>
            <span className="bright_green">Ranking</span>
          </button> der <b>Airlines,</b> die dank modernem Flugzeugpark und optimaler Maschinensauslastung die Umweltbelastung beim Reisen möglichst reduzieren.</p>
          <div className="clear20" />
          <div className="clear h_line" />
          <div className="clear20" />
          <p className="climate_rate">
            <button
              type="button"
              style={{ minWidth: 50, textAlign: 'left' }}
              onClick={() => showPopup('climatemap')}
            ><b className="f16">Länder:</b></button>
            <i className="icon_shamrock icon_shamrock_3" />
            <i className="icon_shamrock icon_shamrock_2" />
            <i className="icon_shamrock icon_shamrock_1" />
            <span> unterschiedlicher CO2 Verbrauch/Anreise</span>
          </p>
          <p className="climate_rate">
            <button
              type="button"
              style={{ minWidth: 50, textAlign: 'left' }}
              onClick={() => showPopup('climatehotel')}
            ><b className="f16">Hotels:</b></button>
            <span><i className="icon_shamrock icon_shamrock_4"/> GSTC anerkannte Nachhaltigkeitslabel</span>
            <span><i className="icon_shamrock icon_shamrock_3"/> Internationale - /Nationale Label</span>
            <span><i className="icon_shamrock icon_shamrock_2"/> auf einem nachhaltigen Weg</span>
            <span><i className="icon_shamrock icon_shamrock_1"/> eventuell nachhaltig (z.B. neu/renoviert)</span>
          </p>
          <p className="climate_rate">
            <button
              type="button"
              style={{ minWidth: 50, textAlign: 'left' }}
              onClick={() => showPopup('climateairline')}
            ><b className="f16">Airlines:</b></button>
            <span><i className="icon_shamrock icon_shamrock_3" /> = atmosfair <span className="class_letter class_letter_b">b</span></span>
            <span><i className="icon_shamrock icon_shamrock_2" /> = atmosfair <span className="class_letter class_letter_c">c</span></span>
            <span><i className="icon_shamrock icon_shamrock_1" /> = atmosfair <span className="class_letter class_letter_d">d</span></span>
            <span><i className="icon_shamrock icon_shamrock_0" /> = atmosfair <span className="class_letter class_letter_e">e</span></span>
            <span><b className="green">Ohne Hinweis: </b>nicht von atmosfair bewertet</span>
          </p>
          <div className="clear10" />
          <div className="clear h_line" />
          <div className="clear20" />
          <p className="list_title list_title_2 f16">Wir bieten unseren Kunden eine völlig neuartige Möglichkeit, individuelle Urlaubswünsche passend zu konfigurieren:</p>
          <p className="list_item f16">Sagt uns, was ihr im Urlaub erleben möchtet – allgemein oder sehr detailliert.</p>
          <p className="list_item f16">holidayfind.de findet für euch Länder und Hotels, bei denen sich eine möglichst optimale Übereinstimmung mit euren Wünschen ergibt.</p>
          <p className="list_item f16">Natürlich werden nur Angebote angezeigt, die eure individuellen Reisezeiten, Budgets und Abflughäfen konkret erfüllen.</p>
          <div className="clear20" />
          <p className="f16"><span className="logo_inline" /> setzt sich zum Ziel, individuell passende Reisen für eine erfüllte Ferienzeit zu finden. Wir bieten darüber hinaus jedem die Möglichkeit, seine ganz persönliche Vorstellung eines klimafreundlichen Urlaubs zu realisieren.</p>
          <p className="f13 nt_mb">Aktuell gibt es noch keine Datenbanken mit Anspruch auf Vollständigkeit, perfekte Vergleichbarkeit und Tagesaktualität. Wir haben uns bemüht, mit führenden Unternehmen der Klimaforschung und Umweltverbänden heute verfügbare Informationen zusammenzufassen. Diese werden laufend aktualisiert. Selbstverständlich kann dies nur zur individuellen Orientierung dienen und stellt keinen Garantieanspruch dar.</p>
        </div>
        <div className="clear" />
      </div>
    </div>
  </div>
) : null);

export default connect(mapStateToProps, mapDispatchToProps)(ClimateFriendly);
