import React from 'react';

export default ({ isShown, onClose }) => (isShown ? (
  <div className="popup">
    <div className="popup_block popup_climate_map_block">
      <button type="button" className="close_btn" onClick={onClose} />
      <div className="popup_content" style={{ overflow: 'unset' }}>
        <div className="popup_body tl">
          <h1 className="climate_map_headline logo_shamrock"><span>CO<sub>2</sub>-Emissionen bei Urlaubsflügen</span></h1>
          <div>
            <p className="climate_zone">
              <i className="icon_shamrock icon_shamrock_3" />
              <span>Zone 0 =     –0,20 t CO2</span>
              <span>Zone 1 = 0,20–0,26 t CO2</span>
            </p>
            <p className="climate_zone">
              <i className="icon_shamrock icon_shamrock_2" />
              <span>Zone 2 = 0,26–0,40 t CO2</span>
              <span>Zone 3 = 0,40–0,60 t CO2</span>
            </p>
            <p className="climate_zone">
              <i className="icon_shamrock icon_shamrock_1" />
              <span>Zone 4 = 0,60–0,90 t CO2</span>
              <span>Zone 5 = 0,90–1,30 t CO2</span>
            </p>
            <p className="climate_zone">
              <i className="icon_shamrock icon_shamrock_0" />
              <span>Zone 6 = 1,30–2,00 t CO2</span>
              <span>Zone 7 = 2,00–3,40 t CO2</span>
            </p>
          </div>
          <div className="clear10" />
          <p className="nt_mb map_block_text"><b>Die Werte beziehen sich auf EINEN <br />Direktflug von Zentraleuropa aus. <br />Empfehlung:</b> Zwischenstopps und -starts vermeiden.</p>
          <p className="f13  map_block_text"><b>Quelle:</b> myclimate.org, 2019.</p>
          <div className="clear20" />
          <p className="f28"><b>Wie gut sind Länder beim Klimaschutz</b></p>
          <div className="clear" />
          <div className="climate_map_zone_block">
            <span className="climate_map_zone climate_map_zone_0">Sehr gut</span>
            <span className="climate_map_zone climate_map_zone_3">Schlecht</span>
            <span className="climate_map_zone climate_map_zone_1">Gut</span>
            <span className="climate_map_zone climate_map_zone_4">Sehr schlecht</span>
            <span className="climate_map_zone climate_map_zone_2">Mäßig</span>
            <span className="climate_map_zone climate_map_zone_5">Nicht in der Bewertung</span>
          </div>
          <p className="map_source nt_mb"><b>Quelle:</b> Germanwatch, New Climate Institute, CAN/Climate Change Performance Index, 2019. Parameter für die Bewertung: CO2-Emissionen und Trends 40 %, Erneuerbare Energie 20 %, Energieeffizienz 20 %, Klimapolitik 20 %.</p>
        </div>
        <div className="clear" />
      </div>
    </div>
  </div>
) : null);
