import {
  select, fork, takeLatest, put, call,
} from 'redux-saga/effects';
import { RESULT } from '../actions/result';
import { RESTORE_SELECTION } from '../actions/restoreSelection';
import {
  getCountryIds, getUserParams, getSortedList, isDeepDive,
} from '../selectors/result';
import { loadGiatas, loadGiatasForCountry, loadOffers } from '../utils/Api';

const preloadCount = 42;

function* loadPortionsDetails(items, page) {
  const userParams = yield select(getUserParams);
  const portion = [];
  for (let i = 0; i < items.length; i++) {
    const {
      offer, tourOperatorList, hotel, error,
    } = yield call(loadOffers, { giataIdList: items[i].giata, ...userParams });
    if (offer) {
      portion.push({
        giata: items[i].giata, item: items[i], offer, hotel, tourOperatorList,
      });
    } else {
      console.log(error || 'offer is empty');
    }
  }
  if (page !== 1) {
    yield put({ type: RESULT.QUEUE_DONE, queue: portion, page });
  }
  return portion;
}

function* loadQueue(queue) {
  let page = 1;
  let count = queue.length;
  yield put({ type: RESULT.QUEUE_DONE, queue, page });

  const country = yield select(getCountryIds);
  const { selectionid } = yield select(({ countries }) => countries);

  let hasError = false;
  let isCancelled = false;
  while (!isCancelled && !hasError && count < preloadCount) {
    const backend = yield call(loadGiatas, selectionid, { country, page: ++page });
    if (backend.status !== 'success' || !backend.items.length) {
      hasError = true;
    } else {
      isCancelled = yield select(({ result }) => result.isCancelled);
      if (!isCancelled) {
        for (let i = 0; i < backend.items.length; i++) {
          yield call(loadPortionsDetails, [backend.items[i]], page);
        }
      }
      count += backend.items.length;
    }
  }
}

function* loadList() {
  const { page } = yield select(({ result }) => result);
  yield put({ type: RESULT.LIST_STARTED });
  try {
    const country = yield select(getCountryIds);
    const { selectionid } = yield select(({ countries }) => countries);
    const isDD = yield select(isDeepDive);
    const {
      items, selectioncode, changed_options: params, status,
    } = yield call(
      isDD ? loadGiatasForCountry : loadGiatas,
      selectionid,
      { country, ...(page > 1 ? { page } : {}) },
    );
    if (status === 'success') {
      if (params) {
        yield put({ type: RESTORE_SELECTION.LOAD_DONE, params });
      }
      const portion = yield call(
        loadPortionsDetails,
        items.length > 40 ? items.slice(0, 40) : items,
        page,
      );
      if (!portion.length) {
        yield put({ type: RESULT.LIST_FAILED, message: 'not enough results' });
        // prevent requests hotel details
      } else if (yield select(({ result }) => result.isCancelled)) {
        yield put({ type: RESULT.LIST_FAILED, message: 'list cancelled' });
      } else {
        yield put({ type: RESULT.LIST_DONE, portion, selectioncode });
        if (isDD) {
          yield put({ type: RESULT.LIST_FAILED, message: 'finished' });
        } else if (page === 1) {
          yield fork(loadQueue, portion);
        }
      }
    } else {
      yield put({ type: RESULT.LIST_FAILED, message: 'error loading hotels' });
    }
  } catch (message) {
    yield put({ type: RESULT.LIST_FAILED, message });
  }
}

function* loadSelectionCode() {
  try {
    const country = yield select(getCountryIds);
    const { selectionid } = yield select(({ countries }) => countries);
    const isDD = yield select(isDeepDive);
    const { selectioncode, status } = yield call(
      isDD ? loadGiatasForCountry : loadGiatas,
      selectionid,
      { country },
    );
    if (status === 'success' && selectioncode) {
      yield put({ type: RESULT.LOAD_SELECTION_CODE_DONE, selectioncode });
    } else {
      yield put({ type: RESULT.LOAD_SELECTION_CODE_FAILED, message: 'error loading selection code' });
    }
  } catch (message) {
    yield put({ type: RESULT.LOAD_SELECTION_CODE_FAILED, message });
  }
}

function* sort() {
  const list = yield select(getSortedList);
  yield put({ type: RESULT.SORT_BY_GROUP_DONE, list });
}

export default function* () {
  yield [
    fork(function* () { yield takeLatest(RESULT.LIST, loadList); }),
    fork(function* () { yield takeLatest(RESULT.LOAD_SELECTION_CODE, loadSelectionCode); }),
    fork(function* () { yield takeLatest(RESULT.SORT_BY_GROUP, sort); }),
  ];
}
