import React, { useEffect } from 'react';
import GoogleAnalytics from 'react-ga';

GoogleAnalytics.initialize(window.gtagid, {
  ...(window.location.hostname === 'localhost' ? { debug: false} : {}),
});
GoogleAnalytics.set({ anonymizeIp: true });

export default (WrappedComponent, options = {}) => {
  const trackPage = page => {
    GoogleAnalytics.set({ page, ...options });
    GoogleAnalytics.pageview(page);
  };

  return props => {
    useEffect(() => {
      trackPage(props.location.pathname);
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };
};
