import { COOKIES_SETTINGS } from "../actions/cookiesStart";

const defaultState = {
  notwendig: true,
  statistik: true,
  personalisierung: true,
};

export default (state = defaultState, { type, ...action }) => {  
  switch (type) {
    case COOKIES_SETTINGS.SOME_CHECKED:
      return { ...state, ...action.setting };

    case COOKIES_SETTINGS.ALL_CHECKED:
      return {
        ...state,
        notwendig: true,
        statistik: true,
        personalisierung: true,
      };

    default:
      return state;
  }
};
