import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const mapStateToProps = ({ result: { lastActivity } }) => ({
  lastActivity,
});

const NotActive30 = ({ lastActivity }) => {
  const [isInactivePopupShowed, setIsInactivePopupShowed] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Math.round(new Date().getTime() / 1000 / 60); // min
      if (now - lastActivity === 30) { // 30 min inactive
        setIsInactivePopupShowed(true);
        clearInterval(intervalId);
      }
    }, 10000); // check every 10 sec

    return () => clearInterval(intervalId);
  }, []);

  if (!isInactivePopupShowed) {
    return null;
  }

  return (
    <div className="popup">
      <div className="popup_block_inactivity">
        <h2>
          Du warst länger als 30 Min. nicht auf dieser Seite aktiv. <span>Das kann Einfluss auf die
          gefundenen Reisevorschläge haben. Bitte entscheide du bitte deshalb:</span>
        </h2>
        <button
          type="button"
          className="close_btn"
          onClick={() => setIsInactivePopupShowed(false)}
        />
        <div>
          <button
            type="button"
            className="btn fl"
            onClick={() => setIsInactivePopupShowed(false)}
          >
            <b>Länderauswahl beibehalten</b>
          </button>
          <button
            type="button"
            className="btn fr"
            onClick={() => window.location.reload()}
          >
            <b>Länderauswahl NEU</b>
          </button>
          <div className="clear" />
        </div>
      </div>
    </div>
  );
};

NotActive30.propTypes = {
  lastActivity: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(NotActive30);
