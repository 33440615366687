const isLocal = window.location.hostname === "localhost";

const localApiHost = "https://holidayfind.de"; // http://holidayfindapi.local

const externalAPI = "https://backend2.holidayfind.de/api";

export const subdomain =
  window.location.hostname === "aktion.holidayfind.de" ? "aktion" : "";

export const googleMapsKey = "AIzaSyBS-X-s1o0vDkv33lqEmzw24X7DddQDJJY";

export const apiInternal = "https://backend2.holidayfind.de/api";

// export const apiExternal = `${localApiHost}/api/v1`;
// export const apiExternal = `${externalAPI}`;

export const apiExternal = (() =>
  isLocal ? `${localApiHost}/api/v1` : `${externalAPI}`)();

export const bookingExternal =
  "https://booking.holidayfind.de/1100000210000000";
export const quid = "5c9261adceb20";

export const individual_link = "https://holidayfind.de/individual";
export const classic_link = "https://holidayfind.de/klassik";
