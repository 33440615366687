import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';

const Gallery = ({ images, startIndex }) => (
  <React.Fragment>
    <div className="popup_body">
      <ImageGallery
        items={images.map(({ original }) => ({ original: original.replace('size=350', 'size=1030') }))}
        startIndex={startIndex}
        lazyLoad
        showFullscreenButton={false}
        showPlayButton={false}
        showIndex
        slideInterval={2000}
        showThumbnails={false}
        additionalClass="app-image-gallery"
      />
    </div>
  </React.Fragment>
);

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  startIndex: PropTypes.number.isRequired,
};

export default Gallery;
