import {
  fork, takeLatest, put, call,
} from 'redux-saga/effects';
import { REVIEWS } from '../actions/reviews';
import { loadHotelReviews } from '../utils/Api';

function* loadHotel({ giata }) {
  try {
    const { hotelReviewList: list } = yield call(loadHotelReviews, giata);
    if (list) {
      yield put({ type: REVIEWS.LOAD_DONE, giata, list });
    } else {
      yield put({ type: REVIEWS.LOAD_FAILED, giata, message: 'empty hotel reviews' });
    }
  } catch (message) {
    yield put({ type: REVIEWS.LOAD_FAILED, giata, message });
  }
}

export default function* () {
  yield [
    fork(function* () { yield takeLatest(REVIEWS.LOAD, loadHotel); }),
  ];
}
