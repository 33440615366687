import React from 'react';
import PropTypes from 'prop-types';

const Price = ({
  giata, selectioncode, personPrice, duration, tourOperator,
}) => (
  <button
    type="button"
    className="price_block"
    onClick={() => window.location.replace(`/reisebuchung?g=${giata}&s=${selectioncode}`)}
  >
    <div className="price_block_top">
      <span className="ab">ab</span>
      <p className="price">{personPrice}<span>&euro;</span></p>
      <div className="clear" />
      <p className="days">p.P. für {duration} Tage</p>
    </div>
    <p className="price_description">{tourOperator}</p>
  </button>
);

Price.propTypes = {
  giata: PropTypes.string.isRequired,
  selectioncode: PropTypes.string.isRequired,
  personPrice: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  tourOperator: PropTypes.string.isRequired,
};

export default Price;
