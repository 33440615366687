import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { load } from '../../../../actions/reviews';
import Review from './Review';
import Rating from './Rating';

const mapStateToProps = (state, ownProps) => ({
  list: state.reviews[ownProps.giata],
});
const mapDispatchToProps = dispatch => bindActionCreators({
  load,
}, dispatch);

class Reviews extends React.Component {
  componentDidMount() {
    const { props } = this;
    const { list, giata } = props;
    if (!list) props.load(giata);
  }

  render() {
    const { list, name } = this.props;
    if (!list) {
      return <div className="popup_content"><div className="popup_header"><h1>Aktuelle Bewertungen werden geladen</h1></div></div>;
    }

    return (
      <React.Fragment>
        <div className="popup_content">
          <div className="popup_header">
            <h1>{name}</h1>
          </div>
          <div className="popup_body">
            <table className="review_table common_review">
              <tbody>
                <Rating
                  title="GESAMTBEWERTUNG"
                  number={list.reduce((res, { rating }) => (res + rating.overall), 0) / list.length}
                />
                <Rating
                  title="GASTRONOMIE"
                  number={list.reduce((res, { rating }) => (res + rating.food), 0) / list.length}
                />
                <Rating
                  title="HOTEL"
                  number={list.reduce((res, { rating }) => (res + rating.hotel), 0) / list.length}
                />
                <Rating
                  title="LAGE"
                  number={list.reduce((res, { rating }) => (res + rating.location), 0) / list.length}
                />
                <Rating
                  title="ZIMMER"
                  number={list.reduce((res, { rating }) => (res + rating.room), 0) / list.length}
                />
                <Rating
                  title="SERVICE"
                  number={list.reduce((res, { rating }) => (res + rating.service), 0) / list.length}
                />
              </tbody>
            </table>
            <div className="clear h_line" />
            {list.map((review, i) => <Review key={i} {...review} />)}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Reviews.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
};

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
