import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Redirect, Link } from "react-router-dom";

import "./startScreen.module.css";

import { changeLogo } from "../../../actions/logoHeader";
import { setSomeCookies, setAllCookies } from "../../../actions/cookiesStart";

export default function StartScreen({ setMenu }) {
  const dispatch = useDispatch();
  const { notwendig, statistik, personalisierung } = useSelector(({ cookiesStart }) => cookiesStart);
  const [isMenuOpen, openMenu] = useState(false);
  const media = useMediaQuery("(max-width:800px)");

  const textChooseCookies = React.useRef();

  const openCookiesMenu = () => {
    textChooseCookies.current.classList.add("classToClick");
    openMenu(true);
  };

  const onCLickContinue = () => {
    setMenu();
    dispatch(changeLogo("newLogo"));
  };

  const handlerCookie = (e) => {
    const nameSetting = e.target.name;
    const checked = e.target.checked;

    dispatch(setSomeCookies({ [nameSetting]: checked }));
  };

  const currentCook = document.cookie.split("; ").find((item) => item === "cookie_accepted=true");

  if (currentCook) {
    dispatch(setAllCookies());
    onCLickContinue();
  }

  return (
    <>
      <div className="chooseCookie">
        <a
          className="img"
          href="https://holidayfind.de/individual/allgemeine-geschaeftsbedingungen#prüfung_18"
          target="_blank"
        >
          <img className="crowd-investing-mobile" style={{ width: "142px" }} src="/images/Button-KI.svg" />
        </a>
        <div
          className="chooseCookie__text"
          ref={textChooseCookies}
          style={media && isMenuOpen ? { paddingBottom: "20px" } : {}}
        >
          <h3>Wir haben ein paar Urlaubscookies für euch!</h3>
          <p>
            Ihr habt die Wahl, ob das nur die unbedingt Notwendigen für den Betrieb der Website sind oder ob auch
            Cookies zur anonymen statistischen Analyse zugelassen werden. Weitere Infos findet ihr in unserer{" "}
            <Link to="/datenschutz">Datenschutzerklärung</Link>.
          </p>
          <div className="chooseCookie__cookiesMenu">
            <div className="checkbox_block">
              <label id={notwendig ? "checkedCookieParam" : ""}>
                <input
                  type="checkbox"
                  name="notwendig"
                  defaultChecked={true}
                  // onChange={handlerCookie}
                />
                Notwendig
              </label>
              <label id={statistik ? "checkedCookieParam" : ""}>
                <input type="checkbox" name="statistik" checked={statistik} onChange={handlerCookie} />
                Statistik
              </label>
              <label id={personalisierung ? "checkedCookieParam" : ""}>
                <input type="checkbox" name="personalisierung" checked={personalisierung} onChange={handlerCookie} />
                Personalisierung
              </label>
            </div>
            <div className="text">
              <h4>Notwendig</h4>
              <p>zum reibungslosen Betrieb von holidayfind.de</p>
              <h4>Statistik</h4>
              <p>zur Optimierung von holidayfind.de durch anonymisierte Informationen.</p>
              <h4>Personalisierung</h4>
              <p>
                zum anonymen temporären Speichern von Merklisten, Reisedaten etc. – erst dadurch ist es möglich z.B.
                persönliche Wünsche mit Hotelsservices zu matchen.
              </p>
            </div>
            <div className="btn_agree_cookies">
              <button
                type="button"
                onClick={() => {
                  if (notwendig && statistik && personalisierung) {
                    document.cookie = "cookie_accepted=true; domain=holidayfind.de";
                  }

                  onCLickContinue();
                }}
              >
                Auswahl akzeptieren
              </button>
              <button
                type="button"
                onClick={() => {
                  dispatch(setAllCookies());
                  onCLickContinue();
                }}
              >
                Alle akzeptieren
              </button>
            </div>
          </div>
        </div>
        <div className="chooseCookie__buttons" style={media && isMenuOpen ? { display: "none" } : {}}>
          <button
            type="button"
            onClick={() => {
              dispatch(setAllCookies());
              onCLickContinue();
              document.cookie = "cookie_accepted=true; domain=holidayfind.de";
            }}
          >
            Alle Cookies zulassen
          </button>
          <button type="button" onClick={openCookiesMenu}>
            Einstellungen verwalten
          </button>
        </div>
      </div>
    </>
  );
}
