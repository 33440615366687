import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleCheckbox } from '../../../actions/budget';
import AcceptButton from '../AcceptButton';
import ListItem from './ListItem';

const mapStateToProps = state => ({
  list: state.budget.list,
  selected: state.budget.selected,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  select: toggleCheckbox,
}, dispatch);

class Budget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  }

  render() {
    const { isExpanded } = this.state;
    const { list, selected, select } = this.props;
    const selectedId = +Object.keys(selected)[0];
    const selectedItem = list.find(item => item.id === selectedId);
    const title = (selectedItem || {}).name || 'Bitte wählen';

    return (
      <div className={`content_block${isExpanded ? ' open' : ''} ${selectedItem ? '' : 'content_block_empty'}`}>
        <header onClick={this.toggle}>
          <p>
            <span>Urlaubs-Budget</span>
          </p>
        </header>
        <div className="content_block_body">
          <button
            type="button"
            className={`empty_block${isExpanded ? ' hidden' : ''}`}
            onClick={this.toggle}
          >
            <span>{title}</span>
            <i className="icon_down" />
          </button>
          {selectedItem && !isExpanded ? <div className="budget_head" /> : ''}
          <div className={`open_content_block headered${isExpanded ? '' : ' hidden'}`}>
            <button
              type="button"
              className="close_content_block_btn"
              onClick={this.toggle}
            ><i className="icon_down" />
            </button>
            <div className="clear" />
            <div className="head budget_header"><p className="black"><b>pro Erwachsenem</b></p></div>
            <div className="open_content_block_body buttoned">
              <ul className="data_list">{list.map(item => (
                <ListItem
                  key={item.id}
                  {...item}
                  isSelected={item.id === selectedId}
                  select={select}
                />))}
              </ul>
            </div>
            <AcceptButton onAccept={this.toggle} />
          </div>
        </div>
      </div>
    );
  }
}

Budget.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any),
  selected: PropTypes.objectOf(PropTypes.bool),
  select: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Budget);
