import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { subdomain } from '../../config';
import { show } from '../../actions/popup';
import Dropdown from './Dropdown';

const Wrapper = styled.div`
  position: absolute;
  top: 15px;
  right: -14px;
  color: #fff;
  font-size: 13px;
  padding: 3px;
  background: #8ccc03;
  transform: rotate(-6deg);
  z-index: 999;

  @media screen and (max-width: 860px) {
    display: none;
  }

  div {
    padding: 0 9px 3px 3px;
    border: 3px solid #fff;
    background: #8ccc03;
  }
  img {
    position: absolute;
    right: -8px;
    top: -6px;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 50%;
  }
  span {
    color: #000;
  }
`;

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  showPopup: show,
}, dispatch);

const Title = ({ showPopup }) => (
  <div className="triple section_title_block section_title_block_results">
    {subdomain === 'aktion' ? (
      <button type="button" onClick={() => showPopup('bestprice')}>
        <img className="partner_link" src="images/partner_logo.svg" alt="Logo" />
      </button>
    ) : (
      <Wrapper>
        <div>
          <Link to="/allgemeine-geschaeftsbedingungen#coronavirus">
            <img src="/images/logo_top.svg" alt="Logo" />
          </Link>
          <span>Storno-Garantie</span>
          <br />
          wegen des
          <br />
          Coronavirus
        </div>
      </Wrapper>
    )}
    <div className="family_top_block">
      <div className="family_img header_family_img"><img src="images/animation/family3.svg" alt="Family" /></div>
    </div>
    <div className="section_title result_section_title">
      <Dropdown />
      <div className="fl">
        <h1>Ideale Urlaubsvorschläge für eure Familie:</h1>
        <div className="clear" />
        <h5>
          Flug / Hotel optimal abgestimmt auf <b><span className="green">eure ausgewählten,</span><span className="red"> persönlichen Urlaubs-Interessen</span></b>
        </h5>
      </div>
    </div>
    <div className="clear" />
  </div>
);

Title.propTypes = {
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Title);
