import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { unselectAll } from '../../../actions/departures';

const mapStateToProps = ({ departures }) => ({
  isNewUser: departures.isNewUser,
  selectedCount: Object.keys(departures.selected).length,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  reset: unselectAll,
}, dispatch);

const NoHotel = ({ isNewUser, selectedCount, reset }) => (
  <div className="data_block">
    <button type="button" className="open_data_block_btn" onClick={reset}>Nur Hotel / Ohne Flug</button>
    <div className="category_checkbox">
      <input
        readOnly
        id="category_unselected"
        type="checkbox"
        checked={!isNewUser && !selectedCount}
        onClick={reset}
      />
      <label htmlFor="category_unselected" className="checkbox_view" />
    </div>
    <div className="clear" />
  </div>
);

NoHotel.propTypes = {
  isNewUser: PropTypes.bool.isRequired,
  selectedCount: PropTypes.number.isRequired,
  reset: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoHotel);
