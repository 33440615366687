import { BUDGET } from '../../actions/budget';
import { RESTORE_SELECTION } from '../../actions/restoreSelection';
import { getBudget } from '../../utils/APIMapper';

// get array of selected budget from previous session
const storageKey = 'budget';
const storage = localStorage.getItem(storageKey);

const defaultState = {
  list: [
    { id: 1, name: 'kein fixes Budget' },
    { id: 2, name: 'bis 500 EUR', max: 500 },
    { id: 3, name: 'bis 750 EUR', max: 750 },
    { id: 4, name: 'bis 1.000 EUR', max: 1000 },
    { id: 5, name: 'bis 1.500 EUR', max: 1500 },
    { id: 6, name: 'bis 2.000 EUR', max: 2000 },
    { id: 7, name: 'bis 2.500 EUR', max: 2500 },
    { id: 8, name: 'bis 3.000 EUR', max: 3000 },
    { id: 9, name: 'bis 3.500 EUR', max: 3500 },
    { id: 10, name: 'über 3.000 EUR', min: 3000 },
  ],
  selected: {},
};
if (storage) {
  // restore selected
  JSON.parse(storage).forEach((id) => { defaultState.selected[id] = true; });
}

export default (state = defaultState, { type, ...action }) => {
  let selectedId;
  let budget;

  switch (type) {
    case BUDGET.TOGGLE_CHECKBOX:
      return { ...state, selected: { [action.id]: true } };

    case RESTORE_SELECTION.LOAD_DONE:
      budget = getBudget(action.params);
      if (!budget) {
        return state;
      }
      state.list.forEach(({ id, min, max }) => {
        if ((min && budget.min === min) || (max && budget.max === max)) selectedId = +id;
      });
      if (selectedId) {
        localStorage.setItem(storageKey, JSON.stringify([selectedId]));
        return { ...state, selected: { [selectedId]: true } };
      }
      return state;

    case BUDGET.SUBMIT:
      localStorage.setItem(
        storageKey,
        JSON.stringify(Object.keys(state.selected).map(key => +key)),
      );
      return state;

    default:
      return state;
  }
};
