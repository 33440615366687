import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AcceptButton from '../AcceptButton';
import Counters from './Counters';
import Sex from './Sex';

const mapStateToProps = ({ person }) => ({
  person,
  selected: person.selected,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

class Person extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
    };

    this.toggle = this.toggle.bind(this);
    this.getCountersText = this.getCountersText.bind(this);
    this.getSexText = this.getSexText.bind(this);
    this.getInfoText = this.getInfoText.bind(this);
  }

  getCountersText() {
    const { person, selected } = this.props;
    return Object.keys(person.counters).filter(key => selected.counters[key]).map(key => (
      `${selected.counters[key]} ${person.counters[key]}`
    ));
  }

  getSexText() {
    const { person, selected } = this.props;
    return Object.keys(person.sex).filter(key => selected.sex[key]).map(key => person.sex[key]);
  }

  getInfoText() {
    const { person, selected } = this.props;
    return Object.keys(person.info).filter(key => selected.info[key]).map(key => person.info[key]);
  }

  toggle() {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  }

  render() {
    const { isExpanded } = this.state;
    const selectedText = [].concat(this.getCountersText(), this.getSexText(), this.getInfoText());

    return (
      <div className={`content_block${isExpanded ? ' open' : ''} ${selectedText ? '' : 'content_block_empty'}`}>
        <header onClick={this.toggle}>
          <p>
            <span>Urlaubs-Team</span>
          </p>
        </header>
        <div className="content_block_body">
          <button
            type="button"
            className={`empty_block${isExpanded ? ' hidden' : ''}`}
            onClick={this.toggle}
          >
            <span>{selectedText.length ? selectedText.join(', ') : 'Bitte wählen'}</span>
            <i className="icon_down" />
          </button>
          <div className={`open_content_block${isExpanded ? '' : ' hidden'}`}>
            <button
              type="button"
              className="close_content_block_btn"
              onClick={this.toggle}
            ><i className="icon_down" />
            </button>
            <div className="head" />
            <div className="open_content_block_body buttoned">
              <Counters />
              <div className="clear5" />
              <Sex />
            </div>
            <AcceptButton onAccept={this.toggle} />
          </div>
        </div>
      </div>
    );
  }
}

Person.propTypes = {
  person: PropTypes.objectOf(PropTypes.object),
  selected: PropTypes.objectOf(PropTypes.object),
};

export default connect(mapStateToProps, mapDispatchToProps)(Person);
