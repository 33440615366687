import React from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import GoogleAnalytics from "react-ga";
import { isMobile } from "react-device-detect";
import { show } from "../../actions/popup";
import { subdomain } from "../../config";
import Wishlist from "./Wishlist";
import Call from "./Call";
import Save from "./Save";
import Ticker from "./Ticker";

const saveLocations = ["/reiselaender", "/unsere-angebote"];

import { changeLogo } from "../../actions/logoHeader";

const shouldShowTicker = (pathname) => pathname === "/urlaubsinteressen" && !isMobile;
const animationShowHeader = (pathname) => pathname === "/";

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showPopup: show,
    },
    dispatch
  );

const Index = ({ location, showPopup }) => {
  const logoHeader = useSelector((state) => state.logoHeader);
  const dispatch = useDispatch();
  return (
    <header className={`main_header ${location.pathname === "/" ? "home_page" : ""}`}>
      <div className={`content ${saveLocations.includes(location.pathname) && "four_icons"}`}>
        <div
          className="logo"
          onClick={() => {
            if (logoHeader !== "oldLogo") dispatch(changeLogo("oldLogo"));
          }}
        >
          <Link
            to="#"
            className="logo_link"
            onClick={() =>
              GoogleAnalytics.event({
                category: "Header",
                action: "Logo",
                label: "Click",
              })
            }
          />
          <img
            src={
              logoHeader === "oldLogo"
                ? "/images/Logo-holidayfind-Claim4.svg"
                : "/images/Logo-holidayfind-mit-Kachel-2-Wege-zum-Ziel.svg"
            }
            alt="Logo"
          />
          <a href="https://holidayfind.de/individual/allgemeine-geschaeftsbedingungen#prüfung_18" target="_blank">
            <img className="crowd-investing-header" src="/images/Button-KI.svg" />
          </a>
        </div>
        {!shouldShowTicker(location.pathname) && !animationShowHeader(location.pathname) && (
          <React.Fragment>
            <Wishlist />
            {["/reisebuchung"].includes(location.pathname) ? (
              ""
            ) : (
              <Link
                to="/kontakt"
                className="nav_btn"
                onClick={() =>
                  GoogleAnalytics.event({
                    category: "Header",
                    action: "Link",
                    label: "Contact",
                  })
                }
              >
                Hilfe & Kontakt
              </Link>
            )}
            <button
              type="button"
              className="sm_logo"
              onClick={() => {
                GoogleAnalytics.event({
                  category: "Header",
                  action: "Klimafreundlicher reisen",
                  label: "Click",
                });
                showPopup("climatefriendly");
              }}
            >
              Klimafreundlicher reisen
            </button>
            <Call />
            {saveLocations.includes(location.pathname) && <Save />}
          </React.Fragment>
        )}
      </div>
      {shouldShowTicker(location.pathname) && <Ticker />}
    </header>
  );
};

Index.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index));
