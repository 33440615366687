import React from 'react';
import PropTypes from 'prop-types';

const Ratings = ({ holidaycheck, tripadvisor }) => (
  <div className="hotel_raitings">
    {holidaycheck && <p>HolidayCheck <b>{holidaycheck}</b></p>}
    {tripadvisor && <p>Tripadvisor <b>{tripadvisor}</b></p>}
  </div>
);

Ratings.propTypes = {
  holidaycheck: PropTypes.string.isRequired,
  tripadvisor: PropTypes.string,
};

export default Ratings;
