import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { prepare } from '../../actions/categories';
import { submit as submitCalendarAction } from '../../actions/calendar';
import { submit as submitPersonsAction } from '../../actions/person';
import { submit as submitDurationAction } from '../../actions/duration';
import { submit as submitBudgetAction } from '../../actions/budget';
import { submit as submitDeparturesAction } from '../../actions/departures';
import { getPeople, getDuration, getCountryIds } from '../../selectors/result';
import PopupWelcome from '../RestoreSelection/PopupWelcome';
import Calendar from './Calendar';
import Person from './Person';
import Duration from './Duration';
import Budget from './Budget';
import Departure from './Departure';
import PopupCountry from './_PopupCountry';

const Buttons = styled.div`
  .btn {
    float: none !important;
    margin: 0 auto;
    display: block;
  }
`;

const mapStateToProps = ({
  categories: { selected, bufferid, req }, calendar, person, duration, budget, departures, countries,
}) => ({
  valid: Object.keys(selected).length ? true : false,
  req,
  bufferid,
  selectedCalendar: calendar,
  people: getPeople({ person }),
  selectedDuration: getDuration({ duration }),
  selectedBudget: budget.selected,
  selectedDepartures: departures.selected,
  isNewUser: departures.isNewUser,
  countries: getCountryIds({ countries }),
  isOptionsChanged: countries.isOptionsChanged,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  prepareCountries: prepare,
  submitCalendar: submitCalendarAction,
  submitPersons: submitPersonsAction,
  submitDuration: submitDurationAction,
  submitBudget: submitBudgetAction,
  submitDepartures: submitDeparturesAction,
}, dispatch);

const Options = ({
  valid, req, bufferid, prepareCountries,
  selectedCalendar, people, selectedBudget, selectedDuration, selectedDepartures, isNewUser,
  location, countries, isOptionsChanged, submitCalendar, submitPersons, submitDuration, submitBudget, submitDepartures,
}) => {
  if (!location.isRestore && !valid) {
    return <Redirect to="/urlaubsinteressen" />;
  }

  const [isPopupCountryShowed, setIsPopupCountryShowed] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (req && !bufferid) prepareCountries();
  }, []);

  const isSubmitAllowed = () => {
    if (
      !selectedCalendar.from || !selectedCalendar.to // Dates
      || !people.adult
      || !selectedDuration // Duration
      || !Object.keys(selectedBudget).length // Budget
      || (!Object.keys(selectedDepartures).length && isNewUser) // Departure
      || (selectedDuration.getMin ? selectedDuration.getMin() : selectedDuration.getExact())
      > Math.round((selectedCalendar.to - selectedCalendar.from) / 86400000)
    ) {
      return false;
    }

    return true;
  };

  const submit = (ev, forced) => {
    if (isSubmitAllowed()) {
      submitCalendar();
      submitPersons();
      submitDuration();
      submitBudget();
      submitDepartures();
      if (!forced && countries && isOptionsChanged) {
        ev.preventDefault();
        setIsPopupCountryShowed(true);
      }
    } else {
      ev.preventDefault();
    }
  };

  return (
    <div className="content">
      <Helmet>
        <title>Familienurlaub mit allen Optionen | holidayfind.de</title>
        <meta name="description" content="holidayfind.de findet den perfekten Familienurlaub – mit ausgesucht
         klimafreundlichen und individuell passenden Reisezielen – passend zu deinen Wünschen." />
        <meta name="keywords" content="familienurlaub buchen, meer familienurlaub, familienurlaub günstig,
         familienurlaub all inclusive" />
      </Helmet>
      {isPopupCountryShowed && <PopupCountry />}
      {location.isRestore && (
        <PopupWelcome
          button={
            isSubmitAllowed()
              ? <Link to="/reiselaender" className="btn" onClick={ev => submit(ev, true)}>Passende Länder finden</Link>
              : null
          }
        />)}
      <div className="section_title_block content_title">
        <h1>Wann und mit wem willst du reisen?</h1>
        <div className="clear" />
        <h5><span className="logo_inline logo_inline_sm" /> unterstützt <b>atmosfair</b> und andere NGOs jährlich mit 10 % vom Überschuss.</h5>
      </div>
      <div className="clear" />
      <div className="flex_content option_page">
        <Calendar />
        <Person />
        <Duration />
        <Budget />
        <Departure />
      </div>
      <div className="clear20" />
      <Buttons className="bottom_btns options_bottom_btns">
        <p className="comment f16 text_shadow" />
        <p className="tr fr"><Link className="f17 back_btn" to="/urlaubsinteressen">zurück</Link></p>
        <Link
          to="/reiselaender"
          className={`btn${isSubmitAllowed() ? '' : ' disabled'}`}
          onClick={submit}
        >Passende Länder finden
        </Link>
      </Buttons>
      <div className="clear" />
    </div>
  );
};

Options.propTypes = {
  valid: PropTypes.bool.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  selectedCalendar: PropTypes.objectOf(PropTypes.object),
  people: PropTypes.objectOf(PropTypes.number),
  selectedDuration: PropTypes.objectOf(PropTypes.any),
  selectedBudget: PropTypes.objectOf(PropTypes.bool),
  selectedDepartures: PropTypes.objectOf(PropTypes.bool),
  isNewUser: PropTypes.bool.isRequired,
  submitCalendar: PropTypes.func.isRequired,
  submitPersons: PropTypes.func.isRequired,
  submitDuration: PropTypes.func.isRequired,
  submitBudget: PropTypes.func.isRequired,
  submitDepartures: PropTypes.func.isRequired,
  countries: PropTypes.string.isRequired,
  isOptionsChanged: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Options);
