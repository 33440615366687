import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ScrollableAnchor from 'react-scrollable-anchor';
import { configureAnchors } from 'react-scrollable-anchor';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import { show } from '../../actions/popup';
import P01 from './p01';
import P02 from './p02';
import P03 from './p03';
import P04 from './p04';
import P14 from './p14';
import P15 from './p15';
import P16 from './p16';
import P17 from './p17';
import P18 from './p18';

configureAnchors({ offset: -140 });

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  showPopup: show,
}, dispatch);

const Agb = ({ history, showPopup }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content">
      <Helmet>
        <title>AGB – Allgemeine Geschäftsbedingungen | holidayfind.de</title>
        <meta name="description" content="Allgemeine Geschäftsbedingungen (AGB) der Blauwege GmbH für die
         Vermittlung von touristischen Leistungen über die Online-Reisesuche Holidayfind." />
        <meta name="keywords" content="holidayfind, holidayfind agb" />
      </Helmet>
      <div className="triple section_title_block section_title_block_landing">
        <button type="button" onClick={() => showPopup('bestprice')}>
          <img className="partner_link" src="images/partner_logo.svg" alt="Logo" />
        </button>
        <div className="family_top_block">
          <div className="family_img header_family_img"><img src="images/animation/family3.svg" /></div>
        </div>
        <div className="section_title">
          <h1>AGB</h1>
          <div className="clear" />
        </div>
        <div className="clear" />
      </div>
      <div className="clear" />
      <div className="text_content">
        <button type="button" className="f17 back_close_btn" onClick={history.goBack} />
        <h4 className="static_header">Unsere allgemeinen Geschäftsbedingungen</h4>
        <p>holidayfind.de ist eine Marke der BLAUWEGE GMBH. Im Folgenden finden sich die allgemeinen Geschäftsbedingungen der BLAUWEGE GMBH
          für die Vermittlung von touristischen Leistungen.</p>
        <ol>
          <li><a href="#scope">Geltungsbereich</a></li>
          <li><a href="#contract">Vertragsinhalt</a></li>
          <li><a href="#conclusion">Vertragsabschluss</a></li>
          <li><a href="#liability">Haftung von BLAUWEGE GMBH als Vermittler / Haftungsbeschränkungen</a></li>
          <li><a href="#service_documents">Reise-/Leistungsunterlagen, Buchungsbestätigung</a></li>
          <li><a href="#payment">Zahlung des Reisepreises an den jeweiligen Reiseveranstalter und/oder die Leistungsträger</a></li>
          <li><a href="#cancellation">Umbuchung und Stornierung</a></li>
          <li><a href="#obligations">Pflichten des Nutzers</a></li>
          <li><a href="#insurance">Versicherungen</a></li>
          <li><a href="#documents">Visa, Pass, Zoll, Devisen- und Gesundheitsbestimmungen</a></li>
          <li><a href="#prohibition">Abtretungsverbot</a></li>
          <li><a href="#third_party_websites">Links zu Websites Dritter</a></li>
          <li><a href="#jurisdiction">Gerichtsstand und Rechtsstatut</a></li>
          <li><a href="#dispute_settlement">Hinweis zur Streitbeilegung gemäß Verbraucherstreitbeilegungsgesetz</a></li>
          <li><a href="#provisions">Schlussbestimmungen</a></li>
          <li><a href="#annex">Annex</a></li>
          <li><a href="#coronavirus">Storno-Garantie wg. Coronavirus</a></li>
        </ol>
        <div className="clear20"></div>
        <ScrollableAnchor id="scope"><h4>1. Geltungsbereich</h4></ScrollableAnchor>
        <P01 />
        <ScrollableAnchor id="contract"><h4>2. Vertragsinhalt</h4></ScrollableAnchor>
        <P02 />
        <ScrollableAnchor id="conclusion"><h4>3. Vertragsabschluss</h4></ScrollableAnchor>
        <P03 />
        <ScrollableAnchor id="liability"><h4>4. Haftung von BLAUWEGE GMBH als Vermittler / Haftungsbeschränkungen</h4></ScrollableAnchor>
        <P04 />
        <ScrollableAnchor id="service_documents"><h4>5. Reise-/Leistungsunterlagen, Buchungsbestätigung</h4></ScrollableAnchor>
        <p>5.1 Der vom Nutzer getätigte Buchungsauftrag ist erst zu dem Zeitpunkt seitens BLAUWEGE GMBH angenommen, an dem dem Nutzer die Buchungsbestätigung zugegangen ist. Der Nutzer ist verpflichtet, die zugegangene Buchungsbestätigung unverzüglich zu überprüfen und innerhalb von 48 Stunden (bei Kurzfristbuchungen zwingend vor Reiseantritt) BLAUWEGE GMBH auf eventuelle Abweichungen oder Unvollständigkeiten im Vergleich zur ursprünglichen Buchung, auch in Bezug auf exakte Namensübereinstimmung hinzuweisen. Zu spät angezeigte Abweichungen und Unvollständigkeiten berechtigen insbesondere nicht zum Rücktritt vom Vertrag.</p>
        <p>5.2 Bei Buchungen von Pauschalreisen und/oder Einzelleistungen erhält der Nutzer seine Reiseunterlagen per Post, per E-Mail oder am Flughafenschalter, je nach Kurzfristigkeit der Reise und nach Bedingungen der jeweiligen Reiseveranstalter und/oder Leistungsträger. Bei Hinterlegung am Flughafenschalter können zusätzliche Gebühren anfallen. Die Details können den allgemeinen Geschäftsbedingungen der jeweiligen Reiseveranstalter und/oder Leistungsträger entnommen werden. Der Nutzer ist angehalten die angegebenen Kontaktdaten, speziell die E-Mail Adresse, detailliert zu überprüfen, da die Buchungs- und Reiseunterlagen an diese Kontaktdaten gesendet werden. In diesem Zusammenhang sind auch die sogenannten Spam-/Junk-Email Ordner zu prüfen, ob dort ggf. entsprechende E-Mails eingegangen sind. Nur so kann eine schnelle und reibungslose Kommunikation ermöglicht werden, speziell auch für kurzfristige Buchungen.</p>
        <p>5.3 Die EG-Verordnung Nr. 2111 vom 14.12.05 verpflichtet auch Reisevermittler und Vermittler von Beförderungsverträgen (neben Reiseveranstaltern), Reisende vor der entsprechenden Flugbeförderung über die Identität jeder ausführenden Fluggesellschaft zu unterrichten, sobald diese feststeht. Soweit dies bei Buchung noch nicht der Fall ist, muss zunächst die wahrscheinlich ausführende Fluggesellschaft angegeben werden. Bei Wechsel der ausführenden Fluggesellschaft nach erfolgter Buchung ist der Nutzer unverzüglich zu unterrichten. Für die Einhaltung der Flugzeiten sind der jeweilige Reiseveranstalter und/oder Leistungsträger und dessen Partner für die Flugbeförderung verantwortlich.</p>
        <ScrollableAnchor id="payment"><h4>6. Zahlung des Reisepreises an den jeweiligen Reiseveranstalter und/oder die Leistungsträger</h4></ScrollableAnchor>
        <p>6.1 Bei allen von BLAUWEGE GMBH vermittelten Leistungen muss die Zahlung vor Reiseantritt und spätestens bei Aushändigung der Unterlagen erfolgen. Ohne vorherige Bezahlung oder Bezahlung bei Abholung der Unterlagen kann kein Versand oder die Aushändigung der Unterlagen erfolgen. Ob und in welcher Höhe eine Anzahlung erforderlich und wann diese fällig ist, wird auf der Buchungsbestätigung gesondert angegeben bzw. ist in den AGB der Reiseveranstalter und/oder Leistungsträger hinterlegt. Die möglichen Zahlungsvarianten sind für jede Reise und alle sonstigen buchbaren Leistungen aufgeführt und können variieren. Falls Kreditkarte bzw. Einzug per Lastschrift/SEPA als Zahlungsmittel möglich ist und gewählt wird, erteilt der Nutzer der BLAUWEGE GMBH und/oder dem seitens BLAUWEGE GMBH beauftragten Fullfillmentdienstleister GFR Gesellschaft für Reisevertriebssysteme mbH (nachfolgend „GFR“ genannt) und/oder dem Reiseveranstalter und/oder dem Leistungsträger die ausdrückliche Ermächtigung, den geschuldeten Reise-/Leistungspreis über die Kreditkartennummer einzuziehen bzw. die jeweiligen Daten an den Reiseveranstalter und/oder Leistungsträger weiterzuleiten.</p>
        <p>6.2 Im Falle des Lastschriftverfahrens per Internet werden persönliche Daten wie Kontonummer, Bankleitzahl, Name und Adresse unter Verwendung der SSL-Technologie 128 Bit verschlüsselt. Es wird insoweit auf die Datenschutzerklärung von BLAUWEGE GMBH verwiesen, die auf diesen Webseiten einsehbar ist. Vor Erhalt der vollständigen Zahlung besteht für BLAUWEGE GMBH keinerlei Verpflichtung, die Tickets, Bestätigungen, Gutscheine oder andere Reiseunterlagen zuzustellen. Der Nutzer bleibt jedoch weiterhin verpflichtet, die mit dem jeweiligen Reiseveranstalter und/oder Leistungsträger vereinbarten Beträge für die bestellten touristischen Leistungen zu zahlen.</p>
        <p>6.3 Bei Zahlung per Kreditkarten werden die Kreditkartendaten bei der Traffics GMBH Mollstraße 32 in 10249 Berlin (nachfolgend „Traffics“ genannt) eingegeben. </p>
        <ScrollableAnchor id="cancellation"><h4>7. Umbuchung und Stornierung</h4></ScrollableAnchor>
        <p>7.1 Der Nutzer kann jederzeit nach den Bestimmungen des zwischen ihm und dem Reiseveranstalter und/oder Leistungsträger jeweils abgeschlossenen Vertrages vor Reisebeginn von der Reise zurücktreten, wobei Stornogebühren von bis zu 100% des Reisepreises anfallen können (je nach Reiseveranstalter und/oder Leistungsträger und Zeitpunkt des Stornos).</p>
        <p>7.2 Stornierungen und Umbuchungen sollen über GFR Gesellschaft für Reisevertriebssysteme mbH (nachfolgend „GFR“ genannt) mit Sitz in 44809 Bochum erfolgen. GFR leitet diese dann in einer angemessenen Bearbeitungsdauer zwecks Prüfung an den entsprechenden Reiseveranstalter und/oder Leistungsträger weiter. Der Rücktritt/die Umbuchung kann per Email an <a href="mailto:storno@holidayfind.de">storno@holidayfind.de</a>, per Fax oder Brief mit Angabe der Buchungsnummer erfolgen.</p>
        <p>7.3 Bei sehr kurzfristigen Änderungswünschen (weniger als zwei Stunden vor Beginn der jeweiligen Leistung), ist der Nutzer, zur Vermeidung von Verzögerungen, angehalten direkt Kontakt mit dem jeweiligen Reiseveranstalter und/oder Leistungsträger aufzunehmen. Serviceanfragen können grundsätzlich nur während der GFR Bürozeiten entgegengenommen werden. Diese sind:</p>
        <ul>
          <li>Mo. – So. 08.00 und 22.00 Uhr.</li>
          <li>Am 24.12. und 31.12.: 09.00 -13.00 Uhr</li>
        </ul>
        <p>7.4 Änderungswünsche bei Umbuchungen oder Stornierungen von bestimmten Leistungen, wie bspw. Reiseversicherungen oder Mietwagen kann GFR nicht selbst bearbeiten. Der Nutzer hat sich in diesen Fällen direkt an den jeweiligen Leistungsträger der gebuchten Leistung zu wenden.</p>
        <ScrollableAnchor id="obligations"><h4>8. Pflichten des Nutzers</h4></ScrollableAnchor>
        <p>8.1 Mängel in der Vermittlungsleistung sind BLAUWEGE GMBH unverzüglich anzuzeigen. BLAUWEGE GMBH ist Gelegenheit zur Abhilfe zu geben. Unterbleibt eine solche Anzeige schuldhaft, entfallen diesbezügliche Ansprüche aus dem Vermittlungsvertrag insoweit, als dass eine zumutbare Abhilfe seitens BLAUWEGE GMBH möglich gewesen wäre.</p>
        <p>8.2 Mit fehlerhaften Daten kann BLAUWEGE GMBH nicht arbeiten. Der Nutzer hat dafür Sorge zu tragen auf den Internetseiten alle notwendigen Informationsfelder und insbesondere die persönlichen Angaben sowie Kontaktdaten sorgfältig auszufüllen und nochmals zu überprüfen. Vom Nutzer fehlerhaft eingegebene Daten, insbesondere E-Mailadresse, Vor- und Nachname, können zu Schwierigkeiten in der Vertragsabwicklung führen, die die Leistungserbringung erheblich beeinträchtigen und zusätzliche Kosten verursachen.</p>
        <ScrollableAnchor id="insurance"><h4>9. Versicherungen</h4></ScrollableAnchor>
        <p>8.1 Mängel in der Vermittlungsleistung sind BLAUWEGE GMBH unverzüglich anzuzeigen. BLAUWEGE GMBH ist Gelegenheit zur Abhilfe zu geben. Unterbleibt eine solche Anzeige schuldhaft, entfallen diesbezügliche Ansprüche aus dem Vermittlungsvertrag insoweit, als dass eine zumutbare Abhilfe seitens BLAUWEGE GMBH möglich gewesen wäre.</p>
        <p>8.2 Mit fehlerhaften Daten kann BLAUWEGE GMBH nicht arbeiten. Der Nutzer hat dafür Sorge zu tragen auf den Internetseiten alle notwendigen Informationsfelder und insbesondere die persönlichen Angaben sowie Kontaktdaten sorgfältig auszufüllen und nochmals zu überprüfen. Vom Nutzer fehlerhaft eingegebene Daten, insbesondere E-Mailadresse, Vor- und Nachname, können zu Schwierigkeiten in der Vertragsabwicklung führen, die die Leistungserbringung erheblich beeinträchtigen und zusätzliche Kosten verursachen.</p>
        <p>Eine Reiserücktrittskostenversicherung oder eine andere Art von Versicherung ist im Regelfall nicht in den Preisen enthalten. BLAUWEGE GMBH ist als Bagatellvermittler nach § 34d Abs. 9 Gewerbeordnung (GewO) tätig. Als solcher empfiehlt BLAUWEGE GMBH dem Nutzer den Abschluss einer Reiseversicherung, insbesondere einer Reiserücktrittskosten- und/oder Ersatzversicherung, die BLAUWEGE GMBH dem Nutzer gerne ebenfalls vermittelt.</p>
        <p>Für vermittelte Versicherungen gelten die Allgemeinen Geschäftsbedingungen des jeweiligen Versicherungsanbieters. Anfallende Prämien werden ggf. direkt durch den Versicherer über das vom Nutzer angegebene Bezahlverfahren belastet.</p>
        <ScrollableAnchor id="documents"><h4>10. Visa, Pass, Zoll, Devisen- und Gesundheitsbestimmungen</h4></ScrollableAnchor>
        <p>Jeder Nutzer ist grundsätzlich selbst dafür verantwortlich, dass für seine Person die zur Durchführung der Reise erforderlichen Voraussetzungen erfüllt sind und sämtliche gesetzlichen Bestimmungen - insbesondere die in- und ausländischen Ein- und Ausreisebestimmungen, Gesundheitsvorschriften, Pass-, und Visa-Bestimmungen - beachtet werden. Gleiches gilt für die Beschaffung erforderlicher Reisedokumente. GFR erteilt dem Nutzer zu diesen Fragen auf Anfrage gewissenhaft Auskunft, kann dafür jedoch keine Haftung übernehmen. Der Nutzer hat darauf zu achten, dass Namensangaben im Reisepass, Visa und sonstigen Identifikationsdokumenten exakt mit denen der Buchung/Reiseunterlagen übereinstimmen. BLAUWEGE GMBH weist ausdrücklich darauf hin, dass die Bestimmungen jederzeit durch die Behörden geändert werden können. Dem Nutzer wird daher nahe gelegt, selbst bei den zuständigen Ämtern und Institutionen Informationen einzuholen. Ausführliche und tagesaktuelle Informationen sind der Webseite des Auswärtigen Amtes unter <a href="www.auswaertiges-amt.de">www.auswaertiges-amt.de</a>.</p>
        <ScrollableAnchor id="prohibition"><h4>11. Abtretungsverbot</h4></ScrollableAnchor>
        <p>Eine Abtretung von Ansprüchen des Nutzers gegen BLAUWEGE GMBH, auch an Ehegatten oder Verwandte, ist ausgeschlossen. Dies betrifft Ansprüche aus dem Vermittlungsvertrag und im Zusammenhang mit der Durchführung und Abwicklung des Vermittlungsvertrages, sowie aus ungerechtfertigter Bereicherung und unerlaubter Handlung. Auch die gerichtliche Geltendmachung vorbezeichneter Ansprüche des Nutzers durch Dritte im eigenen Namen ist unzulässig.</p>
        <ScrollableAnchor id="third_party_websites"><h4>12. Links zu Websites Dritter</h4></ScrollableAnchor>
        <p>Diese Website kann Hyperlinks zu Websites beinhalten, die von anderen Parteien als BLAUWEGE GMBH betrieben werden. Solche Hyperlinks werden dem Nutzer nur als Hinweise zur Verfügung gestellt. BLAUWEGE GMBH kontrolliert solche Websites nicht und ist für deren Inhalte nicht verantwortlich. Die Einbeziehung von Hyperlinks solcher Websites durch BLAUWEGE GMBH impliziert weder eine Billigung des Materials auf solchen Websites noch eine Verbindung mit deren Betreibern. Sollte BLAUWEGE GMBH ein Hinweis durch Dritte erreichen, wird unverzüglich den Inhalt der jeweiligen Seite geprüft.</p>
        <ScrollableAnchor id="jurisdiction"><h4>13. Gerichtsstand und Rechtsstatut</h4></ScrollableAnchor>
        <p>Soweit der Nutzer Kaufmann, eine juristische Person des öffentlichen Rechts oder des öffentlich-rechtlichen Sondervermögens ist, ist als ausschließlicher Gerichtsstand der Landgerichtsbezirk Weilheim vereinbart. Für Verbraucher gelten die gesetzlichen Zuständigkeitsregelungen. Die vertraglichen Beziehungen der Parteien richten sich nach dem Recht der Bundesrepublik Deutschland unter Ausschluss der Kollisionsvorschriften des EGBGB.</p>
        <ScrollableAnchor id="dispute_settlement">
          <h4>14. Hinweis zur Streitbeilegung gemäß Verbraucherstreitbeilegungsgesetz</h4>
        </ScrollableAnchor>
        <P14 />
        <ScrollableAnchor id="provisions"><h4>15. Schlussbestimmungen</h4></ScrollableAnchor>
        <P15 />
        <ScrollableAnchor id="annex"><h4>16. Annex</h4></ScrollableAnchor>
        <P16 />
        <ScrollableAnchor id="coronavirus">
          <h4>17. Storno-Garantie wegen Coronavirus <span style={{ fontWeight: 'normal' }}>(Covid-19 Erreger)</span></h4>
        </ScrollableAnchor>
        <P17 />
        <ScrollableAnchor id="prüfung_18">
          <h4>18. Prüfung „Künstliche Intelligenz“</h4>
        </ScrollableAnchor>
        <P18 />
        <div className="clear10" />
        <p>&copy; 2019 BLAUWEGE GMBH</p>
        <p>(Stand September 2023)</p>
      </div>
    </div>
  );
};

Agb.propTypes = {
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Agb);
