import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from '../../actions/popup';

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  showPopup: show,
}, dispatch);

const NotFound = ({ showPopup }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content">
      <div className="triple section_title_block section_title_block_landing">
        <button type="button" onClick={() => showPopup('bestprice')}>
          <img className="partner_link" src="images/partner_logo.svg" alt="Logo" />
        </button>
        <div className="diver_img" />
        <div className="section_title">
          <h1>404 Not Found</h1>
          <div className="clear" />
        </div>
        <div className="clear" />
      </div>
    </div>
  );
};

NotFound.propTypes = {
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
