import operators from '../assets/data/operators';
import { getStars } from './helper';

const formatPrice = value => `${value}` // convert to format: 1.234,
  .split('')
  .reverse()
  .map((char, i) => (!(i % 3) ? `${char}${i ? '.' : ','}` : char))
  .reverse()
  .join('');

export const getItem = ({
  giata, item, offer, tourOperatorList,
}) => ({
  // raw: { item, offer },
  giata,
  greenRating: item['green-rating'],
  ratings: {
    ...item.ratings,
    holidaycheck: item.ratings.holidaycheck && `${item.ratings.holidaycheck}%`,
    tripadvisor: item.ratings.tripadvisor
      && (
        /^\d+$/.test(item.ratings.tripadvisor)
          ? `${item.ratings.tripadvisor}%`
          : item.ratings.tripadvisor.replace(' Sternen', '') // tripadvisor can be "4.0 von 5 Sternen"
      ),
  },
  matchlist: item.matching.matchlist,
  mismatchlist: item.matching.mismatchlist,
  ...(
    item.oldprice && +item.oldprice.value > +offer.personPrice.value
      ? { oldPrice: formatPrice(item.oldprice.value) }
      : {}
  ),
  personPrice: formatPrice(offer.personPrice.value),
  duration: offer.travelDate.duration,
  tourOperator: (
    operators.find(({ code }) => code === offer.tourOperator.code) || {}
  ).short || offer.tourOperator.code,
  tourOperatorList: tourOperatorList.map(operator => operator.code),
});

export const getHotel = ({ item, hotel }) => {
  const { catalogData } = hotel;
  const images = catalogData.imageList.filter(image => image.split('iid=')[1]).map((image) => {
    const newImage = image
      .replace('/media.traffics-switch.de/', '/proxy.holidayfind.de/')
      .replace(/&amp;/i, '&');
    const [before, after] = newImage.split('&size=');
    if (!after) {
      return { original: newImage, thumbnail: newImage };
    }
    const parts = after.split('&');
    parts.forEach((part, i) => {
      if (part.indexOf('customerID=') === 0) parts[i] = 'customerID=0030001100000000';
    });
    parts.shift();
    return {
      original: `${before}${['&size=350'].concat(parts).join('&')}`,
      thumbnail: `${before}${['&size=100'].concat(parts).join('&')}`,
    };
  });

  return {
    name: hotel.name,
    location: `${item.country}, ${hotel.location.name}, ${hotel.location.region.name}`,
    map: {
      lat: hotel.location.latitude,
      lng: hotel.location.longitude,
    },
    stars: getStars(parseFloat(hotel.category)), // current format: "3.5"
    images: images.length ? images : undefined,
    catalogData,
  };
};

export default data => ({
  ...getItem(data),
  ...getHotel(data),
});
