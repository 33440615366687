import { CATEGORIES } from '../actions/categories';
import { RESTORE_SELECTION } from '../actions/restoreSelection';
import infons from '../assets/data/infons';
import Storage from '../utils/Storage';

const { categories } = infons.familie;
const getAsObject = array => array.reduce((res, key) => { res[key] = true; return res; }, {});

const storage = new Storage('categories');
const storageData = storage.get();

const defaultState = {
  list: categories.filter((category, i) => i < 6), // skip 7th category
  selected: {},
  req: 0,
  bufferid: '',
};
if (storageData) {
  defaultState.selected = getAsObject(storageData.selected);
  if (storageData.selected && storageData.selected.length) {
    defaultState.selected = getAsObject(storageData.selected);
  }
  if (storageData.req) {
    defaultState.req = storageData.req;
  }
}

export default (state = defaultState, { type, ...action }) => {
  const { list, selected } = state;
  let checked;

  switch (type) {
    case CATEGORIES.PREPARE_DONE:
      return {
        ...state,
        bufferid: action.bufferid,
      };

    case CATEGORIES.TOGGLE_CHECKBOX:
      checked = { ...selected, [action.id]: !selected[action.id] };
      list.forEach(({ subcategories }) => {
        subcategories.forEach(({ id: subId, subdetails }) => {
          subdetails.forEach(({ id: subSubId }) => {
            if (subSubId === action.id && !selected[subId]) checked = { ...checked, [subId]: true };
          });
        });
      });
      checked = Object.keys(checked).filter(key => checked[key]).map(key => +key);
      storage.update({ selected: checked });
      return {
        ...state,
        selected: getAsObject(checked),
        bufferid: '',
      };

    case RESTORE_SELECTION.LOAD_DONE:
      checked = action.params.mylikes ? action.params.mylikes.split(',').map(key => +key) : [];
      storage.update({
        selected: checked,
        req: action.params.reqmylikes || defaultState.req,
      });
      return {
        ...state,
        selected: getAsObject(checked),
        bufferid: '',
      };

    case CATEGORIES.RESET:
      storage.update({ selected: {} });
      return {
        ...state,
        selected: {},
        bufferid: '',
      };

    case CATEGORIES.SET_REQ:
      storage.update({ req: action.id });
      return {
        ...state,
        req: action.id,
        bufferid: '',
      };

    default:
      return state;
  }
};
