import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { setReq } from '../../actions/categories';
import { getSelectedMylikes } from '../../selectors/categories';
import BottomPopupTemplate from '../_BottomPopupTemplate';

const mapStateToProps = ({ categories: { list, selected, req } }) => ({
  list: getSelectedMylikes({ categories: { list, selected } }),
  req,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  setRequired: setReq,
}, dispatch);

const BottomPopup = ({ list, req, setRequired }) => {
  list.sort((a, b) => a.name > b.name ? 1 : -1);

  return (
    <BottomPopupTemplate delay={0} duration={0} cbStyle={{ display: 'none' }}>
      <Link to="/reisedaten" className="close_btn" />
      <div style={{ display: 'flex' }}>
        <img
          src="/images/kontakt_160_220.jpg"
          style={{ width: 60, height: 82, marginRight: 5 }}
        />
        <div style={{ marginTop: 13 }}>
          <div style={{ color: '#e30613' }}>Haupt-Interesse?</div>
          <div style={{ marginTop: 10, marginBottom: -10 }}>Falls für dich etwas</div>
        </div>
      </div>
      <div>
        extra wichtig ist - wähle:
        <div style={{ fontSize: 14, color: '#0c3f6c', fontWeight: 'normal' }}>
          <div style={{ padding: '5px 2%' }}>
            <div className="radiobutton">
              <input
                id="main_interest_0"
                type="radio"
                checked={req === 0}
                onChange={() => setRequired(0)}
              />
              <label htmlFor="main_interest_0" className="radio_view" />
              <label htmlFor="main_interest_0">Alles gleich wichtig</label>
            </div>
          </div>
          <div className="additional_body" style={{ maxHeight: 142, marginBottom: 50, overflowY: 'auto' }}>
            <ul style={{ float: 'none', margin: 0 }}>{list.map(({ id, name }) => (
              <li key={id} style={{ float: 'none' }}>
                <div className="radiobutton">
                  <input
                    id={`main_interest_${id}`}
                    type="radio"
                    checked={req === id}
                    onChange={() => setRequired(id)}
                  />
                  <label htmlFor={`main_interest_${id}`} className="radio_view" />
                  <label htmlFor={`main_interest_${id}`}>{name}</label>
                </div>
              </li>))}
            </ul>
          </div>
          <div className="data_list_action tc" style={{ right: 8, left: 8 }}>
            <Link to="/reisedaten" className="btn apply_bnt"><span>Anwenden</span><i className="list_checked" /></Link>
          </div>
        </div>
      </div>
    </BottomPopupTemplate>
  );
};

BottomPopup.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  req: PropTypes.number.isRequired,
  setRequired: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomPopup);
