import {
  select, fork, takeLatest, put,
} from 'redux-saga/effects';
import { DateUtils } from 'react-day-picker';
import { CALENDAR } from '../actions/calendar';

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

function* toggle({ day }) {
  if (day > yesterday) {
    const { from, to } = DateUtils.addDayToRange(day, yield select(({ calendar }) => calendar));
    yield put({ type: CALENDAR.TOGGLE_CHECKBOX_DONE, from, to });
  }
}

export default function* () {
  yield [
    fork(function* () { yield takeLatest(CALENDAR.TOGGLE_CHECKBOX, toggle); }),
  ];
}
