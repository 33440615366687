import { ANIMATION } from '../actions/animation';
import Storage from '../utils/Storage';

const storage = new Storage('animation');
const animation = storage.get();

const defaultState = {
  shown: false,
};
if (animation && animation.shown !== undefined) {
  defaultState.shown = animation.shown;
}

export default (state = defaultState, { type, ...action }) => {
  switch (type) {
    case ANIMATION.SET_SHOWN:
      storage.set({ shown: true });
      return state;

    case ANIMATION.SET_NOT_SHOWN:
      storage.set({ shown: false });
      return { ...state, shown: false };

    default:
      return state;
  }
};
