import React from 'react';
import PropTypes from 'prop-types';
import TrackVisibility from 'react-on-screen';
import Vimeo from '@u-wave/react-vimeo';

class VideoItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoFinished: false,
    };
  }

  render() {
    const { videoFinished } = this.state;
    const { id } = this.props;

    return (
      <TrackVisibility
        once
        partialVisibility
        tag="div"
        className="item vimeo_item"
      >{({ isVisible }) => (
        <Vimeo
          key={`${isVisible}_${videoFinished}`}
          video={id}
          className="vimeo_video"
          muted
          background={isVisible && !videoFinished}
          // paused={!isVisible}
          onEnd={() => this.setState({ videoFinished: true })}
          // NOTE: loop=false doesn't work when background=true
        />)}
      </TrackVisibility>
    );
  }
}

VideoItem.propTypes = {
  id: PropTypes.string.isRequired,
};

export default VideoItem;
