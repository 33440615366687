import {
  fork, takeLatest, put, call,
} from 'redux-saga/effects';
import { RESTORE_SELECTION } from '../actions/restoreSelection';
import { loadParams } from '../utils/Api';

function* load({ id }) {
  try {
    const params = yield call(loadParams, id);
    if (params.quid) { // NOTE: all parameters will be checked in corresponding reducer
      yield put({ type: RESTORE_SELECTION.LOAD_DONE, params });
    } else {
      yield put({ type: RESTORE_SELECTION.LOAD_FAILED, message: 'error loading params' });
    }
  } catch (message) {
    yield put({ type: RESTORE_SELECTION.LOAD_FAILED, message });
  }
}

export default function* () {
  yield [
    fork(function* () { yield takeLatest(RESTORE_SELECTION.LOAD, load); }),
  ];
}
