import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import IframeResizer from 'react-iframe-resizer-super';
import buildQueryString from 'build-query-string';
import { bookingExternal } from '../../../config';

/**
 * @returns {string} '020319,160319,7'
 */
const getSearchDate = ({ date, duration }) => {
  const format = 'DDMMYY';
  const from = Moment(date.from);
  const to = Moment(date.to);
  const range = to.diff(from, 'days');

  // http://assets.traffics.de/docs/ibe/deeplink/de/parameter/search/searchDate.html
  let searchDate = `${from.format(format)},${to.format(format)}`;
  searchDate += duration.getMin && duration.getMax
    // NOTE: duration range doesn't work on IBE side: "7" works, "6-8" doesn't
    // ? `,${duration.getMin(range)}-${duration.getMax(range)}`
    ? `,${duration.getExact(range)}`
    : `,${duration.getExact(range)}`;
  return searchDate;
};

const getKids = ({ people }) => {
  const { child, baby } = people;
  const children = [];
  for (let i = 0; i < child; i++) children.push(10); // pseudo random from 2 to 12
  for (let i = 0; i < baby; i++) children.push(1); // pseudo random from 0 to 2
  return children.join(',');
};

// http://assets.traffics.de/docs/ibe/deeplink/de/
const getBookLink = ({
  giata, tourOperatorList, airports, budget, date, duration, people,
}) => {
  console.log(tourOperatorList, airports, budget, date, duration, people);
  const params = {
    giataIdList: giata,
    adults: people.adult,
    ...(getKids({ people }) ? { children: getKids({ people }) } : {}),
    searchDate: getSearchDate({ date, duration }),
    ...(airports ? { departureAirportList: airports } : {}),
    tourOperatorList: tourOperatorList.join(','),
  };

  // 1. offercode is absent in IBE docs
  // 2. we have 1 case with minPricePerPerson,
  // but IBE supports just maxPricePerPerson, what should we do?
  if (budget && budget.max) {
    params.maxPricePerPerson = budget.max;
  }

  return `${bookingExternal}/${airports ? 'pauschalreise' : 'hotel'}/angebote?${buildQueryString(params)}`;
};

const Iframe = ({ giata, ibeParams }) => (
  ibeParams
    ? (
      <div className="frame_block" id="frame_block">
        <IframeResizer
          src={getBookLink({ giata, ...ibeParams })}
          iframeResizerOptions={{
            // log: true,
            // autoResize: true,
            checkOrigin: false,
            // resizeFrom: 'parent',
            // heightCalculationMethod: 'max',
            // initCallback: () => { console.log('ready!'); },
            // resizedCallback: () => { console.log('resized!'); },
          }}
        />
      </div>
    )
    : null
);

Iframe.propTypes = {
  giata: PropTypes.string.isRequired,
  ibeParams: PropTypes.objectOf(PropTypes.any),
};

export default Iframe;
