import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobileOnly } from 'react-device-detect';
import { sortByGroup } from '../../actions/result';
import { getSelectedGroups } from '../../selectors/categories';
import BottomPopup from './BottomPopup';

const mapStateToProps = state => ({
  groups: getSelectedGroups(state),
});
const mapDispatchToProps = dispatch => bindActionCreators({
  sort: sortByGroup,
}, dispatch);

const defaultText = 'Top-Treffer';
let delay = 20;
const duration = 8;

const Dropdown = ({ groups, sort }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSorted, setIsSorted] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  useEffect(() => {
    let timeoutShowId = setTimeout(() => setIsExpanded(true), delay * 1000);
    let timeoutHideId = setTimeout(() => setIsExpanded(false), (delay + duration) * 1000);
    if (isSorted)
      clearTimeout(timeoutShowId);

    return () => {
      clearTimeout(timeoutShowId);
      clearTimeout(timeoutHideId);
    };
  }, [isSorted]);

  const toggle = () => {
    setIsSorted(true);
    setIsExpanded(!isExpanded);
  };

  const select = id => {
    if (selectedId !== id) {
      sort(id);
      setIsExpanded(false);
      setSelectedId(id);
    }
  };

  return (
    <div className="results_pulldown_block">
      {!isMobileOnly && !isSorted && (
        <BottomPopup delay={delay} onClose={() => setIsExpanded(false)} />
      )}
      <span>Sortierung nach:</span>
      <button
        type="button"
        className="results_pulldown checkbox_after"
        onClick={toggle}
      >{selectedId ? groups.find(({ id }) => id === selectedId).name : defaultText}
      </button>
      <div className={`results_pulldown_content${isExpanded ? ' show' : ''}`}>
        <div className="checkbox green_checkbox checkbox_after green_checkbox_after">
          <input readOnly id="ubereinstimmungen" type="checkbox" checked={!selectedId} />
          <label
            htmlFor="ubereinstimmungen"
            className="checkbox_view"
            onClick={() => select(0)}
          />
          <label
            htmlFor="ubereinstimmungen"
            className="label_line"
            onClick={() => select(0)}
          >{defaultText}
          </label>
        </div>
        <span className="clear h_line sm_mb" />
        <div className="checkbox invisible_checkbox">
          <input readOnly id="interessen" type="checkbox" checked={!!selectedId} />
          <label htmlFor="interessen" className="label_line red">Sortieren nach Kategorien:</label>
        </div>
        <ul className="show">{groups.map(({ id, name }) => (
          <li
            key={id}
            className={id === selectedId ? 'active' : ''}
            onClick={() => select(id)}
          ><span>{name}</span>
          </li>))}
        </ul>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object),
  sort: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
