import React from 'react';
import PropTypes from 'prop-types';

const Rating = ({ title, number, comment }) => {
  const stars = Math.round(number * 6 / 10) / 10;
  const value = stars.toString().includes('.') ? stars : `${stars}.0`;

  return (
    <React.Fragment>
      <tr>
        <td>{title}</td>
        <td style={{ width: 130 }}>
          <ul className="common_raiting">
            <li className={stars < 1 ? '' : 'active'} />
            <li className={stars < 2 ? '' : 'active'} />
            <li className={stars < 3 ? '' : 'active'} />
            <li className={stars < 4 ? '' : 'active'} />
            <li className={stars < 5 ? '' : 'active'} />
            <li className={stars < 6 ? '' : 'active'} />
          </ul>
        </td>
        <td style={{ width: 30 }}>{value}</td>
      </tr>
      {comment && <tr className="review_comment"><td colSpan="3"><p>{comment}</p></td></tr>}
    </React.Fragment>
  );
};

Rating.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  comment: PropTypes.string,
};

export default Rating;
