import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { show } from '../../actions/popup';

const Phrase = styled.div`
  position: absolute;
  top: 52px;
  left: 50%;
  margin-left: 110px;
  font-size: 16px;

  @media(max-width: 640px) {
    display: none;
  }
`;

const links = [
  { image: '/images/links_sp/Logo-5vorFlug-112px.png' },
  { image: '/images/links_sp/Logo-123Fly-112px.png' },
  { image: '/images/links_sp/Logo-alltours-112px.png' },
  { image: '/images/links_sp/Logo-Bucher-112px.png' },
  { image: '/images/links_sp/Logo-DER-112px.png' },
  { image: '/images/links_sp/Logo-FerienTouristik-112px.png' },
  { image: '/images/links_sp/Logo-FTI-112px.png' },
  { image: '/images/links_sp/Logo-ITS-112px.png' },
  { image: '/images/links_sp/Logo-Jahn-112px.png' },
  { image: '/images/links_sp/Logo-LMX-112px.png' },
  { image: '/images/links_sp/Logo-Meiers-112px.png' },
  { image: '/images/links_sp/Logo-Schauinslandreisen-112px.jpg' },
  { image: '/images/links_sp/Robinson_Club_logo.svg' },
  { image: '/images/links_sp/Logo-TUI-112px.png' },
  { image: '/images/links_sp/Logo-vtours-112px.jpg' },
];
const flags = [
  { flag: 'images/flags/cape-verde-islands.svg' },
  { flag: 'images/flags/croatia.svg' },
  { flag: 'images/flags/dominican-republic.svg' },
  { flag: 'images/flags/egypt.svg' },
  { flag: 'images/flags/france.svg' },
  { flag: 'images/flags/greece.svg' },
  { flag: 'images/flags/indonesia.svg' },
  { flag: 'images/flags/italy.svg' },
  { flag: 'images/flags/kenya.svg' },
  { flag: 'images/flags/maldives.svg' },
  { flag: 'images/flags/mexico.svg' },
  { flag: 'images/flags/portugal.svg' },
  { flag: 'images/flags/spain.svg' },
  { flag: 'images/flags/thailand.svg' },
  { flag: 'images/flags/turkey.svg' },
  { flag: 'images/flags/united-states.svg' },
];

const interval = 900;

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  showPopup: show,
}, dispatch);

const Spinner = ({ children, showLogo, showPopup }) => { // showLogo: false = countries, true = results
  const [{ image, flagimage }, setState] = useState({
    currentLinkIndex: 0,
    currentFlagIndex: 0,
    image: links[0].image,
    flagimage: flags[0].flag,
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    const intervalID = setInterval(() => setState(({ currentLinkIndex, currentFlagIndex }) => ({
      currentLinkIndex: currentLinkIndex + 1 === links.length ? 0 : currentLinkIndex + 1,
      currentFlagIndex: currentFlagIndex + 1 === flags.length ? 0 : currentFlagIndex + 1,
      image: links[currentLinkIndex + 1 === links.length ? 0 : currentLinkIndex + 1].image,
      flagimage: flags[currentFlagIndex + 1 === flags.length ? 0 : currentFlagIndex + 1].flag,
    })), interval);

    return () => clearInterval(intervalID);
  }, []);

  return (
    <div className="content spinner_content">
      <div className="triple section_title_block spinner_section_title_block">
        <button type="button" onClick={() => showPopup('bestprice')}>
          <img className="partner_link" src="/images/partner_logo.svg" alt="Logo" />
        </button>
        <div className="family_top_block spinner_family_top_block">
          <div className="family_img header_family_img"><img src="images/animation/family3.svg" /></div>
        </div>
        {showLogo ? (
          <div className="section_title spinner_section_title">
            <h1>Jetzt passiert etwas völlig Neues:</h1>
            <h5>
              Wir suchen und finden nun die <span className="red"><b>Hotels/Reisen</b></span> mit
              der größten Anzahl an Top-Treffern <span className="green"><b>zu euren persönlichen Urlaubs-Interessen.</b></span>
            </h5>
          </div>
        ) : (
          <div className="section_title spinner_section_title">
            <h1>Urlaubsländer checken</h1>
            <div className="clear" />
            <h5>
              Ganz nach euren Urlaubs-Interessen filtern wir jetzt <span className="red"><b>die Länder</b></span> mit
              der höchsten Anzahl <span className="green"><b>passender Hotels.</b></span>
            </h5>
          </div>
        )}
        <div className="clear" />
      </div>
      <div className="spinner">
        <div className="mobile_text">
          {showLogo
            ? (
              <h5>
                Jetzt finden wir die <span className="red"><b>Hotels/Reisen</b></span> zu
                euren <span className="green"><b>Urlaubs-Interessen</b></span>
              </h5>
            ) : (
              <h5>
                Wir finden jetzt die <span className="red"><b>Länder</b></span> mit
                der größten Anzahl <span className="green"><b>passender Hotels.</b></span>
              </h5>
            )}
        </div>
        <div className="spinner_bg" />
        <div className="sun" />
        {showLogo
          ? <img className="spin_link" src={image} alt="Logo" />
          : <img className="spin_link_sm" src={flagimage} alt="Flag" />}
        <Phrase>{children}</Phrase>
        <div className="clear" />
      </div>
      <div className="clear" />
    </div>
  );
};

Spinner.propTypes = {
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Spinner);
