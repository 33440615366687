import React from "react";

export default () => (
  <React.Fragment>
    <p>
      holidayfind.de hat als bisher einziges Reiseportal ein intelligentes System entwickelt, das zu der Anfrage
      (individuelle Interessen) sowohl optimale Reiseziele sucht und findet, als auch die dazu passenden (Vorgaben:
      Budget und Reisezeitraum) Reisen. Mit Tools bestehender KI werden die Ergebnisse überprüft. Zu keinem Zeitpunkt
      werden persönliche Interessen-Daten gespeichert.
    </p>
  </React.Fragment>
);
