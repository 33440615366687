import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUrlParams } from '../../utils/helper';
import { load } from '../../actions/restoreSelection';

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  loadOptions: load,
}, dispatch);

const RestoreSelection = ({ location: { search }, loadOptions }) => {
  useEffect(() => {
    const [selectioncode] = Object.keys(getUrlParams(search));
    if (selectioncode)
      loadOptions(selectioncode);
  }, []);

  console.log('**********************************');
  return <Redirect to={{ pathname: '/reisedaten', isRestore: true }} />;
};

RestoreSelection.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  loadOptions: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RestoreSelection);
