import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BottomPopupTemplate from '../_BottomPopupTemplate';

const Wrapper = styled.div`
  position: absolute;
  top: 5px;
  left: 93px;
  color: #fff;
  font-size: 25px;
  padding: 6px;
  background: #8ccc03;
  transform: rotate(-5deg);

  div {
    width: 190px;
    padding: 3px 0 6px 6px;
    border: 6px solid #fff;
    background: #8ccc03;
  }
  img {
    position: absolute;
    right: -12px;
    top: -10px;
    width: 38px;
    height: 38px;
    background: #fff;
    border-radius: 50%;
  }
  span {
    color: #000;
  }
`;

export default () => (
  <BottomPopupTemplate delay={0} duration={0} cbStyle={{ top: 'auto', bottom: 6, zIndex: 1 }}>
    <Wrapper>
      <div>
        <Link to="/allgemeine-geschaeftsbedingungen#coronavirus">
          <img src="/images/logo_top.svg" alt="Logo" />
        </Link>
        <span>Storno-Garantie</span> wegen des Coronavirus
      </div>
    </Wrapper>
    <div style={{ display: 'flex' }}>
      <img style={{ width: 60 }} src="/images/kontakt_160_220.jpg" />
    </div>
    <div style={{ marginTop: 5, fontSize: 17, fontWeight: 'normal',  color: '#000' }}>
      Gültig vor
      <br />
      Reiseantritt:
    </div>
  </BottomPopupTemplate>
);
