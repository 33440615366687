import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GoogleAnalytics from 'react-ga';
import { loadSelectionCode } from '../../actions/result';

const getEmailSubject = () => encodeURIComponent('Für dich: Mein Speicherlink zu holidayfind.de');
const getEmailBody = selectioncode => encodeURIComponent(`Hallo...

Schau dir bitte mal folgenden Link von holidayfind.de an:
${window.location.origin}/s?${selectioncode}

Ich hab dort meine/unsere Interessen und Reisedaten zusammengestellt.
holidayfind.de findet schnell Länder und passende Reisen nach meinen/unseren Interessen!

Herzliche Grüße

....

PS Noch was: Bei holidayfind.de sind die Reisen zusätzlich mit ihrer „Klimafreundlichkeit" gekennzeichnet.`);

const copy = () => {
  window.document.getElementById('selectioncode').select();
  window.document.execCommand('copy');
  window.alert('Link kopiert');
};

const mapStateToProps = ({ countries: { isLoading }, result }) => ({
  selectioncode: result.selectioncode,
  isLoading: isLoading || result.isLoading,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  loadResults: loadSelectionCode,
}, dispatch);

const Save = ({ location: { pathname }, selectioncode, isLoading, loadResults }) => {
  const [isShowed, setIsShowed] = useState(false);
  if (isLoading) {
    return null;
  }

  const open = () => {
    if (pathname === '/reiselaender' && !selectioncode) {
      loadResults();
    }
    setIsShowed(true);
  };

  return (
    <React.Fragment>
      <button
        type="button"
        className="save_btn"
        onClick={() => {
          GoogleAnalytics.event({ category: 'Header', action: 'Save', label: 'Open' });
          open();
        }}
      />
      <div className={`save_block_container${isShowed ? ' show' : ''}`}>
        <div className="save_block_centered">
          <div id="save_block" className="save_block">
            <button
              type="button"
              className="close_block_btn"
              onClick={() => {
                GoogleAnalytics.event({ category: 'Header', action: 'Save', label: 'Close' });
                setIsShowed(false);
              }}
            />
            <div className="clear10" />
            <h5>Speichern</h5>{selectioncode ? (
              <React.Fragment>
                <label>
                  <b style={{ display: 'block' }}>deiner Interessen und Reisedaten</b>
                  <span>Länder + Angebote werden bei Aufruf neu ermittelt.</span>
                </label>
                <a
                  href={`mailto:?subject=${getEmailSubject()}&body=${getEmailBody(selectioncode)}`}
                  onClick={() => {
                    GoogleAnalytics.event({ category: 'Header', action: 'Save', label: 'Email' });
                    setIsShowed(false);
                  }}
                >per Mail versenden
                </a>
                <div className="clear10" />
                <label>oder</label>
                <input id="selectioncode" readOnly type="text" value={`${window.location.origin}/s?${selectioncode}`} />
                <button
                  type="button"
                  style={{ textAlign: 'left' }}
                  onClick={() => {
                    GoogleAnalytics.event({ category: 'Header', action: 'Save', label: 'Copy' });
                    copy();
                  }}
                >Link kopieren</button>
              </React.Fragment>)
              : <label>wird geladen...</label>}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Save.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  selectioncode: PropTypes.string.isRequired,
  loadResults: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Save));
