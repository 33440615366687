import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SubCategory from './SubCategory';

const mapStateToProps = ({ categories: { selected } }) => ({
  selected,
});

const Category = ({
  name, code, subcategories, selected, isExpanded, onToggle,
}) => {
  const selectedNames = [];
  subcategories.forEach(({ id, name, subdetails }) => {
    if (selected[id]) selectedNames.push(name);
    subdetails.forEach((subdetail) => {
      if (selected[subdetail.id]) selectedNames.push(subdetail.name);
    });
  });

  return (
    <div className="clasification_block" onClick={() => { if (!isExpanded) onToggle(); }}>
      <div className="tc fl">
        <h4>{name}</h4>
        <button
          type="button"
          className={`select_interests${selectedNames.length ? ' select_interests_done' : ''}`}
          onClick={onToggle}
        >Interessen wählen
        </button>
        <p
          className="clasification_block_chosen_items"
          dangerouslySetInnerHTML={{ __html: selectedNames.map(value => `<span>${value}</span>`).join(', ') }}
        />
      </div>
      <div className="category_img_block" style={{ backgroundImage: `url(/images/category/family/cat_0${code}.svg)` }} />
      <button type="button" className={`select_interests_mob${isExpanded ? ' hidden' : ''}`} onClick={onToggle} />
      <div id="diving_snorkeling" className={`additional_parameters${isExpanded ? '' : ' hidden'}`}>
        <p className="block_bottom" />
        <div>
          <header>
            <p>{name} <span>(Mehrfachauswahl möglich)</span></p>
            <button type="button" className="close_additional additional_big" onClick={onToggle} />
          </header>
          {subcategories.map(subcategory => (
            <SubCategory key={subcategory.id} {...subcategory} />
          ))}
          <div className="data_list_action tc">
            <button type="button" className="btn apply_bnt" onClick={onToggle}>
              <span>Anwenden</span>
              <i className="list_checked" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Category.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  selected: PropTypes.objectOf(PropTypes.bool),
  name: PropTypes.string.isRequired,
  code: PropTypes.number.isRequired,
  subcategories: PropTypes.arrayOf(PropTypes.any),
};

export default connect(mapStateToProps)(Category);
