import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GoogleAnalytics from 'react-ga';
import List from '../Wishlist';

const mapStateToProps = ({ wishlist: { list } }) => ({
  count: list.length,
});

const Wishlist = ({ count }) => {
  const [isShowed, setIsShowed] = useState(false);

  return (
    <div className="wish_list_block">
      <button
        type="button"
        className={`wish_list${count ? ' active' : ''}`}
        onClick={() => {
          GoogleAnalytics.event({ category: 'Header', action: 'Wishlist', label: 'Open' });
          setIsShowed(prev => !prev);
        }}
      />
      <span className="wish_list_count">{count}</span>
      <List
        isShowed={isShowed}
        toggle={() => {
          GoogleAnalytics.event({ category: 'Wishlist', action: 'Close' });
          setIsShowed(prev => !prev);
        }}
      />
    </div>
  );
};

Wishlist.propTypes = {
  count: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(Wishlist);
