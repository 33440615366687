import React from 'react';
import PropTypes from 'prop-types';

const activeHotel = 'Eure Auswahl';

const CarouselItem = ({
  isSelected, name, location, stars, onChanged,
}) => (
  isSelected ? (
    <button type="button" className="favorit active">
      <p className="day_favorit">{activeHotel}</p>
      <p className="active_hotel_location"><b>{location}</b></p>
    </button>
  ) : (
    <button type="button" onClick={onChanged}>
      <p className="hotel_name">{`${name}`} <span>{'*'.repeat(stars.length)}</span></p>
      <div className="clear" />
      <p><span>{location}</span></p>
    </button>)
);

CarouselItem.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  stars: PropTypes.arrayOf(PropTypes.bool),
  onChanged: PropTypes.func.isRequired,
};

export default CarouselItem;
