import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import { show } from '../../actions/popup';

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  showPopup: show,
}, dispatch);

const Protection = ({ history, showPopup }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content">
      <Helmet>
        <title>Umweltschutz | holidayfind.de</title>
        <meta name="description" content="holidayfind.de findet ausgesucht attraktive Reisen, die alle
         Wünsche Ihrer Familie erfüllen und auch den Umweltschutz einbeziehen." />
        <meta name="keywords" content="umweltfreundlich reisen, umweltschutz reisen, umwelt reisen" />
      </Helmet>
      <div className="triple section_title_block section_title_block_landing">
        <button type="button" onClick={() => showPopup('bestprice')}>
          <img className="partner_link" src="images/partner_logo.svg" alt="Logo" />
        </button>
        <div className="family_top_block">
          <div className="family_img header_family_img"><img src="images/animation/family3.svg" /></div>
        </div>
        <div className="section_title">
          <h1>Umweltschutz</h1>
          <div className="clear" />
          <h5><span className="logo_inline logo_inline_sm" /> unterstützt <b>atmosfair</b> und andere NGOs jährlich mit 10% vom Überschuss.</h5>
        </div>
        <div className="clear" />
      </div>
      <div className="clear" />
      <div className="text_content">
        <button type="button" className="f17 back_close_btn" onClick={history.goBack} />
        <h4 className="static_header">Klimafreundlichkeit ist auch bei Pauschalreisen wichtig und möglich</h4>
        <div className="clear20"></div>
        <figure className="half_block fr">
          <p><img src="/images/protection.jpg" className="img_left"/></p>
        </figure>
        <p>
          In unserer globalisierten Welt ist Mobilität ein besonderes Gut, das jedoch den Klimaschutz ins Spiel bringt.
          Wie die meisten Menschen lieben wir das Reisen, sind uns jedoch auch der Verantwortung für unseren Planeten bewusst.
          Auch unserer Kinder zuliebe.
        </p>
        <p>
          Bei <span className="logo_inline logo_inline_sm" /> geben wir den Reisenden Orientierung,
          wie sie ihre ganz persönliche Vorstellung eines klimafreundlichen und attraktiven Urlaubs realisieren können.
          Wir bieten dazu erstmalig die Möglichkeit, Paket-Reisen noch viel passgenauer und umweltbewusster auszuwählen.
          Dazu arbeiten wir mit Umweltverbänden und Organisationen wie <b>atmosfair, Germanwatch</b> und <b>myclimate</b> zusammen.
          Denn vor der aufwändigen Kompensation kommt die CO2 -Vermeidung.
        </p>
        <p>
          Weil auch unser Unternehmen insgesamt so nachhaltig wie möglich arbeiten möchte,
          planen wir bis Ende 2020 zudem die Zertifizierung nach einem anerkannten Standard für Serviceunternehmen.
        </p>
        <p>
          <b>Lasst uns gemeinsam klimafreundlicher werden.</b> Weitere
          Informationen <button type="button" onClick={() => showPopup('climatefriendly')} style={{ textDecoration: 'underline' }}>hier</button>.
        </p>
        <div className="clear20" />
      </div>
    </div>
  );
};

Protection.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Protection);
