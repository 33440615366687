import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from 'react-ga';
import ScrollableAnchor from 'react-scrollable-anchor';
import { configureAnchors } from 'react-scrollable-anchor';
import { show } from '../../actions/popup';

configureAnchors({ offset: -140 });

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  showPopup: show,
}, dispatch);

const Datenschutz = ({ history, showPopup }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content">
      <Helmet>
        <title>Datenschutz Online-Reisesuche | holidayfind.de</title>
        <meta name="description" content="Informationen zur Nutzung der Online-Reisesuche von holidayfind.de
         und zum Datenschutz nach EU-DSGVO." />
        <meta name="keywords" content="holidayfind, holidayfind datenschutz" />
      </Helmet>
      <div className="triple section_title_block section_title_block_landing">
        <button type="button" onClick={() => {
          showPopup('bestprice');
          GoogleAnalytics.event({ category: 'Popup', action: 'Best Price', label: 'Open' });
        }}>
          <img className="partner_link" src="images/partner_logo.svg" alt="Logo" />
        </button>
        <div className="family_top_block">
          <div className="family_img header_family_img">
          {/* <img src="images/animation/family3.svg" /> */}
          <img src="images/family5-complete-shadow-dark.svg" />
          </div>
        </div>
        <div className="section_title">
          <h1>Datenschutz</h1>
          <div className="clear" />
        </div>
        <div className="clear" />
      </div>
      <div className="clear" />
      <div className="text_content">
        <button type="button" className="f17 back_close_btn" onClick={history.goBack} />
        <h4 className="static_header">Datenschutzhinweise der BLAUWEGE GMBH</h4>
        <p>holidayfind.de ist eine Marke der BLAUWEGE GMBH. Im Folgenden finden sich die Datenschutzbestimmungen der BLAUWEGE GMBH.</p>
        <ol>
          <li><a href="#general">Allgemeines</a></li>
          <li><a href="#data">Datenspeicherung, Datenverwendung, Datenweitergabe und Datensicherheit</a></li>
          <li><a href="#cookies">Cookies</a></li>
          <li><a href="#google_marketing">Google Marketing Platform</a></li>
          <li><a href="#facebook">Facebook Custom Audience / Conversion Tracking</a></li>
          <li><a href="#social">Social Plugins</a></li>
          <li><a href="#right_opposition">Widerspruchs-, Auskunfts- und Löschungsrecht</a></li>
          <li><a href="#linking">Verlinkung</a></li>
          <li><a href="#consent_forms">Einwilligungserklärungen</a></li>
          <li><a href="#rules">Gewinnspielbedingungen</a></li>
        </ol>
        <div className="clear20"></div>
        <ScrollableAnchor id="general">
          <h4>1. Allgemeines</h4>
        </ScrollableAnchor>
        <p>An dieser Stelle möchten wir unsere Philosophie im Umgang mit persönlichen Daten, die Du uns beim Besuch unserer Website übermittelst, erläutern sowie einige grundlegende Hinweise zum Datenschutz geben. Wir respektieren die Datenschutzrechte jedes Einzelnen. Unsere Dienstleistungen stehen im Einklang mit den rechtlichen Datenschutzbestimmungen, die in der EU-Datenschutzgrundverordnung (DSGVO), im neuen Bundesdatenschutzgesetz (BDSG-neu), Telemediengesetz (TMG) und im Gesetz gegen den unlauteren Wettbewerb (UWG) geregelt sind. </p>
        <p>Mit dem Besuch unserer Webseiten werden von uns automatisch verschiedene Informationen in den jeweiligen Server-Logfiles erfasst. Hierzu gehören beispielsweise die Art und die Versionsnummer Deines Webbrowsers, Dein verwendetes Betriebssystem, Informationen zu Deinem verwendeten Internetprovider und andere ähnliche Informationen. Diese Informationen fallen systembedingt bei der Nutzung des Internets an und sind daher technisch zwingend notwendig, um einen einwandfreien, reibungslosen und sicheren Betrieb einer Webseite zu gewährleisten. Ggf. verwenden wir in anonymer Form diese Informationen zur Optimierung unseres Internetauftritts im Rahmen statistischer Auswertungen.</p>
        <ScrollableAnchor id="data">
          <h4>2. Datenspeicherung, Datenverwendung, Datenweitergabe und Datensicherheit</h4>
        </ScrollableAnchor>
        <p>2.1 Datenspeicherung</p>
        <p>Die von Dir im Rahmen von der BLAUWEGE GMBH auf holidayfind.de eingegebenen Daten werden von BLAUWEGE GMBH, entsprechend der Rechtsgrundlage für die Datenverarbeitung, wie beschrieben in Art.6 Abs.1 DSGVO, erhoben, verarbeitet und für Zwecke der Reisesuche und der Angebotserstellung sowie der weiteren Bearbeitung und Betreuung gespeichert und genutzt. Selbstverständlich kannst Du jederzeit mit Wirkung für die Zukunft von Deinem gesetzlichen Widerspruchsrecht hinsichtlich der Speicherung und Nutzung Deiner persönlichen und Dir eindeutig zugeordneten Daten Gebrauch machen. Wie Du von Deinem Widerspruchsrecht Gebrauch machen kannst, erläutern wir Dir im Punkt 7.2 (Widerspruchsrecht).</p>
        <p>2.2 Datenverwendung</p>
        <p>Neben der Speicherung und Nutzung zum Zwecke der Vergleichs- und Angebotserstellung sowie der weiteren Bearbeitung und Betreuung nutzt BLAUWEGE GMBH die eingegebenen Daten außerdem ausschließlich in pseudonymisierter Form zu statistischen Zwecken, zu Zwecken der Marktforschung und zur Gewinnung von Erkenntnissen zur Verbesserung des eigenen Internetauftrittes. Selbstverständlich kannst Du von Deinem gesetzlichen Widerspruchsrecht hinsichtlich statistischer Zwecken, zu Zwecken der Marktforschung und zur Gewinnung von Erkenntnissen zur Verbesserung des eigenen Internetauftrittes mit Wirkung für die Zukunft Gebrauch machen. Wie Du von Deinem Widerspruchsrecht Gebrauch machen kannst, erläutern wir Ihnen unter Punkt 7.2 (Widerspruchsrecht).</p>
        <p>2.3 Datenweitergabe</p>
        <p>BLAUWEGE GMBH legt großen Wert darauf, dass in keinem Fall eine Weitergabe Deiner Daten an unbeteiligte Dritte ohne Deine aktive Einwilligung stattfindet, es sei denn, wir sind oder wir werden hierzu in irgendeiner Form gesetzlich verpflichtet. Das wäre beispielsweise dann der Fall, wenn wir im Rahmen der Verfolgung und Aufklärung von Straftaten zur Herausgabe der Daten verpflichtet sind bzw. verpflichtet werden. Ansonsten erfolgt eine Weitergabe Deiner Daten ausschließlich auf Grundlage Deiner expliziten Einwilligung und auch nur an die von Dir ausgewählte Gesellschaft und hier wiederum ausschließlich zum Zwecke der Vertragsabwicklung. Du kannst das uns einmal gegebene Einverständnis jederzeit mit Wirkung für die Zukunft widerrufen und/oder der künftigen Verwendung Deiner Daten widersprechen. Wie Du von Deinem Widerspruchsrecht Gebrauch machen kannst, erläutern wir Dir unter Punkt 7.2 (Widerspruchsrecht).</p>
        <p>2.4 Datenverarbeitung </p>
        <p>2.4.1 Datenverarbeitung bei Pauchalreisebuchungen</p>
        <p>Informationen zu Deiner Pauschalreisebuchung werden an die bei der jeweiligen Buchung auf der Last-Minute- und Pauschalreise-Seite gut erkennbaren Reiseveranstalter weitergegeben (welche diese ihrerseits wieder an das entsprechende Hotel, die Fluggesellschaft, den Dienstleister oder dessen Betreiber übermitteln).</p>
        <p>Der technische Teil der Buchung von Pauschalreisen wird von Traffics GMBH (Mollstraße 32 in 10249 Berlin) abgewickelt.</p>
        <p>Bitte beachte, dass alle Fluggesellschaften gesetzlich verpflichtet sind, Flug- und Reservierungsdaten aller Reisenden, die in die USA ein- und ausreisen, der amerikanischen Zollbehörde bekannt zu geben. Diese Daten werden aus Sicherheitsgründen gebraucht und vertraulich behandelt.</p>
        <p>Die Zahlungsabwicklung erfolgt abhängig von der Zahlungsart mit einem oder mehreren Drittanbietern. Dafür werden die notwendigen Informationen an den Zahlungsanbieter übertragen.</p>
        <p>2.4.2 Datenverarbeitung bei Reiseversicherungen</p>
        <p>Bei Buchung einer Reiseversicherung als Flug-Zusatzleistung erfolgt die Datenübermittlung an die ERV (Europäische Reiseversicherung AG, Rosenheimer Straße 116, 81669 München).</p>
        <p>Reiseversicherungen werden angeboten von:</p>
        <ul className="list_style_none">
            <li>ERGO ehemals ERV (Europäische Reiseversicherung AG)</li>
            <li>Rosenheimer Straße 116</li>
            <li>81669 München</li>
            <li>Telefon: 089 4166 – 00</li>
            <li>E-Mail: <a href="mailto:contact@reiseversicherung.de">contact@reiseversicherung.de</a></li>
          </ul>
        <p>2.5 Datensicherheit</p>
        <p>Sobald Du persönliche Daten in die von uns betriebenen Suche auf holidayfind.de eingeben, findet diese Kommunikation ab diesem Zeitpunkt verschlüsselt statt. Wir verbinden Dich mit unserem Sicherheitsserver, so dass die Daten von niemandem mitgelesen werden können. Hierzu setzen wir entsprechende SSL Verschlüsselungs-Verfahren ein, so dass Deine Daten ausschließlich im abgesicherten „https-Modus“ übertragen werden. Dieses Verfahren ist Standard und wird u.a. vom Bundesamt für Sicherheit in der Informationstechnik (BSI) empfohlen. Zu Deiner eigenen Sicherheit empfehlen wir Dir, ausschließlich die jeweils aktuellsten Browserversionen und Virenscanner zu verwenden. Du erkennst die eingeschaltete Verschlüsselung am aktivierten Schloss-Symbol unten in der Statuszeile des Browsers bzw. direkt oberhalb des Vergleichsmoduls oder direkt neben der jeweiligen URL von holidayfind.de. Wir schützen unsere Server und Deine Daten mit den modernsten Sicherheitsprogrammen und Firewalls, um einen unbefugten Zugangsversuch bereits von Beginn an unterbinden.</p>
        <p>2.6 Besondere Behandlung von Kreditkartendaten</p>
        <p>Bei Zahlung per Kreditkarten werden die Kreditkartendaten bei Traffics GmbH eingegeben. </p>
        <ScrollableAnchor id="cookies">
          <h4>3. Cookies</h4>
        </ScrollableAnchor>
        <p>Im Rahmen der ständigen Verbesserung des Angebotes für unsere Benutzer können ggf. weitere Trackingtechnologien von Drittanbietern und uns eingesetzt werden, welche anonymisierte und temporäre „Session-Cookies“ als auch „Persistente Cookies“ verwenden. Cookies sind meistens kleine Textdateien, die ein Webserver bei einem Aufruf einer Webseite auf Dein Gerät überträgt.</p>
        <p>„Session Cookies“ (temporäre Cookies) werden nicht dauerhaft auf dem jeweiligen Gerät, mit dem Sie unsere Webseite besuchen, gespeichert und verschwinden mit dem Schließen des Browsers. Diese Cookies können u.a. Informationen über den von Dir verwendeten Browser, die Bildschirmauflösung, das verwendete Betriebssystem aber auch andere individuelle Informationen enthalten. Daher können diese Cookies auch dazu genutzt werden, um Dir das Navigieren auf unserer Webseite zu erleichtern und/oder um die korrekte Darstellung auf der jeweiligen Webseite zu gewährleisten.</p>
        <p>„Persistente Cookies“ oder auch „permanente Cookies“ hingegen speichern anonyme Informationen auf dem jeweiligen Gerät und stehen auch nach dem Schließen des Browser weiterhin zur Verfügung. Jedes dieser Cookie ist mit einem entsprechenden „Verfalls“-/Gültigkeitsdatum versehen, zu dem dann dieses Cookie automatisch gelöscht wird. Der Zweck des Einsatzes dieser Cookies besteht u.a. darin, anonyme Statistiken über die Nutzung unserer Internet Präsenzen zu erstellen. Die Statistiken können u.a. dazu dienen, zu erkennen, an welchen Stellen wir unsere Web-Seiten noch optimieren müssen, damit sie für Dich einfacher nutzbar werden.</p>
        <p>Beide Cookie-Arten sind anonym. Es erfolgt keine Einzelprofilbildung über Dein Nutzungsverhalten. Du hast jederzeit die Möglichkeit die Speicherung von Cookies zu unterbinden, indem Du dies in Deinen Browsereinstellungen konfigurierst. Wie Du die jeweiligen Einstellungen zum Deaktivieren der Cookies vorzunehmen hast, lese bitte in den jeweiligen Hilfen zu Deinem Browser nach. Bitte beachte, dass durch die Deaktivierung der Cookies, neben ggf. auftretenden Darstellungs- und Navigationsproblemen, es zu erheblichen Einschränkungen der Funktionalität unserer Webseite kommen kann, sodass eine Funktionstüchtigkeit der Webseite, speziell bzgl. der Übermittlung von Buchungsaufträgen, ohne die Speicherung von Cookies nicht gewährleistet werden kann.</p>
        <p>Wichtig für Dich: Die von uns in den Cookies erfassten Daten werden nicht an unbeteiligte Dritte weitergereicht oder ohne Deine Einwilligung mit Deinen personenbezogenen Daten verknüpft. Auch ist eine eindeutige persönliche Identifikation anhand der erfassten Daten in den Cookies nicht möglich bzw. nicht vorgesehen.</p>
        <ScrollableAnchor id="google_marketing">
          <h4>4. Google Marketing Platform</h4>
        </ScrollableAnchor>
        <p>4.1 Google Analytics</p>
        <p>Diese Webseite benutzt Google Analytics, einen Webanalysedienst der von Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA betrieben wird („Google“). Google Analytics verwendet sog. „Cookies“ und Drittanbieter-Cookies (DoubleClick-Cookies). Dabei handelt es sich um Textdateien, die auf Deinem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Dich ermöglichen und um weitere mit der Websitenutzung und der Internetnutzung verbundenen Dienstleistungen zu erbringen, insbesondere auch Funktionen für Display-Werbung, etwa Remarketing, Berichte zu Impressionen im Google Display-Netzwerk, Integration von Google Campaign Manager oder Google Analytics-Berichte zur Leistung nach demografischen Merkmalen und Interessen.</p>
        <p>Die durch den Cookie erzeugten Informationen über Deine Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf diesen Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Webseite wird Google diese Informationen benutzen, um Deine Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und um weitere mit der Webseitennutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Die im Rahmen von Google Analytics von Deinem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Du kannst die Speicherung der Cookies durch eine entsprechende Einstellung Deiner Browser-Software verhindern; wir weisen Dich jedoch darauf hin, dass Du in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Webseite vollumfänglich nutzen kannst. Du kannst darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Deiner Nutzung der Webseite bezogenen Daten (inkl. Deiner IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Du unter dem folgenden Link (<a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>) die verfügbaren Browser-Plugin zur Deaktivierung von Google Analytics herunterlädst und installierst. Diesen musst Du für jeden verwendeten Browser auf Deinem Geräten separat durchführen.</p>
        <p>Neben dem oben erwähnten Browser-Plugin zur Deaktivierung von Google Analytics besteht alternativ und/oder zusätzlich die Möglichkeit auch ein sogenanntes Opt-Out-Cookie in Deinem verwendeten Browser setzen zu lassen. Bitte beachte, dass Du hierfür JavaScript aktiviert haben musst. </p>
        <p>Mit Setzen dieses Cookies wird die Erfassung durch Google Analytics nur für diesen einen Browser mit Wirkung für die Zukunft verhindert und das auch nur, solange das entsprechende Cookie in Deinem Browser installiert bleibt.</p>
        <p>Wir weisen Dich darauf hin, dass auf dieser Webseite Google Analytics um den Code „gat._anonymizeIp();“ erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten.</p>
        <p>Weitere Informationen zum Thema Datenschutz von Google Analytics findest Du unter www.google.com/intl/de/analytics/privacyoverview.html</p>
        <p>4.2 Tag Manager 360</p>
        <p>Diese Webseite benutzt den Tag Manager 360, einen Tag Managementdienst der von Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA betrieben wird („Google“).</p>
        <p>Tags sind kleine Codeelemente, die wir ggf. auf den von uns betriebenen Webseiten einsetzen, um unter anderem den Traffic und das Besucherverhalten unserer Nutzer zu messen, die Auswirkung von Online-Werbung und sozialen Kanälen zu erfassen, um Remarketing und die Ausrichtung auf Zielgruppen betreiben zu können und um unsere Website zu testen und zu optimieren. Weiter stellen wir durch den Einsatz dieses Tools sicher, dass wir keine redundanten oder falsch angewendeten Tags einsetzen, die dann ggf. u.a. zu einer Verfälschung unserer Messungen führen könnten und darauf basierend ggf. höhere Kosten durch fehlende bzw. fehlerbehafte Daten auf unserer Seite zur Folge hätten. Auch erhalten wir mit diesem Tool ein Werkzeug, welches uns ohne großen Zeitaufwand ermöglicht, neue Tags hinzuzufügen, um wichtige Marketing- und Analyseschritte zu beschleunigen.</p>
        <p>Der Google Tag Manager 360 selbst ist eine cookielose Domain und erfasst keine personenbezogenen Daten. Das Tool sorgt lediglich dafür, dass eine Auslösung anderer „Tracking“-Tags erfolgt, die ihrerseits unter Umständen Daten erfassen. Somit greift der Google Tag Manager 360 nicht auf diese Daten zu, sondern „stößt“ lediglich diese „Verarbeitung“ an. Wenn Du in Deinem Browser auf Domain- oder Cookie-Ebene bereits eine Deaktivierung von den betreffenden Anbietern vorgenommen hast, bleibt diese Deaktivierung selbstverständlich auch für diese betreffenden Tracking-Tags bestehen, die mit Hilfe des Google Tag Manager 360 implementiert worden sind.</p>
        <p>Der Google Tag Manager 360 selbst ist eine cookielose Domain und erfasst keine personenbezogenen Daten. Das Tool sorgt lediglich dafür, dass eine Auslösung anderer „Tracking“-Tags erfolgt, die ihrerseits unter Umständen Daten erfassen. Somit greift der Google Tag Manager 360 nicht auf diese Daten zu, sondern „stößt“ lediglich diese „Verarbeitung“ an. Wenn Du in Deinem Browser auf Domain- oder Cookie-Ebene bereits eine Deaktivierung von den betreffenden Anbietern vorgenommen hast, bleibt diese Deaktivierung selbstverständlich auch für diese betreffenden Tracking-Tags bestehen, die mit Hilfe des Google Tag Manager 360 implementiert worden sind.</p>
        <p>4.3 Google Ads Conversion Tracking (vormals Google Adwords Conversion Tracking)</p>
        <p>Diese Webseite nutzt das Google Ads Conversion, einen Analysedienst der von Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend „Google“ genannt) betrieben wird. Gelangst Du über eine Google-Anzeige auf unsere Webseite, wird automatisch von Google Ads, nicht von uns, ein so genanntes „Conversion Cookie“ gesetzt. Dieses Cookie verliert automatisch nach 30 Tagen seine Gültigkeit und dient nicht Deiner persönlichen Identifizierung. Jeder Ads-Kunde und somit auch wir erhalten von Google ein anderes, eigenes Cookie. Somit können die Cookies auch nicht über die Webseiten von verschiedenen Ads-Kunden hinweg nachverfolgt werden.</p>
        <p>Mit dem Setzen dieses „Conversion Cookie“ können sowohl Google als auch der jeweilige Ads-Kunde und somit auch wir erkennen, dass erstens jemand auf die jeweilige Anzeige geklickt hat und somit zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurde und zweitens wie häufig dieser innerhalb von 30 Tage nochmals auf die jeweilige Anzeige geklickt hat. Die mit Hilfe des „Conversion Cookie“ eingeholten Informationen dienen dazu, um Conversion-Statistiken durch Google zu erstellen.</p>
        <p>Wichtig für Dich: wir erfahren lediglich die Gesamtanzahl und Häufigkeit der Nutzer, die auf die jeweilige Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Webseite weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit denen wir Dich als  Nutzer persönlich identifizieren können. </p>
        <p>Wenn Du nicht an dem Tracking-Verfahren teilnehmen möchten, kannst Du das hierfür erforderliche Setzen eines Cookies ablehnen – etwa per Browser-Einstellung, die das automatische Setzen von Cookies generell deaktiviert. Bitte bedenke, dass die vollständige Deaktivierung des Setzens von Cookies ggf. dazu führen kann, dass die Webseite nicht mehr korrekt angezeigt wird. Weiter kannst Du dediziert das Setzen von Cookies für das Conversion-Tracking deaktivieren, indem Du Deinen Browser so einstellst, dass Cookies von der Domain „www.googleadservices.com“ blockiert werden. Alternativ kannst Du den Einsatz dieser von Google angebotenen Technologie abstellen bzw. unterbinden lassen, indem Du die folgende Seite besuchst und dort nachliest, wie diese möglich ist: <a href="www.youronlinechoices.com/de/praferenzmanagement/" target="_blank">www.youronlinechoices.com/de/praferenzmanagement/</a></p>
        <p>Google hat sich im Rahmen seiner Selbstverpflichtung und seiner Datenschutzbestimmung zur Beachtung dieses dort beschriebenen Verfahrens erklärt. Bitte beachte, dass Du die so gesetzten Opt-out-Cookies nicht löschen darfst, solange Du keine Aufzeichnung von Messdaten wünschst. Hast Du alle Ihre Cookies im Browser gelöscht, musst Du das jeweilige Opt-out Cookie über den oben genannten Link erneut setzen.</p>
        <p>Weitere Informationen zu den Nutzungs- und den Datenschutzrichtlinien für dieses Produkt findest Du hier: <a href="http://www.google.com/policies/technologies/ads/" target="_blank">http://www.google.com/policies/technologies/ads/</a> und <a href="www.google.com/intl/de/policies/privacy/" target="_blank">www.google.com/intl/de/policies/privacy/</a></p>
        <p>4.4 Google Ads Dynamic Remarketing</p>
        <p>Diese Website verwendet die Google Ads Dynamic Remarketing-Funktion der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; nachfolgend „Google“ genannt). Diese Funktion dient dazu, Besuchern der Website im Rahmen des Google-Werbenetzwerks interessenbezogene Werbeanzeigen zu präsentieren. Der Browser des Websitebesuchers speichert sog. „Cookies“, Textdateien, die auf Deinem Computer gespeichert werden und die es ermöglichen, den Besucher wiederzuerkennen, wenn dieser Websites aufruft, die dem Werbenetzwerk von Google angehören. Auf diesen Seiten können dem Besucher dann Werbeanzeigen präsentiert werden, die sich auf Inhalte beziehen, die der Besucher zuvor auf Websites aufgerufen hat, die die Remarketing Funktion von Google verwenden. Nach eigenen Angaben erhebt Google bei diesem Vorgang keine personenbezogenen Daten.</p>
        <p>Wenn Du nicht an dem Tracking-Verfahren teilnehmen möchten, kannst Du das hierfür erforderliche Setzen eines Cookies ablehnen – etwa per Browser-Einstellung, die das automatische Setzen von Cookies generell deaktiviert. Bitte bedenke, dass die vollständige Deaktivierung des Setzens von Cookies ggf. dazu führen kann, dass die Webseite nicht mehr korrekt angezeigt wird. Weiter kannst Du dediziert das Setzen von Cookies für das Conversion-Tracking deaktivieren, indem Du Deinen Browser so einstellst, dass Cookies von der Domain „www.googleadservices.com“ blockiert werden. Alternativ kannst Du den Einsatz dieser von Google angebotenen Technologie abstellen bzw. unterbinden lassen, indem Du die folgende Seite besuchst und dort nachliest, wie diese möglich ist: www.youronlinechoices.com/de/praferenzmanagement/</p>
        <ScrollableAnchor id="facebook">
          <h4>5. Facebook Custom Audience / Conversion Tracking</h4>
        </ScrollableAnchor>
        <p>Diese Website nutzt ein Pixel der Facebook Ireland Ltd. (4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland). Dadurch erhebt Facebook Ireland Limited Informationen über die Nutzung der Website und speichert diese in einem Cookie.</p>
        <p>Diese Daten dienen dazu, Dir interessenbezogene Werbung von BLAUWEGE GMBH anzuzeigen und den Erfolg dieser zu messen. Die Übermittlung personenbezogener Daten durch BLAUWEGE GMBH erfolgt nicht.</p>
        <p>Die durch das Cookie erzeugten Informationen werden regelmäßig an einen Server von Facebook übertragen, der sich ggf. in den USA befindet. Dort werden diese Informationen gespeichert und möglicherweise mit Deinem Facebook-Profildaten zusammengefügt. Möglicherweise entstehen bei Facebook so Nutzerprofile, die über das hinausgehen, was Du selbst an Informationen preisgegeben hast.</p>
        <p>Weitere Informationen dazu erhältst Du in den Datenschutzbestimmungen von Facebook (https://de-de.facebook.com/about/privacy/). </p>
        <ScrollableAnchor id="social">
          <h4>6. Social Plugins</h4>
        </ScrollableAnchor>
        <p>BLAUWEGE GMBH betreibt auf den unterschiedlichen Social Media Plattformen verschiedene Seiten, um mit Hilfe dieser Kanäle mit Kunden und Interessenten in Kontakt zu treten und Informationen zu teilen.</p>
        <p>Wir, die BLAUWEGE GMBH, möchten Dich darauf hinweisen, dass die jeweiligen Betreiber dieser Social Media Plattformen höchstwahrscheinlich die Nutzerdaten ihrer Kunden/Interessenten/Besucher für unterschiedlichste eigene Zwecke, inklusive eigener Werbezwecke sowohl auf den jeweiligen eigenen Social Media Plattformen selbst, sowie auf anderen Webseiten nutzen werden. In welchem Umfang und auf welcher Grundlage dieses geschieht, können wir Ihnen leider nicht sagen. Hierzu möchten wir Dich als Nutzer der entsprechenden Plattform auf die jeweiligen Datenschutzhinweise der betreffenden Social Media Plattformen verweisen.</p>
        <p>Darüber hinaus ist ferner davon auszugehen, dass diese Betreiber der entsprechenden Social Media Plattformen die Nutzerdaten ihrer Kunden/Interessenten/Besucher teilweise auch außerhalb der EU verarbeiten.</p>
        <p>Bei Fragen hinsichtlich der Verarbeitung und Nutzung Deiner Daten durch die betreffenden Social Media Plattformen sowie hinsichtlich der zugehörigen Datenschutzhinweise der betreffenden Social Media Plattformen, als auch Deine Möglichkeiten hinsichtlich des Aussprechens eines entsprechenden Widerspruchs, möchten wir Dich bitten, dass Du Dich am besten direkt an den jeweiligen Social Media Anbieter wendest.</p>
        <p>Auf www.holidayfind.de nutzen wir Interaktions-Buttons. Mit den Interaktions-Buttons kannst Du Inhalte von <a href="holidayfind.de">holidayfind.de</a> per Social Media teilen. </p>
        <p>Für das Teilen von Inhalten musst Du auf den jeweiligen Social Media Plattform eingeloggt sein bzw. Dich einloggen.</p>
        <ScrollableAnchor id="right_opposition">
          <h4>7. Widerspruchs-, Auskunfts- und Löschungsrecht</h4>
        </ScrollableAnchor>
        <p>7.1 Produktbewertungen und -empfehlungen per E-Mail</p>
        <p>Als Kunde von BLAUWEGE GMBH werden wir ggf. Deine E-Mail-Anschrift dazu verwenden, um Dir per E-Mail eine Produktbewertung und/oder andere Bewertungsanfragen zukommen zu lassen, die ausschließlich im Zusammenhang mit Deinem Kauf, Abschluss und/oder anderer analoger Transaktionen stehen. Weiter werden wir ggf. in diesem Zusammenhang Deine E-Mail-Anschrift und/oder Deine Postanschrift auch dazu verwenden, um Dir per E-Mail und/oder per Post Produktempfehlungen zu ähnlichen von uns angebotenen Reisangeboten und -dienstleistungen zukommen zu lassen. Diese Bewertungsanfragen und Produktempfehlungen erhältst Du von uns unabhängig davon, ob Du einen Newsletter abonniert haben. Du kannst diesen Bewertungsanfragen und Produktempfehlungen jederzeit per Brief an BLAUWEGE GMBH (BLAUWEGE GMBH, Hofbichlweg 3, 82392 Habach), per E-Mail an <a href="mailto:info@holidayfind.de">info@holidayfind.de</a> und/oder am Ende einer jeden Bewertungs- und/oder Produktempfehlungs-E-Mail mit Wirkung für die Zukunft widersprechen, ohne dass hierfür weitere Kosten als die jeweiligen Übermittlungskosten nach den Basistarifen anfallen.</p>
        <p>7.2 Widerspruchsrecht</p>
        <p>Weiter weisen wir Dich darauf hin, dass es Dir gemäß Art. 21 DSGVO selbstverständlich frei steht, sämtlich erteilte Einwilligungen jedweder Art und/oder der Speicherung Ihrer Daten entweder selektiv oder vollständig mit Wirkungen für die Zukunft zu widerrufen. Hierzu wende Dich bitte per Brief an BLAUWEGE GMBH (BLAUWEGE GMBH, Hofbichlweg 3, 82392 Habach) oder per E-Mail <a href="mailto:info@holidayfind.de">info@holidayfind.de</a>, ohne dass hierfür weitere Kosten als die jeweiligen Übermittlungskosten nach den Basistarifen anfallen. Wichtig wäre uns, dass Du uns in Deiner Mitteilung die Möglichkeit gibst, dass wir Dich eindeutig identifizieren können, indem Du uns Deinen Namen, Deine Anschrift und Dein Geburtsdatum und einen Bezug zu einem Vorgang, einem Angebot und/oder Abschluss nennst.</p>
        <p>7.3 Auskunftsrechte</p>
        <p>Entsprechend Art. 15 DSGVO erteilen wir Dir sehr gern auf schriftlichen Wunsch über Deine bei uns gespeicherten Daten, die Dir zuordenbar sind. Darüber hinaus hast Du gemäß Art. 20 DSGVO das Recht die uns zur Verfügung gestellten personenbezogenen Daten von uns in einem strukturierten und maschinenlesbaren Format zur Verfügung gestellt zu bekommen. In beiden Fällen richte bitte Deine Anfrage ausschließlich schriftlich an BLAUWEGE GMBH, Hofbichlweg 3, 82392 Habach.</p>
        <p>Bitte beachte, dass eine Auskunft ausschließlich dann erteilt werden kann, wenn Du uns Deinen vollständigen Vor- und Nachnamen, Deine aktuelle und ggf. auch alte Anschrift, Dein Geburtsdatum und Deine E-Mailanschrift angibst. Diese Angaben dienen ausschließlich zum Abgleich und stellen somit für Dich ein Schutz dar, dass nicht unberechtigte Dritte Deine persönlichen Daten erhalten können. Wünschenswert und hilfreich, aber nicht notwendig, wären darüber hinaus auch etwaige Angebots-, Vorgangs- und/oder Vertragsnummern, die wir Dir mitgeteilt haben, damit wir schneller die betreffenden Daten identifizieren können.</p>
        <p>7.4 Löschungsrecht</p>
        <p>Gemäß Art. 17 Abs. 1 DSGVO hast Du das Recht die Löschung bzw. Sperrung Deiner Daten bei uns zu veranlassen. Bitte wenden Dich in dem Fall schriftlich (BLAUWEGE GMBH, Hofbichlweg 3, 82392 Habach) oder per E-Mail (info@holidayfind.de) an uns. </p>
        <p>itte beachte, dass eine Löschung gemäß Art. 17 DSGVO bzw. Einschränkung der Verarbeitung gemäß Art. 18 DSGVO nur dann erfolgen kann, wenn Du uns Deinen vollständigen Vor- und Nachnamen, Deine aktuelle und ggf. auch alte Anschrift, Dein Geburtsdatum und Deine E-Mailanschrift/-en angibst. Wünschenswert und hilfreich, aber nicht notwendig, wären darüber hinaus auch etwaige Angebots-, Vorgangs- und/oder Vertragsnummern, die wir Dir mitgeteilt haben, damit wir schneller die betreffenden Daten identifizieren können. Diese Angaben dienen ausschließlich dazu, um möglichst alle Daten von Dir bei uns zur Löschung bzw. Sperrung identifizieren zu können. Andernfalls können wir nicht sicherstellen, dass wir Deinem Wunsch nach Datenlöschung bzw. Sperrung vollumfänglich sicherstellen können.</p>
        <ScrollableAnchor id="linking">
          <h4>8. Verlinkung</h4>
        </ScrollableAnchor>
        <p>Sofern auf Verweisziele („Links“) direkt oder indirekt verwiesen wird, die außerhalb des Verantwortungsbereichs von BLAUWEGE GMBH stehen, wird nur dann gehaftet, wenn BLAUWEGE GMBH von den Inhalten Kenntnis hat und es ihr technisch möglich und zumutbar gewesen wäre, die Nutzung im Fall rechtswidriger Inhalte zu verhindern. Für darüber hinausgehende Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcher Art dargebotener Informationen entstehen, haftet allein der Anbieter dieser Seiten, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist. BLAUWEGE GMBH distanziert sich von Fremdinhalten jeglicher Art.</p>
        <ScrollableAnchor id="consent_forms">
          <h4>9. Einwilligungserklärungen</h4>
        </ScrollableAnchor>
        <p>Ich willige darin ein, dass im Rahmen einer Online-Buchung einer Reiseleistung die vollständige IP-Adresse meines Computers und das entsprechende Datum und die Uhrzeit an BLAUWEGE GMBH übermittelt und von dieser gespeichert werden darf, um mich und BLAUWEGE GMBH vor einem Missbrauch der Daten zu schützen.</p>
        <ScrollableAnchor id="rules">
          <h4>10. Gewinnspielbedingungen</h4>
        </ScrollableAnchor>
        <p>10.1 Veranstalter</p>
        <p>Blauwege GmbH, Hofbichlweg 3, 82392 Habach</p>
        <p>10.2 Gewinnspielzeitraum</p>
        <p>Beginn mit Veröffentlichung - Ende wie jeweils angegeben.</p>
        <p>10.3 Teilnahmevoraussetzungen</p>
        <p>Es nimmt automatisch jeder teil, der im angegebenen Zeitraum eine Reise bucht.
          Unter „Familie“ werden alle gebuchten Reiseteilnehmer zusammengefasst – es muss kein Verwandtschaftsgrad etc. vorliegen.</p>
        <p>10.4 Gewinn/er/-in</p>
        <p>Der/die Gewinner/-in wird durch Los ermittelt. Die jeweilige Losgröße wird gekennzeichnet.
          Die gebuchte Reise ist im Fall des Gewinns kostenlos. Unter Reise wird die jeweilige Buchung verstanden - unabhängig von der Höhe der Kosten und dier Anzahl der Reiseteilnehmer. Darüber hinaus gehende Zusatzkosten (z.B. Versicherungen, Zubringerkosten, Umbuchungskosten, Zusatzleistungen gebucht vor Ort etc.) sind nicht Bestandteil des Gewinns.</p>
        <p>10.5 Datenschutz</p>
        <p>Mit der Buchung wird ausdrücklich zugestimmt, dass die persönlichen Kontaktdaten eines Gewinners genutzt werden dürfen um diesen zu benachrichtigen. Die Daten werden nicht veröffentlicht.</p>
        <p>Der Rechtsweg ist ausgeschlossen.</p>
        <div className="clear10" />
        <p>Sämtliche Einwilligungserklärungen kannst Du formlos per Brief an BLAUWEGE GMBH, Hofbichlweg 3, 82392 Habach oder per E-Mail <a href="mailto:info@holidayfind.de">info@holidayfind.de</a> mit Wirkung für die Zukunft widerrufen.</p>
        <p>&copy; 2019 BLAUWEGE GMBH </p>
        <p>(Stand April 2019)</p>
      </div>
    </div>
  );
};

Datenschutz.propTypes = {
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Datenschutz);
