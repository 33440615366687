import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
// eslint-disable-next-line
import 'owl.carousel/dist/assets/owl.carousel.css';
import Item from './Item';

const EmptyItem = () => (
  <div
    style={{
      float: 'left',
      width: '30%',
      margin: '0 1.5%',
      border: '5px solid transparent',
    }}
  />
);

class Book extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(newGiata) {
    const { list, item, onChange } = this.props;
    if (newGiata !== item.giata) {
      onChange(list.find(({ giata }) => newGiata === giata));
    }
  }

  render() {
    const { item } = this.props;
    if (!item) {
      return <Redirect to="/unsere-angebote" />;
    }

    const { list } = this.props;
    const hotels = list.map(hotelItem => hotelItem);
    if (hotels.length > 4) {
      hotels.splice(4, 0, null);
    }
    const startPosition = Math.floor(hotels.reduce((res, hotelItem, index) => (
      hotelItem && hotelItem.giata === item.giata ? index : res
    ), 0) / 3);

    const trios = [];
    for (let i = 0; i < hotels.length; i += 3) {
      trios.push([hotels[i], hotels[i + 1], hotels[i + 2]]);
    }

    return (
      <OwlCarousel
        key={item.giata} // to rerender for new active item
        margin={1}
        // loop // uncomment to get infinity slider
        nav
        dots={false}
        startPosition={startPosition}
        className="slider_thubnails slider_book"
        items={1}
        responsiveClass
      >{trios.map(([item1, item2, item3]) => (
        <div key={`${item1.giata}`}>
          {item1 && (
            <Item
              {...item1}
              isSelected={item.giata === item1.giata}
              onChanged={() => this.onChange(item1.giata)}
            />)}
          {item2 ? (
            <Item
              {...item2}
              isSelected={item.giata === item2.giata}
              onChanged={() => this.onChange(item2.giata)}
            />) : <EmptyItem />}
          {item3 && (
          <Item
            {...item3}
            isSelected={item.giata === item3.giata}
            onChanged={() => this.onChange(item3.giata)}
          />)}
        </div>))}
      </OwlCarousel>
    );
  }
}

Book.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  item: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
};

export default Book;
