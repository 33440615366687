import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { show } from '../../actions/popup';
import GreenSquareMobile from './_GreenSquareMobile';

const CheckboxWrapper = styled.div`
  @media(max-width: 640px) {
    display: none;
  }
`;

const FamilyWrapper = styled.div`
  @media(max-width: 640px) {
    margin-left: 0;
    
    img {
      margin-left: 0;
    }
  }
`;


const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  showPopup: show,
}, dispatch);

const Title = ({ showPopup }) => (
  <div className="triple section_title_block section_title_block_landing">
    <button type="button" onClick={() => showPopup('bestprice')}>
      <img className="partner_link" src="images/partner_logo.svg" alt="Logo" />
    </button>
    <div className="family_top_block header_sm_show">
      <FamilyWrapper className="family_img header_family_img">
        <img src="images/animation/family3.svg" />
      </FamilyWrapper>
      <GreenSquareMobile />
    </div>
    <div className="section_title">
      <h1>Viel Freude beim Auswählen <span className="red">eurer Urlaubs-Interessen</span></h1>
      <div className="clear" />
      <CheckboxWrapper className="checkbox green_checkbox">
        <input
          id="ecofriendly"
          type="checkbox"
          checked="checked"
        />
        <label htmlFor="ecofriendly" className="checkbox_view" />
        <label htmlFor="ecofriendly" className="label_line active">
          <span>Umweltfreundliche Hotels extra hervorheben</span>
        </label>
      </CheckboxWrapper>
    </div>
    <div className="clear" />
  </div>
);

Title.propTypes = {
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Title);
