import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PopupMatches from '../../Result/Popup/Score';
import PopupMap from '../Popup/Map';
import PopupReview from '../Popup/Reviews';
import PopupGallery from '../Popup/Gallery';
import Hotel from './Hotel';
import Iframe from './Iframe';

class Details extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isScorePopupShowed: false,
      isMapPopupShowed: false,
      isReviewPopupShowed: false,
      isGalleryPopupShowed: false,
      galleryImageIndex: 0,
    };
  }

  render() {
    const {
      isScorePopupShowed, isMapPopupShowed, isReviewPopupShowed,
      isGalleryPopupShowed, galleryImageIndex,
    } = this.state;

    const { item, selectioncode, ibeParams } = this.props;

    return (
      <div key={item.giata}>
        <div className="slider_block">
          <Hotel
            {...item}
            selectioncode={selectioncode}
            showScorePopup={() => this.setState({ isScorePopupShowed: true })}
            showMapPopup={() => this.setState({ isMapPopupShowed: true })}
            showReviewPopup={() => this.setState({ isReviewPopupShowed: true })}
            showGalleryPopup={imageIndex => this.setState({
              isGalleryPopupShowed: true,
              galleryImageIndex: imageIndex,
            })}
          />
          <div className="clear" />
        </div>
        {isScorePopupShowed && (
          <div className="popup">
            <div className="popup_block">
              <button
                type="button"
                className="close_btn"
                onClick={() => this.setState({ isScorePopupShowed: false })}
              />
              <PopupMatches matchlist={item.matchlist} mismatchlist={item.mismatchlist} />
            </div>
          </div>)}
        {item.map && isMapPopupShowed && (
          <div className="popup">
            <div className="popup_block">
              <button
                type="button"
                className="close_btn"
                onClick={() => this.setState({ isMapPopupShowed: false })}
              />
              <PopupMap name={item.name} location={item.location} {...item.map} />
            </div>
          </div>)}
        {isReviewPopupShowed && (
          <div className="popup review_popup">
            <div className="popup_block">
              <button
                type="button"
                className="close_btn"
                onClick={() => this.setState({ isReviewPopupShowed: false })}
              />
              <PopupReview giata={item.giata} name={item.name} />
            </div>
          </div>)}
        {isGalleryPopupShowed && (
          <div className="popup popup_gallery">
            <div className="popup_block">
              <button
                type="button"
                className="close_btn"
                onClick={() => this.setState({ isGalleryPopupShowed: false })}
              />
              <PopupGallery images={item.images} startIndex={galleryImageIndex} />
            </div>
          </div>)}
        <div className="frame_block"><Iframe giata={item.giata} ibeParams={ibeParams} /></div>
        <p className="tr" style={{ paddingTop: 30 }}>
          <Link className="f17 back_btn" to="/unsere-angebote">zurück</Link>
        </p>
      </div>
    );
  }
}

Details.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  item: PropTypes.objectOf(PropTypes.any),
  selectioncode: PropTypes.string.isRequired,
  ibeParams: PropTypes.objectOf(PropTypes.any),
};

export default Details;
