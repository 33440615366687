import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import { reset } from '../../actions/categories';
import Title from './Title';
import BottomPopup from './BottomPopup';
import Category from './Category';

const mapStateToProps = ({ categories: { list, selected } }) => ({
  list,
  selected,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  resetSelected: reset,
}, dispatch);

const Categories = ({ list, selected, resetSelected }) => {
  const [expanded, setExpanded] = useState({});
  const [popupShowed, setPopupShowed] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggle = id => setExpanded({ [id]: !expanded[id] });
  const isSubmitAllowed = () => Object.keys(selected).length;

  return (
    <div className="content">
      <Helmet>
        <title>Urlaub mit Kindern | holidayfind.de</title>
        <meta name="description" content="holidayfind.de findet ausgesucht klimafreundliche und
         attraktive Urlaubsziele, die alle individuellen Wünsche von Eltern und Kindern erfüllen." />
        <meta name="keywords" content="urlaub familie, urlaub familie meer, urlaub mit kindern, urlaub buchen" />
      </Helmet>
      <Title resetSelected={resetSelected} />
      <div className="clear" />
      <div className="flex_content stretch">{list.map(category => (
        <Category
          key={category.id}
          {...category}
          isExpanded={expanded[category.id]}
          onToggle={() => toggle(category.id)}
        />))}
      </div>
      <div className="clear" />
      <p className="tr fr white f16">
        Alle Interessen wieder <button type="button" onClick={resetSelected}><u>löschen</u></button>.
      </p>
      <div className="clear" />
      <div className="bottom_btns categories_btns">
        <button
          type="button"
          className={`landing_btn btn${isSubmitAllowed() && !popupShowed ? '' : ' disabled'}`}
          onClick={() => {
            if (isSubmitAllowed()) setPopupShowed(true);
          }}
        >Weiter
        </button>
        <div className="clear" />
      </div>
      <div className="clear20" />
      {popupShowed && <BottomPopup />}
    </div>
  );
};

Categories.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.objectOf(PropTypes.bool),
  resetSelected: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
