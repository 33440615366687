import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobile } from 'react-device-detect';
import { hide } from '../../actions/popup';
import ExitPopup from './Exit';
import BestPricePopup from './BestPrice';
import ClimateFriendlyPopup from './ClimateFriendly';
import ClimateMapPopup from './ClimateMap';
import ClimateHotelPopup from './ClimateHotel';
import ClimateAirlinePopup from './ClimateAirline';
import Mob from './Mob';

const mapStateToProps = ({ popup: { name } }) => ({
  name,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  hidePopup: hide,
}, dispatch);

const list = ['bestprice', 'climatefriendly', 'climatemap', 'climatehotel', 'climateairline']

const Popups = ({ location, name, hidePopup }) => (
  <React.Fragment>
    {location.pathname !== '/' && <ExitPopup />}
    {name && list.includes(name) && (
      isMobile
        ? <Mob onClose={hidePopup} />
        : (
          <React.Fragment>
            <BestPricePopup isShown={name === 'bestprice'} onClose={hidePopup} />
            <ClimateFriendlyPopup isShown={name === 'climatefriendly'} onClose={hidePopup} />
            <ClimateMapPopup isShown={name === 'climatemap'} onClose={hidePopup} />
            <ClimateHotelPopup isShown={name === 'climatehotel'} onClose={hidePopup} />
            <ClimateAirlinePopup isShown={name === 'climateairline'} onClose={hidePopup} />
          </React.Fragment>
        )
    )}
  </React.Fragment>
);
// NOTE: ExitPopup skipped on Intro

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Popups));
