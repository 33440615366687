export const CATEGORIES = {
  TOGGLE_CHECKBOX: 'CATEGORIES_TOGGLE_CHECKBOX',
  RESET: 'CATEGORIES_RESET',
  SET_REQ: 'CATEGORIES_SET_REQ',
  PREPARE: 'COUNTRIES_PREPARE',
  PREPARE_DONE: 'COUNTRIES_PREPARE_DONE',
  PREPARE_FAILED: 'COUNTRIES_PREPARE_FAILED',
};

export const toggleCheckbox = id => ({ type: CATEGORIES.TOGGLE_CHECKBOX, id });
export const setReq = id => ({ type: CATEGORIES.SET_REQ, id });
export const reset = () => ({ type: CATEGORIES.RESET });
export const prepare = () => ({ type: CATEGORIES.PREPARE });
