import React from 'react';

export default () => (
  <React.Fragment>
    <p>
      17.1 Wir <b>garantieren</b> eine kostenlose Stornierung von über holidayfind.de gebuchten Pauschalreisen (eine Pauschalreise liegt
      immer dann vor, wenn mindestens zwei verschiedene Arten von Reiseleistungen für den Zweck derselben Reise gebucht werden, z.B. Flug
      und Hotel) für den Fall, dass eine, vom Auswärtigen Amt ausgesprochene Reisewarnung wegen unvermeidbarer und außergewöhnlicher Umstände im
      Zusammenhang mit der Coronavirus-Epidemie für das gebuchte Reiseland vorlag. Diese Reisewarnung war zum Zeitpunkt der Buchung nicht
      vorhersehbar - d.h. konkret, dass an dem Tag keine vom Auswärtigen Amt keine Reisewarnung vorlag.
    </p>
    <p>
      17.2 Im Fall einer Stornierung wenden sie sich zuerst an den Veranstalter und teilen Ihr Reisestorno via Einschreiben/Rückschein mit.
      <br />
      Der Veranstalter ist nach deutschem Reiserecht verpflichtet im o.g. Fall Erstattung ohne Abzug zu leisten. Wir ergänzen diese
      Verpflichtung durch unsere Storno-Garantie für den Fall, dass der Veranstalter sich erfolgreich weigert.
    </p>
    <p>
      17.3 Falls der Veranstalter Ihnen weder eine vertretbare und gleichwertige oder bessere Reisealternative anbietet oder Sie diese
      begründet ablehnen und Ihnen der Veranstalter trotzdem erfolgreich die durch Ihr Storno verursachte Rückzahlung verweigert,
      so <b>übernehmen wir GARANTIERT die Reisekosten der Buchung</b> (ohne weitere Zusätze, wie Anfahrtskosten, entgangene Urlaubsfreuden
      etc.). In dem Fall, dass der Veranstalter teilweise die Reisekosten erstattet, ist diese Erstattung bei den Gesamt-Reisekosten in
      Abzug zu bringen.
    </p>
    <p>
      Senden Sie uns einfach die bestätigte Buchung sowie alle Unterlagen, die Sie an den Veranstalter im Zusammenhang mit Ihrem Storno
      geschickt haben, sowie gegebenenfalls dessen Antwort/en an: <a href="mailto:storno@holidayfind.de">storno@holidayfind.de</a>
    </p>
    <p>
      17.4 Im Fall der Kostenübernahme treten Sie verpflichtend die Ihnen zustehenden Forderungen und Ansprüche gegen den Veranstalter an
      den Betreiber von holidayfind.de, die Blauwege GmbH ab.
    </p>
    <p>
      17.5 Informationen des Auswertigen Amtes mit tagesaktuellen Reisewarnungen:
      <br />
      <a href="https://www.auswaertiges-amt.de/de/ReiseUndSicherheit/10.2.8Reisewarnungen" target="_blank">
        https://www.auswaertiges-amt.de/de/ReiseUndSicherheit/10.2.8Reisewarnungen
      </a>
    </p>
    <p>
      17.6 Diese Garantie bezieht sich ausschließlich auf den o.g. Stornierungsfall und ersetzt nicht eine Reiseversicherung für andere
      Situationen.
    </p>
  </React.Fragment>
);
