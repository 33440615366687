import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import { isMobileOnly } from 'react-device-detect';
import {
  load, toggleCheckbox, selectAll, unselectAll,
} from '../../actions/countries';
import { reset } from '../../actions/result';
import { getPeople } from '../../selectors/result';
import PopupZeroSelected from './_PopupZeroSelected';
import BottomPopup from './BottomPopup';
import Country from './Country';
import CountrySmall from './CountrySmall';
import Spinner from '../Spinner';

const mapStateToProps = ({
  countries: { list, listTop, selected, isLoading }, categories, calendar, person, duration, budget,
}) => ({
  list,
  listTop,
  selected,
  isLoading,
  valid: Object.keys(categories.selected).length
    && calendar.from
    && calendar.to
    && getPeople({ person }).adult
    && Object.keys(duration.selected).filter(key => duration.selected[key]).length
    && Object.keys(budget.selected).filter(key => budget.selected[key]).length ? true : false,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  loadCountries: load,
  toggle: toggleCheckbox,
  selectAllAction: selectAll,
  unselectAllAction: unselectAll,
  resetResult: reset,
}, dispatch);

const Countries = ({
  valid, isLoading, loadCountries, list, listTop, selected,
  resetResult, toggle, selectAllAction, unselectAllAction,
}) => {
  if (!valid)
    return <Redirect to="/reisedaten" />;

  const [isPopupShowed, setIsPopupShowed] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadCountries();
  }, []);

  if (isLoading)
    return (
      <Spinner>
        <div>Wir prüfen gerade über 100 Länder.</div>
        <div>Der Vorgang kann bis zu 25 Sekunden dauern...</div>
      </Spinner>
    );

  const isSubmitAllowed = () => Object.keys(selected).length;

  const isZeroMatches = () => {
    let isZero = true;
    listTop.forEach(({ Top100_Matches }) => { if (isZero && Top100_Matches) isZero = false; });
    list.forEach(({ Top100_Matches }) => { if (isZero && Top100_Matches) isZero = false; });
    return isZero;
  };

  const isZeroSelected = () => {
    let isZero = true;
    listTop.forEach(({ id, Top100_Matches }) => { if (isZero && Top100_Matches && selected[id]) isZero = false; });
    list.forEach(({ id, Top100_Matches }) => { if (isZero && Top100_Matches && selected[id]) isZero = false; });
    return isZero;
  };

  const submit = ev => {
    if (!isSubmitAllowed()) {
      ev.preventDefault();
      return;
    }

    if (
      Object.keys(selected).length === 1
      && listTop.concat(list).find(({ id, count }) => selected[id] && count > 0)
    ) { // use "deep dive" independent on Top100_Matches == 0 or != 0, just count should be greater than 0
      resetResult();
      return;
    }

    if (isZeroSelected()) {
      ev.preventDefault();
      setIsPopupShowed(true);
      return;
    }

    resetResult();
  };

  return (
    <div className="content">
      <Helmet>
        <title>Familienurlaub an den schönsten Orten | holidayfind.de</title>
        <meta name="description" content="Mit holidayfind.de findet alle Reiseländer in denen alle Wünsche deiner
         Familie an einen attraktiven und klimafreundlichen Familienurlaub erfüllt werden." />
        <meta name="keywords" content="familienurlaub buchen, familienurlaub finden, mit familie reisen" />
      </Helmet>
      {isPopupShowed && <PopupZeroSelected isZeroMatches={isZeroMatches()} close={() => setIsPopupShowed(false)} />}
      <div className="countries">
        <div className="triple section_title_block country_section_title_block">
          <div className="countyr_head_button_block">
            <Link
              to="/unsere-angebote"
              className={`btn${isSubmitAllowed() ? '' : ' disabled'}`}
              onClick={submit}
            >Passende Reisen/Hotels finden
            </Link>
            <div className="clear" />
            <h5 className="mobile_show">Auf Wunsch jetzt Länder streichen.</h5>
            <p className="fr nt_mb"><Link className="f17 back_btn" to="/reisedaten">zurück</Link></p>
          </div>
          <div className="section_title">
            <h1>Länder mit Top-Urlaubstreffern</h1>
            <div className="clear" />
            <h5>
              <span className="green"><b>Die Treffergenauigkeit</b></span> zu euren persönlichen
              Urlaubs-Interessen <span className="red"><b>ist in diesen Ländern</b></span> <span className="green"><b>am höchsten:</b></span>
            </h5>
          </div>
          <div className="clear" />
        </div>
        <div className="clear10" />
        <div className="flex_content">{listTop.map(country => (
          <Country
            key={country.id}
            {...country}
            isSelected={!!selected[country.id]}
            onChange={() => {
              resetResult();
              toggle(country.id);
            }}
          />))}
          {listTop.length ? listTop.length < 4 && (
            <div className="country_block text_country_block">
              <p className="nt_mb"><b>Tipp:</b></p>
              <p>Überprüfe deine Reisefakten.</p>
              <p>
                Wenn du z.B. den Zeitraum oder/und das Budget veränderst, ist es gut möglich,
                dass weitere Länder für euch interessant werden.
              </p>
            </div>
          ) : (
            <div className="country_block full_country_block">
              <p>
                <b>Wir haben leider kein passendes Land entdeckt.</b> Überprüfe bitte deine Reisefakten.
                Wenn du z.B. den Zeitraum oder/und das Budget veränderst, finden wir sicher Länder für dich...
              </p>
            </div>
          )}
        </div>
        <div className="clear" />
        <h5 className="nt_mb mobile_hide">
          <span style={{ position: 'absolute' }}>Auf Wunsch jetzt Reiseziele reduzieren.</span>
          <span style={{ marginLeft: 275, display: 'block' }}>
            <b style={{ position: 'absolute', marginLeft: -38 }}>Tipps:</b>
            Bei der Suche nach <b className="red">nur einem Reiseziel</b> zuerst alle anderen Länder <button
              style={{ textDecoration: 'underline' }}
              onClick={() => { resetResult(); unselectAllAction(); }}
            >hier</button> deaktivieren und dann erneut dein Wunschland aktivieren.<br />
            Falls bei dieser Suche für dein Wunschland keine Treffer angezeigt wurden: Einfach Interessen oder/und
            Zeitraum*Budget <Link to="/urlaubsinteressen" style={{ textDecoration: 'underline' }}>hier</Link> modifizieren.
          </span>
        </h5>
        <div className="line" />
        <h2 className="nt_mb">Weitere Länder</h2>
        <p>
          Die <b>Passgenauigkeit zu euren Interessen</b> ist bei den Urlaubstreffern in diesen
          Ländern <b>niedriger oder es liegen keine verfügbaren Angebote vor:</b>
        </p>
        <div className="flex_content">
          {list.map(country => (
            <CountrySmall
              key={country.id}
              {...country}
              isSelected={!!selected[country.id]}
              onChange={() => {
                resetResult();
                toggle(country.id);
              }}
            />
          ))}
        </div>
        <div className="clear" />
        <div className="fr">
          <Link
            to="/unsere-angebote"
            className={`fr btn${isSubmitAllowed() ? '' : ' disabled'}`}
            onClick={submit}
          >Passende Hotels finden
          </Link>
        </div>
        <div className="fr">
          <button
            type="button"
            className="select_all_btn"
            onClick={() => {
              resetResult();
              selectAllAction();
            }}
          >{'Alle Länder aktivieren'}
          </button>
        </div>
        <div className="fr">
          <button
            type="button"
            className="select_all_btn"
            onClick={() => {
              resetResult();
              unselectAllAction();
            }}
          >{'Alle Länder deaktivieren'}
          </button>
        </div>
        <div className="clear20" />
      </div>
      {!isMobileOnly && <BottomPopup />}
    </div>
  );
};

Countries.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.objectOf(PropTypes.bool),
  loadCountries: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  selectAllAction: PropTypes.func.isRequired,
  unselectAllAction: PropTypes.func.isRequired,
  resetResult: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Countries);
