import React from 'react';

export default ({ onClose }) => (
  <div className="popup">
    <div className="popup_block">
      <button type="button" className="close_btn" onClick={onClose} />
      <div className="popup_content" style={{ overflow: 'unset' }}>
        <div className="popup_body popup_mob_body tl">
          <h1><span className="logo_shamrock"></span><span>Klimafreundlicher reisen</span></h1>
          <div className="clear" />
          <p>
            Zur besseren Orientierung, wie man Pauschalreisen klimafreundlicher {'auswählen'} kann,
            hat <span className="logo_inline" /> eine Reihe informativer Punkte zusammengestellt.
          </p>
          <p><b>Wegen der zum Teil detailreichen Abbildungen {'können'} diese nur auf PC/Mac optimal dargestellt werden.</b></p>
          <p>Dort findest du einen {'Überblick'} in Sachen Klimaschutz – bezogen auf {'Urlaubsländer'}, Hotels und Airlines.</p>
          <div className="clear" />
        </div>
        <div className="clear" />
      </div>
    </div>
  </div>
);
