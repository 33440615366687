import { isIE, isEdge, isMobileOnly } from 'react-device-detect';
import { categories } from '../assets/data/departures.json';

export const getUrlParams = (search) => {
  const urlParams = {};

  if (!search) {
    return urlParams;
  }

  search.slice(1).split('&').forEach((param) => {
    const [name, value] = param.split('=');
    urlParams[name] = value ? decodeURIComponent(value) : '';
  });

  return urlParams;
};

export const getSelectedAirports = (selectedIDs) => {
  const codes = {};
  const selectedAirports = categories.reduce((res, cat) => (
    res.concat(cat.airports.filter(airport => selectedIDs[airport.id]))
  ), []);
  // get unique
  return selectedAirports.filter((airport) => {
    if (codes[airport.code]) {
      return false;
    }
    codes[airport.code] = true;
    return true;
  });
};

/**
 * @param {number}
 * @return array of bool values: true for star, false for half-star
 */
export const getStars = (number) => {
  const stars = []; // format: 35
  for (let i = 0; i < number; i++) {
    stars.push(true);
  }
  if (number % 1) {
    stars.push(false);
  }
  return stars;
};

const isShowIntro = () => {
  if (isIE || isEdge || isMobileOnly) {
    return false;
  }
  const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  if (viewportWidth < 768) {
    return false;
  }
  return true;
};
export const showIntro = isShowIntro();
