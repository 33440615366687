import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSelectedAirports } from '../../../utils/helper';
import AcceptButton from '../AcceptButton';
import Category from './Category';
import NoHotel from './NoHotel';

const mapStateToProps = ({ departures: { isNewUser, list, selected } }) => ({
  isNewUser,
  list,
  selected,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const Departure = ({ isNewUser, list, selected }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggle = () => setIsExpanded(!isExpanded);
  const selectedItem = getSelectedAirports(selected).map(({ name }) => name).join(' ');
  const title = selectedItem || (isNewUser ? 'Bitte wählen' : 'Nur Hotel / Ohne Flug');

  return (
    <div className={`content_block${isExpanded ? ' open' : ''} ${selectedItem ? '' : 'content_block_empty'}`}>
      <header onClick={toggle}>
        <p>
          <span>mit/ohne Flug</span>
        </p>
      </header>
      <div className="content_block_body">
        <button
          type="button"
          className={`empty_block${isExpanded ? ' hidden' : ''}`}
          onClick={toggle}
        >
          <span>{title}</span>
          <i className="icon_down" />
        </button>
        <div className={`open_content_block headered${isExpanded ? '' : ' hidden'}`}>
          <button
            type="button"
            className="close_content_block_btn"
            onClick={toggle}
          ><i className="icon_down" />
          </button>
          <div className="head" />
          <div className="open_content_block_body buttoned">
            <NoHotel />
            <p className="nt_mb"><b>ABFLUGHÄFEN:</b></p>
            {list.map(category => <Category key={category.id} {...category} />)}
          </div>
          <AcceptButton onAccept={toggle} />
        </div>
      </div>
    </div>
  );
};

Departure.propTypes = {
  isNewUser: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(PropTypes.any),
  selected: PropTypes.objectOf(PropTypes.bool),
};

export default connect(mapStateToProps, mapDispatchToProps)(Departure);
