export default [
  { id: 'l1', iso: 'EG', name: 'Ägypten', leaves: 2 },
  { id: 'l202', iso: 'DZ', name: 'Algerien', leaves: 2 },
  { id: 'l199', iso: 'AI', name: 'Anguilla', leaves: 1 },
  { id: 'l38', iso: 'AG', name: 'Antigua/Barbuda', leaves: 1 },
  { id: 'l44', iso: 'AR', name: 'Argentinien', leaves: 0 },
  { id: 'l45', iso: 'AM', name: 'Armenien', leaves: 2 },
  { id: 'l14', iso: 'AU', name: 'Australien', leaves: 0 },
  { id: 'l48', iso: 'BS', name: 'Bahamas', leaves: 0 },
  { id: 'l49', iso: 'BH', name: 'Bahrain', leaves: 1 },
  { id: 'l36', iso: 'BB', name: 'Barbados', leaves: 1 },
  { id: 'l52', iso: 'BE', name: 'Belgien', leaves: 3 },
  { id: 'l53', iso: 'BZ', name: 'Belize', leaves: 0 },
  { id: 'l209', iso: 'BM', name: 'Bermuda', leaves: 1 },
  { id: 'l59', iso: 'BRA', name: 'Brasilien', leaves: 0 },
  { id: 'l221', iso: 'VG', name: 'Br. Jungferninseln', leaves: 1 },
  { id: 'l60', iso: 'BN', name: 'Brunei', leaves: 0 },
  { id: 'l3', iso: 'BG', name: 'Bulgarien', leaves: 3 },
  { id: 'l64', iso: 'CN', name: 'China', leaves: 0 },
  { id: 'l210', iso: 'CK', name: 'Cookinseln', leaves: 0 },
  { id: 'l65', iso: 'CR', name: 'Costa Rica', leaves: 0 },
  { id: 'l242', iso: 'CW', name: 'Curaçao', leaves: 0 },
  { id: 'l41', iso: 'DE', name: 'Deutschland', leaves: 3 },
  { id: 'l69', iso: 'DM', name: 'Dominica', leaves: 1 },
  { id: 'l5', iso: 'DO', name: 'Dom. Republik', leaves: 1 },
  { id: 'l70', iso: 'DJ', name: 'Dschibuti', leaves: 1 },
  { id: 'l71', iso: 'EC', name: 'Ecuador', leaves: 0 },
  { id: 'l74', iso: 'EE', name: 'Estland', leaves: 3 },
  { id: 'l75', iso: 'FJ', name: 'Fidschi', leaves: 0 },
  { id: 'l76', iso: 'FI', name: 'Finnland', leaves: 3 },
  { id: 'l31', iso: 'FR', name: 'Frankreich', leaves: 3 },
  { id: 'l214', iso: 'PF', name: 'Franz. Polynesien', leaves: 0 },
  { id: 'l78', iso: 'GM', name: 'Gambia', leaves: 1 },
  { id: 'l81', iso: 'GD', name: 'Grenada', leaves: 1 },
  { id: 'l7', iso: 'GR', name: 'Griechenland', leaves: 3 },
  { id: 'l82', iso: 'GB', name: 'GB / England', leaves: 3 },
  { id: 'l211', iso: 'GP', name: 'Guadeloupe', leaves: 1 },
  { id: 'l90', iso: 'IN', name: 'Indien', leaves: 1 },
  { id: 'l10', iso: 'ID', name: 'Indonesien / Bali', leaves: 0 },
  { id: 'l93', iso: 'IE', name: 'Irland', leaves: 3 },
  { id: 'l95', iso: 'IL', name: 'Israel', leaves: 2 },
  { id: 'l13', iso: 'IT', name: 'Italien', leaves: 3 },
  { id: 'l34', iso: 'JM', name: 'Jamaika', leaves: 0 },
  { id: 'l96', iso: 'JP', name: 'Japan', leaves: 0 },
  { id: 'l98', iso: 'JO', name: 'Jordanien', leaves: 2 },
  { id: 'l212', iso: 'KY', name: 'Kaimaninseln', leaves: 0 },
  { id: 'l100', iso: 'KH', name: 'Kambodscha', leaves: 0 },
  { id: 'l102', iso: 'CA', name: 'Kanada', leaves: 1 },
  { id: 'l103', iso: 'CV', name: 'Kapverdische Inseln', leaves: 1 },
  { id: 'l208', iso: 'BQ', name: 'Karibische NL', leaves: 0 },
  { id: 'l105', iso: 'QA', name: 'Katar', leaves: 1 },
  { id: 'l17', iso: 'KE', name: 'Kenia', leaves: 1 },
  { id: 'l108', iso: 'CO', name: 'Kolumbien', leaves: 0 },
  { id: 'l109', iso: 'KM', name: 'Komoren', leaves: 1 },
  { id: 'l33', iso: 'HR', name: 'Kroatien', leaves: 3 },
  { id: 'l21', iso: 'CU', name: 'Kuba', leaves: 0 },
  { id: 'l114', iso: 'KW', name: 'Kuwait', leaves: 1 },
  { id: 'l115', iso: 'LA', name: 'Laos', leaves: 0 },
  { id: 'l117', iso: 'LV', name: 'lettland', leaves: 3 },
  { id: 'l118', iso: 'LB', name: 'Libanon', leaves: 2 },
  { id: 'l124', iso: 'MG', name: 'Madagaskar', leaves: 1 },
  { id: 'l22', iso: 'MY', name: 'Malaysia', leaves: 0 },
  { id: 'l9', iso: 'MV', name: 'Malediven', leaves: 1 },
  { id: 'l27', iso: 'MT', name: 'Malta', leaves: 3 },
  { id: 'l18', iso: 'MA', name: 'Marokko', leaves: 2 },
  { id: 'l213', iso: 'MQ', name: 'Martinique', leaves: 1 },
  { id: 'l20', iso: 'MU', name: 'Mauritius', leaves: 0 },
  { id: 'l11', iso: 'MX', name: 'Mexiko', leaves: 0 },
  { id: 'l130', iso: 'FM', name: 'Mikronesien', leaves: 0 },
  { id: 'l234', iso: 'ME', name: 'Montenegro', leaves: 3 },
  { id: 'l134', iso: 'MZ', name: 'Mosambik', leaves: 1 },
  { id: 'l135', iso: 'MM', name: 'Myanmar/Burma', leaves: 0 },
  { id: 'l136', iso: 'NA', name: 'Namibia', leaves: 1 },
  { id: 'l222', iso: 'NC', name: 'Neukaledonien', leaves: 0 },
  { id: 'l139', iso: 'NZ', name: 'Neuseeland', leaves: 0 },
  { id: 'l40', iso: 'NL', name: 'Niederlande', leaves: 3 },
  { id: 'l144', iso: 'NO', name: 'Norwegen', leaves: 3 },
  { id: 'l145', iso: 'OM', name: 'Oman', leaves: 1 },
  { id: 'l146', iso: 'AT', name: 'Österreich', leaves: 3 },
  { id: 'l148', iso: 'PW', name: 'Palau', leaves: 0 },
  { id: 'l150', iso: 'PA', name: 'Panama', leaves: 0 },
  { id: 'l154', iso: 'PH', name: 'Philippinen', leaves: 0 },
  { id: 'l155', iso: 'PL', name: 'Polen', leaves: 3 },
  { id: 'l12', iso: 'PT', name: 'Portugal', leaves: 2 },
  { id: 'l215', iso: 'PR', name: 'Puerto Rico', leaves: 1 },
  { id: 'l216', iso: 'RE', name: 'Réunion', leaves: 0 },
  { id: 'l157', iso: 'RO', name: 'Rumänien', leaves: 3 },
  { id: 'l158', iso: 'RU', name: 'Russland', leaves: 2 },
  { id: 'l160', iso: 'ZM', name: 'Sambia', leaves: 1 },
  { id: 'l165', iso: 'SE', name: 'Schweden', leaves: 3 },
  { id: 'l166', iso: 'CH', name: 'Schweiz', leaves: 3 },
  { id: 'l168', iso: 'SC', name: 'Seychellen', leaves: 1 },
  { id: 'l171', iso: 'SG', name: 'Singapur', leaves: 0 },
  { id: 'l173', iso: 'SI', name: 'Slowenien', leaves: 3 },
  { id: 'l2', iso: 'ES', name: 'Spanien', leaves: 3 },
  { id: 'l24', iso: 'LK', name: 'Sri lanka', leaves: 1 },
  { id: 'l235', iso: 'BL', name: 'Saint-Barthélemy', leaves: 1 },
  { id: 'l175', iso: 'KN', name: 'St. Kitts und Nevis', leaves: 1 },
  { id: 'l176', iso: 'LC', name: 'St. Lucia', leaves: 1 },
  { id: 'l223', iso: 'SX', name: 'St. Martin (frz.)', leaves: 1 },
  { id: 'l220', iso: 'VC', name: 'St. Vincent/Grenad.', leaves: 1 },
  { id: 'l178', iso: 'ZA', name: 'Südafrika', leaves: 0 },
  { id: 'l113', iso: 'KR', name: 'Südkorea', leaves: 0 },
  { id: 'l181', iso: 'SZ', name: 'Swasiland-Eswatini', leaves: 0 },
  { id: 'l184', iso: 'TZ', name: 'Tansania', leaves: 1 },
  { id: 'l8', iso: 'TH', name: 'Thailand', leaves: 0 },
  { id: 'l186', iso: 'TO', name: 'Tonga', leaves: 1 },
  { id: 'l16', iso: 'TT', name: 'Trinidad & Tobago', leaves: 1 },
  { id: 'l32', iso: 'CZ', name: 'Tschechien', leaves: 3 },
  { id: 'l6', iso: 'TN', name: 'Tunesien', leaves: 2 },
  { id: 'l217', iso: 'TC', name: 'Türk/Caicos Inseln', leaves: 0 },
  { id: 'l4', iso: 'TR', name: 'Türkei', leaves: 3 },
  { id: 'l25', iso: 'HU', name: 'Ungarn', leaves: 3 },
  { id: 'l193', iso: 'US', name: 'USA', leaves: 1 },
  { id: 'l23', iso: 'VE', name: 'Venezuela', leaves: 0 },
  { id: 'l35', iso: 'AE', name: 'VAE', leaves: 1 },
  { id: 'l196', iso: 'VN', name: 'Vietnam', leaves: 0 },
  { id: 'l231', iso: 'VI', name: 'Virgin Islands (US)', leaves: 1 },
  { id: 'l15', iso: 'CY', name: 'Zypern', leaves: 3 },
];
