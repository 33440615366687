import React from 'react';
import PropTypes from 'prop-types';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { googleMapsKey } from '../../../config';

const height = 600;
const zoom = 16;
const mapType = 'SATELLITE';

const MapContainer = ({
  name, location, google, lat, lng,
}) => (
  <div className="popup_content" style={{ marginTop: -10 }}>
    <h1 className="nt_mb">{name}</h1>
    <p><span>{location}</span></p>
    <div className="popup_body">
      <Map
        className="map_block"
        containerStyle={{ position: 'relative', height }}
        initialCenter={{ lat, lng }}
        mapType={mapType}
        google={google}
        zoom={zoom}
        fullscreenControl={false}
        scrollwheel
      >
        <Marker
          icon={{
            url: '/images/map_marker.svg',
            scaledSize: new google.maps.Size(48, 64),
          }}
          position={{ lat, lng }}
          name={location}
          title={location}
        />
      </Map>
    </div>
  </div>
);

MapContainer.propTypes = {
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  google: PropTypes.objectOf(PropTypes.any),
  lat: PropTypes.string.isRequired,
  lng: PropTypes.string.isRequired,
};

export default GoogleApiWrapper({ apiKey: googleMapsKey })(MapContainer);
