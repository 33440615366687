export const DEPARTURES = {
  TOGGLE_CHECKBOX: 'DEPARTURES_TOGGLE_CHECKBOX',
  SELECT_CHECKBOX: 'DEPARTURES_SELECT_CHECKBOX',
  UNSELECT_CHECKBOX: 'DEPARTURES_UNSELECT_CHECKBOX',
  UNSELECT_ALL: 'DEPARTURES_UNSELECT_ALL',
  SUBMIT: 'DEPARTURES_SUBMIT',
};

export const toggleCheckbox = id => ({ type: DEPARTURES.TOGGLE_CHECKBOX, id });
export const selectCheckbox = id => ({ type: DEPARTURES.SELECT_CHECKBOX, id });
export const unselectCheckbox = id => ({ type: DEPARTURES.UNSELECT_CHECKBOX, id });
export const unselectAll = () => ({ type: DEPARTURES.UNSELECT_ALL });

export const submit = () => ({ type: DEPARTURES.SUBMIT });
