import React from 'react';
import PropTypes from 'prop-types';
/* https://github.com/xiaolin/react-image-gallery  */
import ImageGallery from 'react-image-gallery';

const ImageSlider = ({ images }) => (
  <ImageGallery
    items={images}
    lazyLoad
    showFullscreenButton={false}
    showPlayButton={false}
    //showIndex
    slideInterval={2000}
    additionalClass="app-image-gallery"
  />
);

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
};

export default ImageSlider;
