import { DURATION } from '../../actions/duration';
import { CALENDAR } from '../../actions/calendar';
import { RESTORE_SELECTION } from '../../actions/restoreSelection';
import { getDuration } from '../../utils/APIMapper';

const defaultSelect = 1;

// get array of selected duration from previous session
const storageKey = 'duration';
const storage = localStorage.getItem(storageKey);
// fromDate,toDate,duration|0,minDuration|0,maxDuration|range,strictDate[fromDate|toDate]
const defaultState = {
  list: [ // range means we need to calculate range from calendar
    // { id: 1, name: 'beliebig' },
    {
      id: 1,
      name: 'wie ausgewählt',
      getExact: range => range,
    }, /* {
      id: 3,
      name: 'wie ausgewählt +/- 3 Tage',
      getExact: range => range,
      getMin: range => range - 3,
      getMax: range => range + 3,
    }, */
    { id: 2, name: '1 Woche', getExact: () => 7 },
    { id: 3, name: '2 Wochen', getExact: () => 14 },
    { id: 4, name: '3 Wochen', getExact: () => 21 },
    { id: 5, name: '4 Wochen', getExact: () => 28 },
    { id: 6, name: '3 Tage', getExact: () => 3 },
    { id: 7, name: '4 Tage', getExact: () => 4 },
    { id: 8, name: '5 Tage', getExact: () => 5 },
    {
      id: 9,
      name: '3-5 Tage',
      getExact: () => 4,
      getMin: () => 3,
      getMax: () => 5,
    }, {
      id: 10,
      name: '5-8 Tage',
      getExact: () => 6,
      getMin: () => 5,
      getMax: () => 8,
    }, {
      id: 11,
      name: '9-12 Tage',
      getExact: () => 10,
      getMin: () => 9,
      getMax: () => 12,
    }, {
      id: 12,
      name: '13-15 Tage',
      getExact: () => 14,
      getMin: () => 13,
      getMax: () => 15,
    }, {
      id: 13,
      name: '16-22 Tage',
      getExact: () => 17,
      getMin: () => 16,
      getMax: () => 21,
    },
  ],
  selected: { [defaultSelect]: true },
  isExpanded: false,
};
if (storage) {
  const [id] = JSON.parse(storage);
  defaultState.selected = { [id]: true };
}

export default (state = defaultState, { type, ...action }) => {
  let selected;
  let traveltime;
  let newSelected;

  switch (type) {
    case DURATION.SELECT:
      return { ...state, selected: { [action.id]: true } };

    case RESTORE_SELECTION.LOAD_DONE:
      traveltime = getDuration(action.params);
      if (!traveltime) {
        return state;
      }
      newSelected = state.list.find(({ getExact }) => getExact() === traveltime);
      if (!newSelected) {
        return state;
      }
      localStorage.setItem(storageKey, JSON.stringify([newSelected.id]));
      return { ...state, selected: { [newSelected.id]: true } };

    case DURATION.TOGGLE:
      return { ...state, isExpanded: !state.isExpanded };

    case CALENDAR.TOGGLE_CHECKBOX_DONE: // set to default when calendar less than duration
      selected = +Object.keys(state.selected)[0];
      if (action.to && action.from && selected !== 1) {
        const timeDiff = Math.abs(action.to.getTime() - action.from.getTime());
        const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24)) + 1;
        if (diffDays < state.list.filter(({ id }) => id === selected)[0].getExact()) {
          return { ...state, selected: { [defaultSelect]: true }, isExpanded: true };
        }
      }
      return state;

    case DURATION.SUBMIT:
      localStorage.setItem(
        storageKey,
        JSON.stringify(Object.keys(state.selected).map(key => +key)),
      );
      return state;

    default:
      return state;
  }
};
