import {
  select, fork, takeLatest, put,
} from 'redux-saga/effects';
import { WISHLIST } from '../actions/wishlist';


function* add({ giata }) {
  yield put({
    type: WISHLIST.ADD_DONE,
    item: yield select(({ hotel, result }) => ({
      giata,
      selectioncode: result.selectioncode,
      result: result.list.find(item => item.giata === giata),
      hotel: hotel[giata],
    })),
  });
}

export default function* () {
  yield [
    fork(function* () { yield takeLatest(WISHLIST.ADD, add); }),
  ];
}
