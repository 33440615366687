import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Empty from './Empty';
import Content from './Content';

const mapStateToProps = ({ wishlist: { list } }) => ({
  isEmpty: !list.length,
});

const Wishlist = ({ isEmpty, isShowed, toggle }) => (
  isShowed
    ? (
      <div className="popup">
        <div className="content" style={{ marginTop: 100 }}>
          <div className={`wishlist${isEmpty ? ' wishlist_empty' : ''}`}>
            <button
              type="button"
              className="close_block_btn"
              style={{ top: 15, right: 30, zIndex: 1000 }}
              onClick={toggle}
            />
            {isEmpty ? <Empty /> : <Content />}
          </div>
        </div>
      </div>
    )
    : null
);

Wishlist.propTypes = {
  isEmpty: PropTypes.bool.isRequired,
  isShowed: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Wishlist);
