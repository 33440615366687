import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleSex, toggleInfo } from '../../../actions/person';

const mapStateToProps = state => ({
  sex: state.person.sex,
  selectedSex: state.person.selected.sex,
  info: state.person.info,
  selectedInfo: state.person.selected.info,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  onChangeSex: toggleSex,
  onChangeInfo: toggleInfo,
}, dispatch);

const Sex = ({
  sex, selectedSex, info, selectedInfo, onChangeSex, onChangeInfo,
}) => (
  <div className="sex_block">
    <span>Ich bin ein/e</span>
    <div className="clear5" />
    {Object.keys(sex).map(key => (
      <div key={key} className={`choose_sex ${sex[key].toLowerCase()}`}>
        <div className="radiobutton">
          <input
            id={`choose_${sex[key]}`}
            name="sex"
            type="radio"
            checked={!!selectedSex[key]}
            onChange={() => { onChangeSex(key); }}
          />
          <label htmlFor={`choose_${sex[key]}`} className="radio_view" />
        </div>
      </div>
    ))}
    <div className="choose_sex">
      <ul>{Object.keys(info).map(key => (
        <li key={key}>
          <div className="checkbox">
            <input
              id={`partner_${key}`}
              type="checkbox"
              checked={!!selectedInfo[key]}
              onChange={() => { onChangeInfo(key); }}
            />
            <label htmlFor={`partner_${key}`} className="checkbox_view" />
            <label htmlFor={`partner_${key}`}>{info[key]}</label>
          </div>
        </li>))}
      </ul>
    </div>
  </div>
);

Sex.propTypes = {
  sex: PropTypes.objectOf(PropTypes.string),
  selectedSex: PropTypes.objectOf(PropTypes.bool),
  info: PropTypes.objectOf(PropTypes.string),
  selectedInfo: PropTypes.objectOf(PropTypes.bool),
  onChangeSex: PropTypes.func.isRequired,
  onChangeInfo: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sex);
