import { WISHLIST } from '../actions/wishlist';
import Storage from '../utils/Storage';

const storage = new Storage('wishlist');
const version = 1;
const storageData = storage.get();

const defaultState = {
  list: [],
};

if (storageData) {
  if (storageData.version === version) {
    defaultState.list = storageData.list;
  } else {
    storage.set({ version, list: defaultState.list });
  }
}

export default (state = defaultState, { type, ...action }) => {
  let list;

  switch (type) {
    case WISHLIST.ADD_DONE:
      list = state.list.concat(action.item);
      storage.set({ version, list });
      return { ...state, list };

    case WISHLIST.REMOVE:
      list = state.list.filter(({ giata, selectioncode }) => (
        action.giata !== giata || action.selectioncode !== selectioncode
      ));
      storage.set({ version, list });
      return { ...state, list };

    default:
      return state;
  }
};
