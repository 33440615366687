import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { add, remove } from '../../../actions/wishlist';
import { show } from '../../../actions/popup';

const mapStateToProps = ({ result, wishlist }, { giata }) => ({
  selectioncode: result.selectioncode,
  isWished: !!wishlist.list.find(item => (
    item.giata === giata && item.selectioncode === result.selectioncode
  )),
});
const mapDispatchToProps = dispatch => bindActionCreators({
  addItem: add,
  removeItem: remove,
  showPopup: show,
}, dispatch);

const Header = ({
  name, location, stars, giata, selectioncode, isWished, addItem, removeItem, greenRating, showPopup,
}) => (
  <header>
    <Link to={{ pathname: '/reisebuchung', giata }}>
      <p className="title">
        {name}
        <span className="hotel_stars">{stars.map((item, i) => (
          <FontAwesomeIcon key={i} icon={item ? faStar : faStarHalf} size="xs" />))}
        </span>
      </p>
      <div className="clear" />
      <p className="second_title">{location}</p>
    </Link>
    {greenRating !== undefined && (
      <button
        type="button"
        className={`results_shamrock results_shamrock_${greenRating}`}
        onClick={() => showPopup('climatehotel')}
      />
    )}
    <button
      type="button"
      className={`hotel_wish${isWished ? ' active' : ''}`}
      onClick={() => (isWished ? removeItem(giata, selectioncode) : addItem(giata))}
    />
    <div className="clear" />
  </header>
);

Header.propTypes = {
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  stars: PropTypes.arrayOf(PropTypes.bool),
  giata: PropTypes.string.isRequired,
  selectioncode: PropTypes.string.isRequired,
  isWished: PropTypes.bool.isRequired,
  addItem: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
