import React from 'react';
import { Link } from 'react-router-dom';
import BottomPopupTemplate from '../_BottomPopupTemplate';

export default () => (
  <BottomPopupTemplate delay={8} cbStyle={{ top: 'auto', bottom: 12, zIndex: 1 }}>
    <div className="contest_small">
      <div className="ribbon">
        <div className="text1">
          <div>Bis</div>
          15.Februar 2020
        </div>
        <div className="text2">
          <div>Eine von je 66 Familien*</div>
          reist auf <span>UNSERE KOSTEN!</span>
        </div>
        <div className="text3">
          *Detaillierte Gewinnbedingungen <Link to="/datenschutz" style={{ textDecoration: 'underline' }}>hier</Link>.
        </div>
      </div>
      <img src="/images/intro_yellow_ribbon_small.svg" alt="Ribbon" />
    </div>
    <div style={{ display: 'flex' }}>
      <img style={{ width: 60 }} src="/images/kontakt_160_220.jpg" />
    </div>
    <div style={{ marginBottom: 10, color: '#e30613' }}>Jetzt :</div>
  </BottomPopupTemplate>
);
