import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from '../../actions/popup';

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  showPopup: show,
}, dispatch);

const Country = ({
  id, name, leaves, isSelected, onChange, showPopup,
}) => (
  <label className="country_block" htmlFor={`country_${id}`}>
    <input id={`country_${id}`} type="checkbox" checked={isSelected} onChange={onChange} />
    <label htmlFor={`country_${id}`} className="checkbox_view" />
    <label htmlFor={`country_${id}`}>
      <span className="country_name">{name}</span>
    </label>
    <button
      type="button"
      className={`country_shamrocks country_shamrocks_${leaves}`}
      onClick={() => showPopup('climatemap')}
    />
    <img src={`/images/countries/${id}_264x212.jpg`} alt={name} />
  </label>
);

Country.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  leaves: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Country);
