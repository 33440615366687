export const PERSON = {
  SELECT_COUNTERS: 'PERSON_SELECT_COUNTERS',
  SELECT_SEX: 'PERSON_SELECT_SEX',
  SELECT_INFO: 'PERSON_SELECT_INFO',
  SUBMIT: 'PERSON_SUBMIT',
};

export const toggleCounters = (id, value) => ({ type: PERSON.SELECT_COUNTERS, id, value });
export const toggleSex = id => ({ type: PERSON.SELECT_SEX, id });
export const toggleInfo = id => ({ type: PERSON.SELECT_INFO, id });

export const submit = () => ({ type: PERSON.SUBMIT });
