import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { getUrlParams } from '../utils/helper';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  
  div, span {
    position: absolute;
    background-color: #000;
  }
  
  div {
    width: 100%;
    height: 1px;
  }
  
  span {
    width: 1px;
    height: 100%;
  }

  @media(max-width: 640px) {
    display: none;
  }
`;

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const Debug = ({ location: { search } }) => {
  const params = getUrlParams(search);
  const units = params.units || '%';
  const cells = [];
  const step = params.step ? +params.step : 10;
  const max = params.max ? +params.max : 100;
  for (let i = step; i < max; i += step)
    cells.push(i);

  return (
    <Wrapper id="debug_block">
      {cells.map(n => <div style={{ top: `${n}${units}` }} />)}
      {cells.map(n => <span style={{ left: `${n}${units}` }} />)}
    </Wrapper>
  );
};

Debug.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Debug));
