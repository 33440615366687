import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from '../actions/popup';

const shouldShow = () => window.innerWidth > 1240;

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  showPopup: show,
}, dispatch);

const AtmosfairBar = ({ showPopup }) => {
  const [isShowed, setIsShowed] = useState(shouldShow());
  const onResize = () => setIsShowed(shouldShow());

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div id="atmosfer_link" className={`atmosfer_link${isShowed ? ' show' : ''}`}>
      <button className="atmosfer_link_body" type="button" onClick={() => showPopup('climateairline')}>
        <h6>Airlines</h6>
        <p>im Ranking</p>
        <img src="/images/atmosfair_logo.jpg" alt="Logo" />
        <div className="clear" />
      </button>
    </div>
  );
};

AtmosfairBar.propTypes = {
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AtmosfairBar);
