import React from 'react';
import { Link } from 'react-router-dom';

export default () => (
  <div className="popup_block_no_results">
    <h2 style={{ fontSize: 25 /* made one line */ }}>Zu deinem angegebenem Zeitraum und/oder Budget
      gibt es leider keine passenden Reiseangebote
    </h2>
    <div className="popup_body">
      <div className="half">
        <div><b>Vorschlag:</b> Du kannst jetzt deine Reisedaten selbst anpassen und
          dann eine neue Suche starten. Deine ausgewählten Interessen sind notiert.
        </div>
        <Link to="/reisedaten" className="btn"><b>Neue Suche</b></Link>
      </div>
      <div className="half">
        <div><b>Oder:</b> Wir finden gerne auch ganz persönlich eine passende Reise für dich.</div>
        <div className="contact">
          <img src="/images/kontakt_160_220.jpg" alt="Manager" />
          <div>Tel.:</div>
          <b>08847 6955994</b>
        </div>
      </div>
      <div className="clear" />
    </div>
  </div>
);
