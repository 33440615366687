import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import '../../assets/css/image-gallery.css';
import Item from './Item';
import PopupDetails from './ScorePopup';

const mapStateToProps = ({ wishlist }) => ({
  list: wishlist.list.map(({ result, hotel, selectioncode }) => (
    { ...result, ...hotel, selectioncode }
  )),
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

class Result extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isScorePopupShowed: false,
      popupDetails: { matchlist: [], mismatchlist: [] },
    };
  }

  render() {
    const { isScorePopupShowed, popupDetails } = this.state;
    const { list } = this.props;

    return (
      <React.Fragment>
        <div className="triple section_title_block" style={{ paddingTop: 0 }}>
          <div className="section_title" style={{ display: 'flex', alignItems: 'center' }}>
            <h1 style={{ width: 200, marginTop: -5 }}>Dein Merkzettel</h1>
            <i className="wishlist_icon" style={{ width: 89, height: 85 }} />
          </div>
        </div>
        <div
          className="results_block"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: 20,
          }}
        >{list.map((item, index) => (
          <React.Fragment key={`${item.giata}_${item.selectioncode}`}>
            <Item
              index={index}
              {...item}
              showScorePopup={(matchlist, mismatchlist) => this.setState({
                isScorePopupShowed: true,
                popupDetails: { matchlist, mismatchlist },
              })}
            />
          </React.Fragment>))}
          <div className="clear" />
        </div>
        <div className={`popup${isScorePopupShowed ? '' : ' hidden'}`}>
          <div className="popup_block">
            <button
              type="button"
              className="close_btn"
              onClick={() => this.setState({ isScorePopupShowed: false })}
            />
            <PopupDetails {...popupDetails} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Result.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
};

export default connect(mapStateToProps, mapDispatchToProps)(Result);
