import React from 'react';

export default () => (
  <p>
    Die folgenden Bedingungen regeln ausschließlich das Vertragsverhältnis zwischen dem Nutzer ('Nutzer') und der BLAUWEGE GMBH in
    Bezug auf die Vermittlung von touristischen Leistungen. Die Allgemeinen Geschäftsbedingungen der BLAUWEGE GMBH gelten auch dann,
    wenn die Nutzung, der Zugriff oder die Reiseanmeldung von außerhalb der Bundesrepublik Deutschland erfolgt, auch unabhängig von
    der Staatsangehörigkeit des Nutzers.
  </p>
);
