import React from 'react';

export default () => (
  <React.Fragment>
    <p>
      3.1 Der Auftrag eines Nutzers an BLAUWEGE GMBH, eine touristische Leistung bei einem Reiseveranstalter und/oder Leistungsträger zu
      buchen, kann fernmündlich, per Brief, per Telefax oder über den Online-Dienst www.holidayfind.de erfolgen. Mit dieser Anmeldung
      bietet der Nutzer BLAUWEGE GMBH den Abschluss eines Reise-/Leistungsvermittlungsvertrages verbindlich an. Durch die vorbehaltlose
      Annahme dieses Vermittlungsauftrages durch BLAUWEGE GMBH kommt zwischen dem Nutzer und der BLAUWEGE GMBH ein Vermittlungsvertrag
      zustande. Die Annahme des Vermittlungsauftrages durch BLAUWEGE GMBH kann per Brief, per E-Mail oder per Fax erfolgen. Die
      vertraglichen Vereinbarungen und die hier aufgeführten Allgemeinen Geschäftsbedingungen gelten für alle Leistungsnehmer.
    </p>
    <p>
      3.2 Es besteht kein Rechtsanspruch auf Annahme des Vermittlungsauftrages des Nutzers durch BLAUWEGE GMBH. Außerdem hat BLAUWEGE GMBH
      keinen Einfluss darauf, ob der jeweilige Reiseveranstalter und/oder Leistungsträger das Vertragsangebot des Nutzers annimmt. Die auf
      der Webseite von BLAUWEGE GMBH dargestellten 'Angebote' sind keine verbindlichen Vertragsangebote. Sonderwünsche werden von BLAUWEGE
      GMBH an den jeweiligen Reiseveranstalter und/oder Leistungsträger zwecks Prüfung weitergeleitet, der den Nutzer über eine eventuelle
      Annahme des Sonderwunsches informiert.
    </p>
    <p>
      3.3 Zur laufenden Verbesserung der Serviceleistungen von BLAUWEGE GMBH, insbesondere für aktuelle Reisehinweise zu der gebuchten
      Reise, zur Sicherstellung einer sicheren und zuverlässigen Zahlungsabwicklung, insbesondere zur Verifizierung der eingegebenen
      persönlichen Daten (Identitätsprüfung) sowie zum Schutz vor Kreditkartenmissbrauch (Credit Card Fraud Prevention) kann der Nutzer bis
      wenige Tage nach der Buchung von BLAUWEGE GMBH unter den angegebenen Kontaktdaten angerufen werden.
    </p>
    <p>
      3.4 BLAUWEGE GMBH verwendet die im Rahmen einer Buchung zur Verfügung gestellten Daten zur Abwicklung der gebuchten Leistungen.
      Darüber kann BLAUWEGE GMBH die Daten nutzen, um den Nutzer auf ihn zugeschnittene Produkte und Services zu informieren. Näheres zum
      Datenschutz ist den „Datenschutzhinweisen“ zu entnehmen.
    </p>
  </React.Fragment>
);
