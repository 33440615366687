import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleCheckbox } from '../../../actions/calendar';
import { getDuration } from '../../../selectors/result';
import AcceptButton from '../AcceptButton';
import ListItem from './ListItem';

const mapStateToProps = ({ calendar, duration }) => ({
  from: calendar.from,
  to: calendar.to,
  selectedDuration: getDuration({ duration }),
});
const mapDispatchToProps = dispatch => bindActionCreators({
  select: toggleCheckbox,
}, dispatch);

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  }

  render() {
    const { isExpanded } = this.state;
    const {
      from, to, select, selectedDuration,
    } = this.props;

    let showRedBorder = false;
    if (
      selectedDuration
      && (selectedDuration.getMin ? selectedDuration.getMin() : selectedDuration.getExact())
        > Math.round((to - from) / 86400000)
    ) {
      showRedBorder = true;
    }

    return (
      <div
        key={selectedDuration}
        className={`content_block ${isExpanded ? ' open' : ''} ${from && to ? '' : 'content_block_empty'} duble`}
        style={showRedBorder ? { boxShadow: '0 0 0 7px #ff0c38', zIndex: 1 } : {}}
      >
        <header onClick={this.toggle}>
          <p>
            <span>Urlaubs-Zeitraum</span>
          </p>
        </header>
        <div className="content_block_body">
          <button
            type="button"
            className={`empty_block${isExpanded ? ' hidden' : ''}`}
            onClick={this.toggle}
          >{from && to ? (
            <span>
              <span className="light">Anreise:</span> <span>{Moment(from).format('DD.MM.YYYY')}</span>&#8195;
              <span className="light">Abreise:</span> <span>{Moment(to).format('DD.MM.YYYY')}</span>
            </span>
          ) : <span>Bitte wählen</span>}
            <i className="icon_down" />
          </button>
          <div className={`open_content_block${isExpanded ? '' : ' hidden'}`}>
            <button
              type="button"
              className="close_content_block_btn close_calendar"
              onClick={this.toggle}
            ><i className="icon_down" />
            </button>
            <div className="open_content_block_body buttoned">
              <div>
                <div className="head">Zum Ändern des Reisebeginns 2 x auf 1. Reisetag klicken.</div>
                <div className="clear10" />
              </div>
              <ListItem from={from} to={to} select={select} />
            </div>
            <AcceptButton onAccept={this.toggle} />
          </div>
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  selectedDuration: PropTypes.objectOf(PropTypes.any),
  select: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
