import { CALENDAR } from '../../actions/calendar';
import { RESTORE_SELECTION } from '../../actions/restoreSelection';
import { getCalendar } from '../../utils/APIMapper';

const today = new Date();
today.setDate(today.getDate() - 1);

// get array of selected calendar from previous session
const storageKey = 'calendar';
const storage = localStorage.getItem(storageKey);

const defaultState = {
  from: undefined,
  to: undefined,
};
if (storage) {
  // restore selected
  const [from, to] = JSON.parse(storage);
  const fromDate = new Date(from);
  const toDate = new Date(to);
  if (
    Object.prototype.toString.call(fromDate) === '[object Date]' && !Number.isNaN(fromDate.getTime())
    && Object.prototype.toString.call(toDate) === '[object Date]' && !Number.isNaN(toDate.getTime())
    && fromDate > today
  ) {
    defaultState.from = fromDate;
    defaultState.to = toDate;
  }
}

export default (state = defaultState, { type, ...action }) => {
  const { from, to } = state;
  let newSelected;

  switch (type) {
    case CALENDAR.TOGGLE_CHECKBOX_DONE: // deny for 2- days, should be 3+
      return action.to && action.from && Math.abs(action.to - action.from) < 86400 * 1000 * 3
        ? { ...state, from: null, to: null }
        : { ...state, from: action.from, to: action.to };

    case RESTORE_SELECTION.LOAD_DONE:
      newSelected = getCalendar(action.params);
      if (!newSelected || newSelected.from < today) {
        return state;
      }
      localStorage.setItem(storageKey, JSON.stringify([newSelected.from, newSelected.to]));
      return { ...state, ...newSelected };

    case CALENDAR.SUBMIT:
      localStorage.setItem(storageKey, JSON.stringify([from, to]));
      return state;

    default:
      return state;
  }
};
