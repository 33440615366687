import React from "react";

import ChooseSearch from "./ChooseSearch";
import StartScreen from "./StartScreen";

import "./newStart.module.css";

export default function NewStart() {
  const [menu, setMenu] = React.useState("startScreen");

  return (
    <div className="newStart__container">
      {menu === "startScreen" && (
        <StartScreen setMenu={() => setMenu("chooseSearch")} />
      )}
      <div className="chooseCookie__familyImg">
        <img src="/images/family5-complete-shadow-dark.svg" />
      </div>
      {menu === "chooseSearch" && <ChooseSearch />}
      <div className="footerContainer">
        <h2>Das EINMALIGE bei holidayfind.de</h2>

        <div className="blocksContainer">
          <div className="transparentContainer">
            <div className="block">
              <div>
                <h3>Individuellen Urlaub genießen</h3>
                <span>holidayfind.de findet den Urlaub für dich.</span>
                <p>
                  Wie du träumen die allermeisten Menschen „für die schönste Zeit
                  des Jahres“ von einem Urlaub, der ihren Wünschen genau
                  entspricht. Da Urlaubs-Interessen jedoch sehr unterschiedlich
                  sein können, ist es gar nicht so einfach, eine passende
                  individuelle Reise zu finden.<br/><br/>Es geht dabei oft nicht um
                  besonders exotische Ansprüche, eher darum, dass einfach rundum
                  alles passt.
                </p>
              </div>

              <p>
                Um Individualität, die nicht mit hohen Kosten verbunden ist.
              </p>
            </div>

            <div className="block">
              <div>
                <h3>Passende Reisen finden</h3>
                <span>
                  Die individuelle Reisesuche von holidayfind.de macht es ganz
                  einfach.
                </span>
                <p>
                  Herkömmliche Online-Reiseportale listen bislang einfach alle
                  verfügbaren Reisen auf.<br/>Doch dann geht das Finden eines
                  passenden <br/> Hotels aber erst los: <br/> Wo kann ich Tauchen lernen?
                  Vegan essen? Wo können die Kinder abends Karaoke singen? Was ist
                  unter den gefühlt 1.000 Angeboten das Richtige z.B. für alle in
                  meiner Familie? Kann ich Schwerpunkte festlegen?
                </p>
              </div>

              <p>Unser Portal filtert hier sehr genau für dich.</p>
            </div>

            <div className="block">
              <div>
                <h3>Schnell Ergebnisse erhalten</h3>
                <span>
                  Mit wenigen Klicks zeigt holidayfind.de dir eine zutreffende
                  Auswahl.
                </span>
                <p>
                  Das Rheingold-Institut hat schon vor Jahren ermittelt, dass es
                  bei der klassischen Suche nach<br />z. B. einem Familienurlaub oft
                  bis zu neun Stunden braucht, bis ein einigermaßen
                  zufriedenstellendes Ergebnis zustande kommt.<br />Suchen,
                  vergleichen, überprüfen, nachrecherchieren … und am Ende sind
                  die Reise-Angebote dann oft schon ausgebucht oder sogar teurer
                  geworden.
                </p>
              </div>

              <p>Es geht auch anders!</p>
            </div>

            <div className="block">
              <div>
                <h3>Klimafreundlich reisen</h3>
                <span>Selbstverständlich. Wir haben verstanden:</span>
                <p>
                Wenn reisen, dann möglichst auch umweltfreundlich. Doch wie geht
                das bei einer Pauschalreise? Ist das Zielland umweltfreundlich?
                Hält sich der <span className="inline">CO<sub>2</sub></span>-Verbrauch in Grenzen? Hat die Fluggesellschaft
                moderne Airlines? Und die Hotels: Welche wirtschaften
                nachhaltig, sind z. B.<br />Bio-Hotels? Wo finde ich ganz einfach
                Orientierungshilfen rund um Klimaschutz und Urlaub, so dass ich
                angebotene Reisen besser einordnen kann?
                </p>
              </div>

              <p>Neu und einmalig - nur bei holidayfind.de</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
