import { COUNTRIES } from '../actions/countries';
import { RESTORE_SELECTION } from '../actions/restoreSelection';
import Storage from '../utils/Storage';
import { getCountries } from '../utils/APIMapper';
import countries from '../assets/data/countries';

const storage = new Storage('countries');
const version = 1;
const storageData = storage.get();

const getAsObject = array => array.reduce((res, key) => ({ ...res, [key]: true }), {});
const excludeId = (obj, id) => Object.keys(obj)
  .filter(key => key !== id)
  .reduce((res, key) => ({ ...res, [key]: true }), {});
const mapCountries = list => list.map(
  item => ({ ...item, ...countries.find(c => c.id === item.id) }),
).filter(c => c);

const defaultState = {
  listTop: [],
  list: [],
  selected: {},
  selectionid: '',
  isLoading: false,
  isOptionsChanged: false,
};

if (storageData) {
  if (storageData.version === version) {
    defaultState.selected = getAsObject(storageData.selected);
    defaultState.selectionid = storageData.selectionid;
  } else {
    storage.set({
      version,
      selected: Object.keys(defaultState.selected),
      selectionid: defaultState.selectionid,
    });
  }
}

export default (state = defaultState, { type, ...action }) => {
  let selected;
  let allSelected;

  switch (type) {
    case COUNTRIES.LIST:
      return { ...state, isOptionsChanged: false, isLoading: true };

    case COUNTRIES.SET_OPTIONS_CHANGED:
      return { ...state, isOptionsChanged: true };

    case COUNTRIES.LIST_DONE:
      allSelected = action.listTop
        .concat(action.list)
        .filter(({ Top100_Matches: match }) => match)
        .map(({ id }) => id);

      selected = allSelected.filter(id => !!state.selected[id]);
      if (!selected.length) {
        selected = allSelected;
      }
      storage.update({ selected, selectionid: action.selectionid });
      return {
        ...state,
        listTop: mapCountries(action.listTop),
        list: mapCountries(action.list),
        selected: getAsObject(selected),
        selectionid: action.selectionid,
        isLoading: false,
      };

    case COUNTRIES.LIST_FAILED:
      console.log(action.message || 'network error');
      return state;

    case RESTORE_SELECTION.LOAD_DONE:
      selected = getCountries(action.params);
      if (!selected) return state;
      storage.update({ selected, selectionid: action.params.selectionid });
      return {
        ...state,
        selected: getAsObject(selected),
      };

    case COUNTRIES.SELECT_ALL:
      selected = state.listTop.concat(state.list).map(({ id }) => id);
      storage.update({ selected });
      return {
        ...state,
        selected: getAsObject(selected),
      };

    case COUNTRIES.UNSELECT_ALL:
      storage.update({ selected: [] });
      return {
        ...state,
        selected: {},
      };

    case COUNTRIES.TOGGLE_CHECKBOX:
      selected = state.selected[action.id]
        ? { ...excludeId(state.selected, action.id) }
        : { ...state.selected, [action.id]: true };
      storage.update({ selected: Object.keys(selected) });
      return {
        ...state,
        selected,
      };

    default:
      return state;
  }
};
