import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { unselectAll } from '../../actions/countries';

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  reset: unselectAll,
}, dispatch);

const Question = ({ reset }) => (
  <div className="popup">
    <div className="popup_block_inactivity">
      <div>
        <h2>Du hast deine Interessen/Reisedaten modifiziert. <span>Das kann Einfluss auf deine
          Länderauswahl haben. Entscheide du bitte deshalb:</span>
        </h2>
        <Link to="/reiselaender" className="btn fl"><b>Länderauswahl beibehalten</b></Link>
        <Link to="/reiselaender" className="btn fr" onClick={reset}><b>Länderauswahl NEU</b></Link>
        <div className="clear" />
      </div>
    </div>
  </div>
);

Question.propTypes = {
  reset: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Question);
