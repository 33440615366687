import React from 'react';

export default () => (
  <React.Fragment>
    <p>
      2.1 BLAUWEGE GMBH tritt im Rahmen ihrer Tätigkeiten ausschließlich als Vermittler von touristischen Leistungen (Pauschalreisen und
      Versicherungen) auf. Die vertragliche Pflicht von BLAUWEGE GMBH ist die ordnungsgemäße Vermittlung der gebuchten Beförderungsleistung
      bzw. die Vermittlung der einzelnen touristischen Leistung. Das Erbringen der gebuchten Leistung als solche ist nicht Bestandteil der
      Pflichten von BLAUWEGE GMBH. BLAUWEGE GMBH ist nicht zur Prüfung der Angaben der Reiseveranstalter oder Leistungsträger verpflichtet.
    </p>
    <p>
      2.2 Als Leistungsträger bzw. touristische Leistungsträger, werden alle Unternehmen bezeichnet, deren Produkte oder Leistungen an den
      Kunden verkauft werden bzw. von einem Reisevermittler (BLAUWEGE GMBH) an den Kunden vermittelt werden. Beispiele für touristische
      Leistungsträger, sind z.B. Beherbergungs- und Verpflegungsbetriebe (Hotels und Restaurants), Transportbetriebe (Bahn, Bus, Flug),
      Fahrzeugvermietungen, Reiserücktrittsversicherungen sowie die Betreiber von Reservierungs- und Informationssystemen.
    </p>
    <p>
      2.3 Bei einer Buchung durch den Nutzer entsteht ein Vertragsverhältnis mit dem Reiseveranstalter und/oder den einzelnen
      Leistungsträgern, das nicht in den Allgemeinen Geschäftsbedingungen von BLAUWEGE GMBH geregelt ist. BLAUWEGE GMBH weist alle
      Teilnehmer und Nutzer ausdrücklich darauf hin, dass der Reisevertrag nicht mit BLAUWEGE GMBH bzw. der Marke holidayfind, sondern
      stets mit dem jeweils angegebenen Reiseveranstalter und/oder Leistungsträger unter Berücksichtigung der Allgemeinen
      Geschäftsbedingungen des jeweiligen Reiseveranstalters und/oder Leistungsträgers zustande kommt. Deren Allgemeine
      Geschäftsbedingungen werden vor Reisebuchung angezeigt und werden bei einer Buchung Bestandteil des Vertrags zwischen dem Nutzer und
      dem Reiseveranstalter und/oder Leistungsträger. Daher muss der Nutzer auch bestätigen, dass er die AGBs gelesen, verstanden und
      akzeptiert hat. Im Rahmen der angebotenen Leistungen ist der Nutzer verpflichtet, sich über die Konsequenzen des Abschlusses eines
      Reisevertrages bei dem jeweiligen Reiseveranstalter oder Leistungsträger zu informieren.
    </p>
  </React.Fragment>
);
