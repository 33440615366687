import React from 'react';
import PropTypes from 'prop-types';
// http://react-day-picker.js.org/examples/basic
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import '../../../assets/css/calendar.css';

const months = [
  'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
  'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember',
];
const weekdaysShort = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];

const ListItem = ({ from, to, select }) => (
  <DayPicker
    className="Selectable"
    initialMonth={from}
    months={months}
    weekdaysShort={weekdaysShort}
    fromMonth={new Date()}
    numberOfMonths={2}
    selectedDays={[from, { from, to }]}
    disabledDays={{ before: new Date() }}
    modifiers={{ start: from, end: to }}
    onDayClick={select}
  />
);

ListItem.propTypes = {
  from: PropTypes.instanceOf(Date),
  to: PropTypes.instanceOf(Date),
  select: PropTypes.func.isRequired,
};

export default ListItem;
