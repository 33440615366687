import React from 'react';
import { isIOS, isIOS13 } from 'react-device-detect';

export default ({ isShown, onClose }) => (isShown ? (
  <div className="popup">
    <div className="popup_block">
      <button type="button" className="close_btn" onClick={onClose} />
      <div className="popup_content" style={{ overflow: 'unset' }}>
        <div className="popup_body tl">
          <div className="climate_airline_left">
            <h1 className="logo_shamrock"><span>Airline-Ranking nach dem Kriterium „Möglichst geringe CO2-Belastung“</span></h1>
            <div className="clear" />
            <div className="climate_airline_text_block">
              <p className="f16">Bei Flugreisen fallen CO2-Emissionen an. Durch Auswahl einer Fluggesellschaft mit möglichst hoher Effizienzklasse kann jedoch jeder einzelne Flugpassagier seinen Beitrag zum Klimaschutz leisten. Maßgebend ist die Wirtschaftlichkeit, also Flugzeugtechnik, Kraftstoffverbrauch, Gewicht und Auslastung der Maschinen.</p>
              <p className="f16">Unser Partner <b>atmosfair</b> kooperiert mit der Reisebranche weltweit, um den Klimaschutz zu stärken und CO2-Kompensationen zu ermöglichen. Mit atmosfair kannst du zum Beispiel hier >>>>> checken, welche der weltweit 125 größten Fluggesellschaften am klimaeffizientesten unterwegs sind. Laufende Aktualisierung/ Erweiterung. Die größten Billigflieger werden eigens erfasst.</p>
              <p className="f16"><span className="logo_inline" /><b> unterstützt atmosfair und andere NGOs jährlich mit 10% vom Überschuss.</b><br />So fördern wir zertifizierte Klimaschutzprojekte rund um den Globus. Außerdem unterstützen wir die NGOs Germanwatch und myclimate.</p>
            </div>
            <div className="climate_airline_table_block">
              <div className="climate_airline_table_top">
                <div className="climate_airline_table_block_header">
                  <span>Gesamtbewertung</span>
                </div>
                <div className="climate_airline_table_block_header">
                  <span>Distanzbewertung</span>
                </div>
                <div className="climate_airline_table_header">
                  <table border="1">
                    <thead>
                    <tr>
                      <th colSpan="5">&nbsp; </th>
                      <th className="white_cell" />
                      <th colSpan="3">&lt; 800 km"</th>
                      <th colSpan="3">800-3800 km</th>
                      <th colSpan="3">&gt; 3800 km</th>
                    </tr>
                    <tr>
                      <th style={{ width: 23 }}>Platz</th>
                      <th>Airline</th>
                      <th style={{ width: 58 }}>Land</th>
                      <th style={{ width: 25 }}>EP*</th>
                      <th style={{ width: 20 }}>EK*</th>
                      <th className="white_cell" />
                      <th style={{ width: 25 }}>EP*</th>
                      <th style={{ width: 20 }}>EK*</th>
                      <th style={{ width: 33 }}>Platz</th>
                      <th style={{ width: 25 }}>EP*</th>
                      <th style={{ width: 20 }}>EK*</th>
                      <th style={{ width: 33 }}>Platz</th>
                      <th style={{ width: 25 }}>EP*</th>
                      <th style={{ width: 20 }}>EK*</th>
                      <th style={{ width: 33 }}>Platz</th>
                    </tr>
                    </thead>
                  </table>
                </div>
              </div>
              <div className="climate_airline_table_block_body" {...(isIOS || isIOS13 ? { style: { paddingRight: 15 } } : {})}>
                <table border="1">
                  <tbody>
                  <tr>
                    <td style={{ width: 23 }}>1</td>
                    <td>TUI Airways</td>
                    <td style={{ width: 58 }}>UK</td>
                    <td style={{ width: 25 }}>79.5</td>
                    <td style={{ width: 20 }} className="ek_b">b</td>
                    <td className="white_cell" />
                    <td style={{ width: 25 }}>69.1</td>
                    <td style={{ width: 20 }} className="ek_c">c</td>
                    <td style={{ width: 33 }}>17</td>
                    <td style={{ width: 25 }}>79.4</td>
                    <td style={{ width: 20 }} className="ek_b">b</td>
                    <td style={{ width: 33 }}>2</td>
                    <td style={{ width: 25 }}>79.2</td>
                    <td style={{ width: 20 }} className="ek_b">b</td>
                    <td style={{ width: 33 }}>1</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>LATAM Airlines Brasilien </td>
                    <td>Brasilien</td>
                    <td>78.8</td>
                    <td className="ek_b">b</td>
                    <td className="white_cell" />
                    <td>76.3</td>
                    <td className="ek_c">c</td>
                    <td>3</td>
                    <td>82.2</td>
                    <td className="ek_b">b</td>
                    <td>1</td>
                    <td>66</td>
                    <td className="ek_c">c</td>
                    <td>18</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>China West Air</td>
                    <td>China</td>
                    <td>77.8</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>76.7</td>
                    <td className="ek_c">c</td>
                    <td>2</td>
                    <td>77.9</td>
                    <td className="ek_b">b</td>
                    <td>4</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>TUIfly</td>
                    <td>Deutschland</td>
                    <td>77.6</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>72.9</td>
                    <td className="ek_c">c</td>
                    <td>2</td>
                    <td>77.7</td>
                    <td className="ek_c">c</td>
                    <td>5</td>
                    <td>76.3</td>
                    <td className="ek_c">c</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Transavia.com France</td>
                    <td>Frankreich</td>
                    <td>76.3</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>77.8</td>
                    <td className="ek_c">c</td>
                    <td>1</td>
                    <td>76.3</td>
                    <td className="ek_c">c</td>
                    <td>7</td>
                    <td>73.8</td>
                    <td className="ek_c">c</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>SunExpress</td>
                    <td>Türkei</td>
                    <td>74.9</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>39.8</td>
                    <td className="ek_e">e</td>
                    <td>101</td>
                    <td>74.9</td>
                    <td className="ek_c">c</td>
                    <td>9</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Thomas Cook Airlines</td>
                    <td>UK</td>
                    <td>74.7</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>54.5</td>
                    <td className="ek_d">d</td>
                    <td>64</td>
                    <td>78.6</td>
                    <td className="ek_b">b</td>
                    <td>3</td>
                    <td>68.8</td>
                    <td className="ek_c">c</td>
                    <td>20</td>
                  </tr>
                  <tr>
                    <td>8</td>
                    <td>Air Europa Express</td>
                    <td>Spanien</td>
                    <td>73.4</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>73.4</td>
                    <td className="ek_c">c</td>
                    <td>9</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>9</td>
                    <td>Condor Flugdienst </td>
                    <td>Deutschland</td>
                    <td>71.8</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>42.9</td>
                    <td className="ek_e">e</td>
                    <td>92</td>
                    <td>77.6</td>
                    <td className="ek_c">c</td>
                    <td>6</td>
                    <td>65.7</td>
                    <td className="ek_c">c</td>
                    <td>20</td>
                  </tr>
                  <tr>
                    <td>10</td>
                    <td>Juneyao Airlines</td>
                    <td>China</td>
                    <td>70.9</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>69.4</td>
                    <td className="ek_c">c</td>
                    <td>15</td>
                    <td>71.0</td>
                    <td className="ek_c">c</td>
                    <td>15</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>11</td>
                    <td>Jet2.com</td>
                    <td>UK</td>
                    <td>70.8</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>68.4</td>
                    <td className="ek_c">c</td>
                    <td>21</td>
                    <td>70.8</td>
                    <td className="ek_c">c</td>
                    <td>16</td>
                    <td>73.8</td>
                    <td className="ek_c">c</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>12</td>
                    <td>Air Europa</td>
                    <td>Spanien</td>
                    <td>70.7</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>70.4</td>
                    <td className="ek_c">c</td>
                    <td>13</td>
                    <td>74.1</td>
                    <td className="ek_c">c</td>
                    <td>10</td>
                    <td>68.3</td>
                    <td className="ek_c">c</td>
                    <td>12</td>
                  </tr>
                  <tr>
                    <td>13</td>
                    <td>Air New Zealand</td>
                    <td>Neuseeland</td>
                    <td>70.5</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>75.4</td>
                    <td className="ek_c">c</td>
                    <td>5</td>
                    <td>75.1</td>
                    <td className="ek_c">c</td>
                    <td>8</td>
                    <td>66.5</td>
                    <td className="ek_c">c</td>
                    <td>17</td>
                  </tr>
                  <tr>
                    <td>14</td>
                    <td>Vietnam Airlines</td>
                    <td>Vietnam</td>
                    <td>70.4</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>63.9</td>
                    <td className="ek_d">d</td>
                    <td>40</td>
                    <td>69.3</td>
                    <td className="ek_c">c</td>
                    <td>22</td>
                    <td>76.9</td>
                    <td className="ek_c">c</td>
                    <td>2</td>
                  </tr>
                  <tr>
                    <td>15</td>
                    <td>Beijing Capital Airlines</td>
                    <td>China</td>
                    <td>69.8</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>68.7</td>
                    <td className="ek_c">c</td>
                    <td>18</td>
                    <td>70.0</td>
                    <td className="ek_c">c</td>
                    <td>18</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>16</td>
                    <td>Siberia Airlines</td>
                    <td>Russland</td>
                    <td>69.2</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>66.2</td>
                    <td className="ek_c">c</td>
                    <td>33</td>
                    <td>69.7</td>
                    <td className="ek_c">c</td>
                    <td>20</td>
                    <td>67.4</td>
                    <td className="ek_c">c</td>
                    <td>14</td>
                  </tr>
                  <tr>
                    <td>17</td>
                    <td>KLM</td>
                    <td>Niederlande</td>
                    <td>68.9</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>64.8</td>
                    <td className="ek_d">d</td>
                    <td>37</td>
                    <td>71.6</td>
                    <td className="ek_c">c</td>
                    <td>13</td>
                    <td>68.5</td>
                    <td className="ek_c">c</td>
                    <td>11</td>
                  </tr>
                  <tr>
                    <td>18</td>
                    <td>Virgin Australia Int.</td>
                    <td>Australien</td>
                    <td>68.5</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>71.6</td>
                    <td className="ek_c">c</td>
                    <td>11</td>
                    <td>69.8</td>
                    <td className="ek_c">c</td>
                    <td>19</td>
                    <td>61.4</td>
                    <td className="ek_d">d</td>
                    <td>31</td>
                  </tr>
                  <tr>
                    <td>19</td>
                    <td>Air New Zealand Link</td>
                    <td>Neuseeland</td>
                    <td>68.3</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>68.5</td>
                    <td className="ek_c">c</td>
                    <td>19</td>
                    <td>62.9</td>
                    <td className="ek_d">d</td>
                    <td>52</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>20</td>
                    <td>Air Caraibes</td>
                    <td>Guadeloupe</td>
                    <td>68.2</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>74.0</td>
                    <td className="ek_d">c</td>
                    <td>7</td>
                    <td>70.3</td>
                    <td className="ek_d">c</td>
                    <td>17</td>
                    <td>67.6</td>
                    <td className="ek_d">d</td>
                    <td>13</td>
                  </tr>
                  <tr>
                    <td>21</td>
                    <td>Avianca</td>
                    <td>Kolumbien</td>
                    <td>67.9</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>67.9</td>
                    <td className="ek_c">c</td>
                    <td>22</td>
                    <td>68.2</td>
                    <td className="ek_c">c</td>
                    <td>26</td>
                    <td>67.4</td>
                    <td className="ek_c">c</td>
                    <td>14</td>
                  </tr>
                  <tr>
                    <td>22</td>
                    <td>Alaska Airlines</td>
                    <td>USA</td>
                    <td>67.4</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>20.9</td>
                    <td className="ek_f">f</td>
                    <td>119</td>
                    <td>67.3</td>
                    <td className="ek_c">c</td>
                    <td>31</td>
                    <td>70.2</td>
                    <td className="ek_c">c</td>
                    <td>7</td>
                  </tr>
                  <tr>
                    <td>22</td>
                    <td>Shandong Airlines</td>
                    <td>China</td>
                    <td>67.4</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>68.5</td>
                    <td className="ek_c">c</td>
                    <td>19</td>
                    <td>67.3</td>
                    <td className="ek_c">c</td>
                    <td>31</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>22</td>
                    <td>Sichuan Airlines</td>
                    <td>China</td>
                    <td>67.4</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>64.5</td>
                    <td className="ek_d">d</td>
                    <td>38</td>
                    <td>68.5</td>
                    <td className="ek_c">c</td>
                    <td>25</td>
                    <td>48.6</td>
                    <td className="ek_e">e</td>
                    <td>65</td>
                  </tr>
                  <tr>
                    <td>22</td>
                    <td>Thai Airways Int.</td>
                    <td>Thailand</td>
                    <td>67.4</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>70.8</td>
                    <td className="ek_c">c</td>
                    <td>12</td>
                    <td>69.6</td>
                    <td className="ek_c">c</td>
                    <td>21</td>
                    <td>65.8</td>
                    <td className="ek_c">c</td>
                    <td>19</td>
                  </tr>
                  <tr>
                    <td>26</td>
                    <td>Air Transat</td>
                    <td>Kanada</td>
                    <td>67.1</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>67.0</td>
                    <td className="ek_c">c</td>
                    <td>26</td>
                    <td>71.3</td>
                    <td className="ek_c">c</td>
                    <td>14</td>
                    <td>64.5</td>
                    <td className="ek_d">d</td>
                    <td>22</td>
                  </tr>
                  <tr>
                    <td>27</td>
                    <td>UTair Aviation</td>
                    <td>Russland</td>
                    <td>66.9</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>70.1</td>
                    <td className="ek_c">c</td>
                    <td>14</td>
                    <td>66.3</td>
                    <td className="ek_c">c</td>
                    <td>37</td>
                    <td>68.6</td>
                    <td className="ek_c">c</td>
                    <td>10</td>
                  </tr>
                  <tr>
                    <td>28</td>
                    <td>Air India Express</td>
                    <td>India</td>
                    <td>66.8</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>38.9</td>
                    <td className="ek_e">e</td>
                    <td>103</td>
                    <td>67.1</td>
                    <td className="ek_c">c</td>
                    <td>35</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>29</td>
                    <td>Hong Kong Airlines</td>
                    <td>Hong Kong</td>
                    <td>66.2</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>74.3</td>
                    <td className="ek_c">c</td>
                    <td>6</td>
                    <td>66.2</td>
                    <td className="ek_c">c</td>
                    <td>38</td>
                    <td>61.7</td>
                    <td className="ek_d">d</td>
                    <td>29</td>
                  </tr>
                  <tr>
                    <td>30</td>
                    <td>Shenzhen Airlines</td>
                    <td>China</td>
                    <td>66.1</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>66.7</td>
                    <td className="ek_c">c</td>
                    <td>27</td>
                    <td>66.1</td>
                    <td className="ek_c">c</td>
                    <td>40</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>31</td>
                    <td>Xiamen Airlines Company</td>
                    <td>China</td>
                    <td>66.0</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>66.6</td>
                    <td className="ek_c">c</td>
                    <td>28</td>
                    <td>65.8</td>
                    <td className="ek_c">c</td>
                    <td>43</td>
                    <td>66.8</td>
                    <td className="ek_c">c</td>
                    <td>16</td>
                  </tr>
                  <tr>
                    <td>32</td>
                    <td>Air Canada</td>
                    <td>Kanada</td>
                    <td>65.6</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>57.7</td>
                    <td className="ek_d">d</td>
                    <td>55</td>
                    <td>63.2</td>
                    <td className="ek_d">d</td>
                    <td>49</td>
                    <td>69.0</td>
                    <td className="ek_c">c</td>
                    <td>8</td>
                  </tr>
                  <tr>
                    <td>33</td>
                    <td>Hainan Airlines</td>
                    <td>China</td>
                    <td>65.6</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>69.2</td>
                    <td className="ek_c">c</td>
                    <td>16</td>
                    <td>66.2</td>
                    <td className="ek_c">c</td>
                    <td>38</td>
                    <td>61.8</td>
                    <td className="ek_d">d</td>
                    <td>27</td>
                  </tr>
                  <tr>
                    <td>34</td>
                    <td>Iberia</td>
                    <td>Spanien</td>
                    <td>65.0</td>
                    <td className="ek_c">c</td>
                    <td className="white_cell" />
                    <td>66.5</td>
                    <td className="ek_c">c</td>
                    <td>30</td>
                    <td>67.9</td>
                    <td className="ek_c">c</td>
                    <td>27</td>
                    <td>58.9</td>
                    <td className="ek_d">d</td>
                    <td>35</td>
                  </tr>
                  <tr>
                    <td>35</td>
                    <td>Ural Airlines</td>
                    <td>Russland</td>
                    <td>64.9</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>62.3</td>
                    <td className="ek_d">d</td>
                    <td>43</td>
                    <td>66.0</td>
                    <td className="ek_c">c</td>
                    <td>42</td>
                    <td>58.6</td>
                    <td className="ek_d">d</td>
                    <td>36</td>
                  </tr>
                  <tr>
                    <td>36</td>
                    <td>Finnair</td>
                    <td>Finnland</td>
                    <td>64.4</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>61.7</td>
                    <td className="ek_d">d</td>
                    <td>44</td>
                    <td>67.5</td>
                    <td className="ek_c">c</td>
                    <td>28</td>
                    <td>61.9</td>
                    <td className="ek_d">d</td>
                    <td>26</td>
                  </tr>
                  <tr>
                    <td>37</td>
                    <td>China Eastern Airlines</td>
                    <td>China</td>
                    <td>64.0</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>66.5</td>
                    <td className="ek_c">c</td>
                    <td>30</td>
                    <td>68.7</td>
                    <td className="ek_c">c</td>
                    <td>24</td>
                    <td>44.6</td>
                    <td className="ek_e">e</td>
                    <td>74</td>
                  </tr>
                  <tr>
                    <td>38</td>
                    <td>Japan Airlines</td>
                    <td>Japan</td>
                    <td>63.9</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>73.6</td>
                    <td className="ek_c">c</td>
                    <td>8</td>
                    <td>72.3</td>
                    <td className="ek_c">c</td>
                    <td>11</td>
                    <td>53.9</td>
                    <td className="ek_d">d</td>
                    <td>48</td>
                  </tr>
                  <tr>
                    <td>39</td>
                    <td>Air India</td>
                    <td>Indien</td>
                    <td>63.4</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>59.7</td>
                    <td className="ek_d">d</td>
                    <td>51</td>
                    <td>65.3</td>
                    <td className="ek_c">c</td>
                    <td>45</td>
                    <td>61.8</td>
                    <td className="ek_d">d</td>
                    <td>27</td>
                  </tr>
                  <tr>
                    <td>40</td>
                    <td>El Al Israel Airlines</td>
                    <td>Israel</td>
                    <td>63.2</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>66.1</td>
                    <td className="ek_c">c</td>
                    <td>34</td>
                    <td>67.3</td>
                    <td className="ek_c">c</td>
                    <td>31</td>
                    <td>56.1</td>
                    <td className="ek_d">d</td>
                    <td>41</td>
                  </tr>
                  <tr>
                    <td>41</td>
                    <td>Air China</td>
                    <td>China</td>
                    <td>63.1</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>64.2</td>
                    <td className="ek_d">d</td>
                    <td>39</td>
                    <td>62.8</td>
                    <td className="ek_d">d</td>
                    <td>53</td>
                    <td>64.0</td>
                    <td className="ek_d">d</td>
                    <td>23</td>
                  </tr>
                  <tr>
                    <td>42</td>
                    <td>Batik Air</td>
                    <td>Indonesia</td>
                    <td>62.5</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>61.2</td>
                    <td className="ek_d">d</td>
                    <td>45</td>
                    <td>62.8</td>
                    <td className="ek_d">d</td>
                    <td>53</td>
                    <td>62.2</td>
                    <td className="ek_d">d</td>
                    <td>25</td>
                  </tr>
                  <tr>
                    <td>43</td>
                    <td>Royal Air Maroc Express</td>
                    <td>Marokko</td>
                    <td>62.3</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>65.5</td>
                    <td className="ek_c">c</td>
                    <td>35</td>
                    <td>53.9</td>
                    <td className="ek_d">d</td>
                    <td>78</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>44</td>
                    <td>Garuda Indonesia</td>
                    <td>Indonesien</td>
                    <td>61.9</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>65.2</td>
                    <td className="ek_c">c</td>
                    <td>36</td>
                    <td>62.8</td>
                    <td className="ek_d">d</td>
                    <td>53</td>
                    <td>57.5</td>
                    <td className="ek_d">d</td>
                    <td>37</td>
                  </tr>
                  <tr>
                    <td>45</td>
                    <td>Cathay Pacific Airways</td>
                    <td>Hong Kong</td>
                    <td>61.8</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>0.0</td>
                    <td className="ek_g">g</td>
                    <td>124</td>
                    <td>66.1</td>
                    <td className="ek_c">c</td>
                    <td>40</td>
                    <td>60.1</td>
                    <td className="ek_d">d</td>
                    <td>33</td>
                  </tr>
                  <tr>
                    <td>45</td>
                    <td>Delta Airlines</td>
                    <td>USA</td>
                    <td>61.8</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>58.6</td>
                    <td className="ek_d">d</td>
                    <td>53</td>
                    <td>66.5</td>
                    <td className="ek_c">c</td>
                    <td>36</td>
                    <td>54.7</td>
                    <td className="ek_d">d</td>
                    <td>46</td>
                  </tr>
                  <tr>
                    <td>47</td>
                    <td>Corsair</td>
                    <td>France</td>
                    <td>61.6</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>35.5</td>
                    <td className="ek_f">f</td>
                    <td>107</td>
                    <td>56.9</td>
                    <td className="ek_d">d</td>
                    <td>73</td>
                    <td>61.6</td>
                    <td className="ek_d">d</td>
                    <td>30</td>
                  </tr>
                  <tr>
                    <td>48</td>
                    <td>TAP Portuga</td>
                    <td>Portugal</td>
                    <td>61.5</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>45.9</td>
                    <td className="ek_e">e</td>
                    <td>84</td>
                    <td>65.2</td>
                    <td className="ek_c">c</td>
                    <td>46</td>
                    <td>56.9</td>
                    <td className="ek_d">d</td>
                    <td>38</td>
                  </tr>
                  <tr>
                    <td>49</td>
                    <td>Qantas Airways</td>
                    <td>Australien</td>
                    <td>61.4</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>75.8</td>
                    <td className="ek_c">c</td>
                    <td>4</td>
                    <td>72.3</td>
                    <td className="ek_c">c</td>
                    <td>11</td>
                    <td>48.8</td>
                    <td className="ek_e">e</td>
                    <td>64</td>
                  </tr>
                  <tr>
                    <td>50</td>
                    <td>Aerolineas Argentinas</td>
                    <td>Argentina</td>
                    <td>60.4</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>67.1</td>
                    <td className="ek_c">c</td>
                    <td>25</td>
                    <td>63.8</td>
                    <td className="ek_d">d</td>
                    <td>48</td>
                    <td>53.5</td>
                    <td className="ek_d">d</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>50</td>
                    <td>United Airlines</td>
                    <td>USA</td>
                    <td>60.4</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>60.5</td>
                    <td className="ek_d">d</td>
                    <td>46</td>
                    <td>67.2</td>
                    <td className="ek_c">c</td>
                    <td>34</td>
                    <td>52.3</td>
                    <td className="ek_d">d</td>
                    <td>54</td>
                  </tr>
                  <tr>
                    <td>52</td>
                    <td>China Southern Airlines</td>
                    <td>China</td>
                    <td>60.3</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>60.3</td>
                    <td className="ek_d">d</td>
                    <td>48</td>
                    <td>61.6</td>
                    <td className="ek_d">d</td>
                    <td>58</td>
                    <td>52.6</td>
                    <td className="ek_d">D</td>
                    <td>53</td>
                  </tr>
                  <tr>
                    <td>53</td>
                    <td>TianJin Airlines</td>
                    <td>China</td>
                    <td>60.0</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>56.1</td>
                    <td className="ek_d">d</td>
                    <td>59</td>
                    <td>62.3</td>
                    <td className="ek_d">d</td>
                    <td>56</td>
                    <td>51.2</td>
                    <td className="ek_d">d</td>
                    <td>57</td>
                  </tr>
                  <tr>
                    <td>54</td>
                    <td>Icelandair</td>
                    <td>Island</td>
                    <td>59.9</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>35.1</td>
                    <td className="ek_f">f</td>
                    <td>108</td>
                    <td>60.3</td>
                    <td className="ek_d">d</td>
                    <td>62</td>
                    <td>59.6</td>
                    <td className="ek_d">d</td>
                    <td>34</td>
                  </tr>
                  <tr>
                    <td>55</td>
                    <td>Shanghai Airlines</td>
                    <td>China</td>
                    <td>59.8</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>60.0</td>
                    <td className="ek_d">d</td>
                    <td>50</td>
                    <td>59.9</td>
                    <td className="ek_d">d</td>
                    <td>64</td>
                    <td>56.9</td>
                    <td className="ek_d">d</td>
                    <td>38</td>
                  </tr>
                  <tr>
                    <td>56</td>
                    <td>Cathay Dragon</td>
                    <td>Hong Kong</td>
                    <td>59.6</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>55.9</td>
                    <td className="ek_d">d</td>
                    <td>61</td>
                    <td>60.1</td>
                    <td className="ek_d">d</td>
                    <td>63</td>
                    <td>55.3</td>
                    <td className="ek_d">d</td>
                    <td>44</td>
                  </tr>
                  <tr>
                    <td>57</td>
                    <td>Hawaiian Airlines</td>
                    <td>USA</td>
                    <td>59.0</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>51.6</td>
                    <td className="ek_d">d</td>
                    <td>71</td>
                    <td />
                    <td />
                    <td />
                    <td>60.3</td>
                    <td className="ek_d">d</td>
                    <td>32</td>
                  </tr>
                  <tr>
                    <td>58</td>
                    <td>American Airlines</td>
                    <td>USA</td>
                    <td>58.7</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>51.9</td>
                    <td className="ek_d">d</td>
                    <td>70</td>
                    <td>64.1</td>
                    <td className="ek_d">d</td>
                    <td>47</td>
                    <td>47.2</td>
                    <td className="ek_e">e</td>
                    <td>68</td>
                  </tr>
                  <tr>
                    <td>58</td>
                    <td>MASwings</td>
                    <td>Malaysia</td>
                    <td>58.7</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>58.7</td>
                    <td className="ek_d">d</td>
                    <td>52</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>58</td>
                    <td>Urkraine Int. Airlines</td>
                    <td>Urkaine</td>
                    <td>58.7</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>41.0</td>
                    <td className="ek_e">e</td>
                    <td>100</td>
                    <td>61.2</td>
                    <td className="ek_d">d</td>
                    <td>59</td>
                    <td>56.3</td>
                    <td className="ek_d">d</td>
                    <td>40</td>
                  </tr>
                  <tr>
                    <td>61</td>
                    <td>All Nippon Airways</td>
                    <td>Japan</td>
                    <td>58.4</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>67.6</td>
                    <td className="ek_c">c</td>
                    <td>24</td>
                    <td>63.2</td>
                    <td className="ek_d">d</td>
                    <td>49</td>
                    <td>50.5</td>
                    <td className="ek_e">e</td>
                    <td>59</td>
                  </tr>
                  <tr>
                    <td>61</td>
                    <td>Malaysia Airlines</td>
                    <td>Malaysia</td>
                    <td>58.4</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>52.2</td>
                    <td className="ek_d">d</td>
                    <td>68</td>
                    <td>61.8</td>
                    <td className="ek_d">d</td>
                    <td>57</td>
                    <td>54.2</td>
                    <td className="ek_d">d</td>
                    <td>47</td>
                  </tr>
                  <tr>
                    <td>63</td>
                    <td>Copa Airlines</td>
                    <td>Panama</td>
                    <td>58.2</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>43.7</td>
                    <td className="ek_e">e</td>
                    <td>87</td>
                    <td>54.5</td>
                    <td className="ek_d">d</td>
                    <td>76</td>
                    <td>64.7</td>
                    <td className="ek_d">d</td>
                    <td>21</td>
                  </tr>
                  <tr>
                    <td>64</td>
                    <td>Aeromexico</td>
                    <td>Mexico</td>
                    <td>58.1</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>56.1</td>
                    <td className="ek_d">d</td>
                    <td>59</td>
                    <td>56.2</td>
                    <td className="ek_d">d</td>
                    <td>74</td>
                    <td>63.1</td>
                    <td className="ek_d">d</td>
                    <td>24</td>
                  </tr>
                  <tr>
                    <td>65</td>
                    <td>Alitalia</td>
                    <td>Italien</td>
                    <td>57.2</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>60.5</td>
                    <td className="ek_d">d</td>
                    <td>46</td>
                    <td>60.7</td>
                    <td className="ek_d">d</td>
                    <td>61</td>
                    <td>52.2</td>
                    <td className="ek_d">d</td>
                    <td>55</td>
                  </tr>
                  <tr>
                    <td>66</td>
                    <td>Lufthansa</td>
                    <td>Deutschland</td>
                    <td>56.9</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>58.1</td>
                    <td className="ek_d">d</td>
                    <td>54</td>
                    <td>67.4</td>
                    <td className="ek_c">c</td>
                    <td>30</td>
                    <td>49.4</td>
                    <td className="ek_e">e</td>
                    <td>61</td>
                  </tr>
                  <tr>
                    <td>67</td>
                    <td>Singapore Airlines</td>
                    <td>Singapore</td>
                    <td>56.5</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>41.8</td>
                    <td className="ek_e">e</td>
                    <td>97</td>
                    <td>61.1</td>
                    <td className="ek_d">d</td>
                    <td>60</td>
                    <td>55.5</td>
                    <td className="ek_d">d</td>
                    <td>43</td>
                  </tr>
                  <tr>
                    <td>68</td>
                    <td>Aeroflot Russian Airlines</td>
                    <td>Russland</td>
                    <td>56.4</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>52.7</td>
                    <td className="ek_d">d</td>
                    <td>66</td>
                    <td>57.8</td>
                    <td className="ek_d">d</td>
                    <td>70</td>
                    <td>53.5</td>
                    <td className="ek_d">d</td>
                    <td>50</td>
                  </tr>
                  <tr>
                    <td>69</td>
                    <td>Turkish Airlines</td>
                    <td>Türkei</td>
                    <td>56.2</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>63.9</td>
                    <td className="ek_d">d</td>
                    <td>40</td>
                    <td>58.6</td>
                    <td className="ek_d">d</td>
                    <td>67</td>
                    <td>49.2</td>
                    <td className="ek_e">e</td>
                    <td>63</td>
                  </tr>
                  <tr>
                    <td>70</td>
                    <td>Asiana Airlines</td>
                    <td>Südkorea</td>
                    <td>56.1</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>66.3</td>
                    <td className="ek_c">c</td>
                    <td>32</td>
                    <td>58.3</td>
                    <td className="ek_d">d</td>
                    <td>68</td>
                    <td>50.2</td>
                    <td className="ek_e">e</td>
                    <td>60</td>
                  </tr>
                  <tr>
                    <td>71</td>
                    <td>Korean Air</td>
                    <td>Südkorea of</td>
                    <td>55.9</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>66.6</td>
                    <td className="ek_c">c</td>
                    <td>28</td>
                    <td>63.1</td>
                    <td className="ek_d">d</td>
                    <td>51</td>
                    <td>50.6</td>
                    <td className="ek_e">e</td>
                    <td>58</td>
                  </tr>
                  <tr>
                    <td>72</td>
                    <td>Srilankan Airlines</td>
                    <td>Sri Lanka</td>
                    <td>55.6</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>57.3</td>
                    <td className="ek_d">d</td>
                    <td>58</td>
                    <td>57.8</td>
                    <td className="ek_d">d</td>
                    <td>70</td>
                    <td>53.2</td>
                    <td className="ek_d">d</td>
                    <td>52</td>
                  </tr>
                  <tr>
                    <td>73</td>
                    <td>Air France</td>
                    <td>Frankreich</td>
                    <td>54.5</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>67.7</td>
                    <td className="ek_c">c</td>
                    <td>23</td>
                    <td>67.5</td>
                    <td className="ek_c">c</td>
                    <td>28</td>
                    <td>46.4</td>
                    <td className="ek_e">e</td>
                    <td>70</td>
                  </tr>
                  <tr>
                    <td>74</td>
                    <td>British Airways</td>
                    <td>UK</td>
                    <td>54.4</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>57.6</td>
                    <td className="ek_d">d</td>
                    <td>56</td>
                    <td>65.8</td>
                    <td className="ek_c">c</td>
                    <td>43</td>
                    <td>47.6</td>
                    <td className="ek_e">e</td>
                    <td>67</td>
                  </tr>
                  <tr>
                    <td>75</td>
                    <td>Iberia Regional</td>
                    <td>Spanien</td>
                    <td>54.3</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>55.9</td>
                    <td className="ek_d">d</td>
                    <td>61</td>
                    <td>50.4</td>
                    <td className="ek_e">e</td>
                    <td>87</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>76</td>
                    <td>Royal Air Maroc</td>
                    <td>Marokko</td>
                    <td>54.0</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>42.2</td>
                    <td className="ek_e">e</td>
                    <td>96</td>
                    <td>53.9</td>
                    <td className="ek_d">d</td>
                    <td>78</td>
                    <td>55.7</td>
                    <td className="ek_d">d</td>
                    <td>42</td>
                  </tr>
                  <tr>
                    <td>77</td>
                    <td>QantasLink</td>
                    <td>Australien</td>
                    <td>53.6</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>55.8</td>
                    <td className="ek_d">d</td>
                    <td>63</td>
                    <td>46.5</td>
                    <td className="ek_e">e</td>
                    <td>94</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>78</td>
                    <td>SAS Scandinavian Airlines</td>
                    <td>Schweden</td>
                    <td>53.4</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>50.2</td>
                    <td className="ek_e">e</td>
                    <td>74</td>
                    <td>59.3</td>
                    <td className="ek_d">d</td>
                    <td>65</td>
                    <td>44.7</td>
                    <td className="ek_e">e</td>
                    <td>72</td>
                  </tr>
                  <tr>
                    <td>79</td>
                    <td>EVA Airways</td>
                    <td>Taiwan</td>
                    <td>53.2</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>52.0</td>
                    <td className="ek_d">d</td>
                    <td>69</td>
                    <td>58.7</td>
                    <td className="ek_d">d</td>
                    <td>66</td>
                    <td>49.4</td>
                    <td className="ek_e">e</td>
                    <td>61</td>
                  </tr>
                  <tr>
                    <td>79</td>
                    <td>SilkAir</td>
                    <td>Singapore</td>
                    <td>53.2</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>42.7</td>
                    <td className="ek_e">e</td>
                    <td>94</td>
                    <td>54.0</td>
                    <td className="ek_d">d</td>
                    <td>77</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>81</td>
                    <td>Austrian Airlines</td>
                    <td>Österreich</td>
                    <td>51.6</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>43.3</td>
                    <td className="ek_e">e</td>
                    <td>90</td>
                    <td>53.2</td>
                    <td className="ek_d">d</td>
                    <td>81</td>
                    <td>54.9</td>
                    <td className="ek_d">d</td>
                    <td>45</td>
                  </tr>
                  <tr>
                    <td>82</td>
                    <td>China Airlines</td>
                    <td>Taiwan</td>
                    <td>51.4</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>49.7</td>
                    <td className="ek_e">e</td>
                    <td>76</td>
                    <td>53.7</td>
                    <td className="ek_d">d</td>
                    <td>80</td>
                    <td>48.4</td>
                    <td className="ek_e">e</td>
                    <td>66</td>
                  </tr>
                  <tr>
                    <td>83</td>
                    <td>Virgin Atlantic Airways</td>
                    <td>UK</td>
                    <td>51.3</td>
                    <td className="ek_d">d</td>
                    <td className="white_cell" />
                    <td>0.0</td>
                    <td className="ek_g">g</td>
                    <td>124</td>
                    <td />
                    <td />
                    <td />
                    <td>51.4</td>
                    <td className="ek_d">d</td>
                    <td>56</td>
                  </tr>
                  <tr>
                    <td>84</td>
                    <td>Brussels Airlines</td>
                    <td>Belgium</td>
                    <td>50.5</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>48.8</td>
                    <td className="ek_e">e</td>
                    <td>80</td>
                    <td>53.2</td>
                    <td className="ek_d">d</td>
                    <td>81</td>
                    <td>46.6</td>
                    <td className="ek_e">e</td>
                    <td>69</td>
                  </tr>
                  <tr>
                    <td>85</td>
                    <td>South African Express</td>
                    <td>Südafrika</td>
                    <td>50.3</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>52.5</td>
                    <td className="ek_d">d</td>
                    <td>67</td>
                    <td>43.7</td>
                    <td className="ek_e">e</td>
                    <td>99</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>86</td>
                    <td>Air Algerie</td>
                    <td>Algerien</td>
                    <td>50.2</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>57.6</td>
                    <td className="ek_d">d</td>
                    <td>56</td>
                    <td>49.6</td>
                    <td className="ek_e">e</td>
                    <td>90</td>
                    <td>44.8</td>
                    <td className="ek_e">e</td>
                    <td>71</td>
                  </tr>
                  <tr>
                    <td>87</td>
                    <td>Pakistan Int. Airlines</td>
                    <td>Pakistan</td>
                    <td>50.1</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>43.3</td>
                    <td className="ek_e">e</td>
                    <td>90</td>
                    <td>58.0</td>
                    <td className="ek_d">d</td>
                    <td>69</td>
                    <td>38.2</td>
                    <td className="ek_e">e</td>
                    <td>84</td>
                  </tr>
                  <tr>
                    <td>87</td>
                    <td>Philippine Airlines</td>
                    <td>Philippinen</td>
                    <td>50.1</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>51.5</td>
                    <td className="ek_d">d</td>
                    <td>72</td>
                    <td>57.2</td>
                    <td className="ek_d">d</td>
                    <td>72</td>
                    <td>37.1</td>
                    <td className="ek_e">e</td>
                    <td>85</td>
                  </tr>
                  <tr>
                    <td>89</td>
                    <td>Swiss</td>
                    <td>Schweiz</td>
                    <td>49.7</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>60.1</td>
                    <td className="ek_d">d</td>
                    <td>49</td>
                    <td>69.0</td>
                    <td className="ek_c">c</td>
                    <td>23</td>
                    <td>38.5</td>
                    <td className="ek_e">e</td>
                    <td>83</td>
                  </tr>
                  <tr>
                    <td>90</td>
                    <td>Alaska Horizon</td>
                    <td>USA</td>
                    <td>49.5</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>49.0</td>
                    <td className="ek_e">e</td>
                    <td>78</td>
                    <td>50.3</td>
                    <td className="ek_e">e</td>
                    <td>88</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>91</td>
                    <td>Jazz Aviation</td>
                    <td>Kanada</td>
                    <td>49.1</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>51.3</td>
                    <td className="ek_d">d</td>
                    <td>73</td>
                    <td>47.3</td>
                    <td className="ek_e">e</td>
                    <td>91</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>92</td>
                    <td>PAL Express</td>
                    <td>Philippinen</td>
                    <td>48.8</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>48.4</td>
                    <td className="ek_e">e</td>
                    <td>82</td>
                    <td>51.1</td>
                    <td className="ek_d">d</td>
                    <td>86</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>93</td>
                    <td>ANA Wings</td>
                    <td>Japan</td>
                    <td>48.6</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>49.1</td>
                    <td className="ek_e">e</td>
                    <td>77</td>
                    <td>44.0</td>
                    <td className="ek_e">e</td>
                    <td>98</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>94</td>
                    <td>Nordic Regional Airlines</td>
                    <td>Finland</td>
                    <td>48.3</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>63.9</td>
                    <td className="ek_d">d</td>
                    <td>40</td>
                    <td>36.6</td>
                    <td className="ek_e">e</td>
                    <td>110</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>95</td>
                    <td>Gulf Air</td>
                    <td>Bahrain</td>
                    <td>47.3</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>35.1</td>
                    <td className="ek_f">f</td>
                    <td>108</td>
                    <td>52.7</td>
                    <td className="ek_d">d</td>
                    <td>83</td>
                    <td>41.6</td>
                    <td className="ek_e">e</td>
                    <td>77</td>
                  </tr>
                  <tr>
                    <td>96</td>
                    <td>Etihad Airways</td>
                    <td>VAE</td>
                    <td>47.2</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>49.8</td>
                    <td className="ek_e">e</td>
                    <td>75</td>
                    <td>55.8</td>
                    <td className="ek_d">d</td>
                    <td>75</td>
                    <td>44.1</td>
                    <td className="ek_e">e</td>
                    <td>75</td>
                  </tr>
                  <tr>
                    <td>97</td>
                    <td>LOT - Polish Airlines</td>
                    <td>Polen</td>
                    <td>47.0</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>43.6</td>
                    <td className="ek_e">e</td>
                    <td>88</td>
                    <td>38.2</td>
                    <td className="ek_e">e</td>
                    <td>106</td>
                    <td>70.6</td>
                    <td className="ek_c">c</td>
                    <td>6</td>
                  </tr>
                  <tr>
                    <td>98</td>
                    <td>Flybe</td>
                    <td>UK</td>
                    <td>46.8</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>48.6</td>
                    <td className="ek_e">e</td>
                    <td>81</td>
                    <td>41.3</td>
                    <td className="ek_e">e</td>
                    <td>101</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>99</td>
                    <td>Lufthansa Regional</td>
                    <td>Deutschland</td>
                    <td>46.7</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>43.9</td>
                    <td className="ek_e">e</td>
                    <td>86</td>
                    <td>50.0</td>
                    <td className="ek_e">e</td>
                    <td>89</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>100</td>
                    <td>Qatar Airways</td>
                    <td>Qatar</td>
                    <td>46.4</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>44.0</td>
                    <td className="ek_e">e</td>
                    <td>85</td>
                    <td>51.7</td>
                    <td className="ek_d">d</td>
                    <td>85</td>
                    <td>44.7</td>
                    <td className="ek_e">e</td>
                    <td>72</td>
                  </tr>
                  <tr>
                    <td>101</td>
                    <td>Egyptair</td>
                    <td>Ägypten</td>
                    <td>44.7</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>49.0</td>
                    <td className="ek_e">e</td>
                    <td>78</td>
                    <td>45.9</td>
                    <td className="ek_e">e</td>
                    <td>96</td>
                    <td>40.1</td>
                    <td className="ek_e">e</td>
                    <td>80</td>
                  </tr>
                  <tr>
                    <td>102</td>
                    <td>BA CityFlyer</td>
                    <td>UK</td>
                    <td>43.6</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>42.3</td>
                    <td className="ek_e">e</td>
                    <td>95</td>
                    <td>44.7</td>
                    <td className="ek_e">e</td>
                    <td>97</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>103</td>
                    <td>Oman Air</td>
                    <td>Oman</td>
                    <td>43.4</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>38.4</td>
                    <td className="ek_e">e</td>
                    <td>104</td>
                    <td>46.3</td>
                    <td className="ek_e">e</td>
                    <td>95</td>
                    <td>40.5</td>
                    <td className="ek_e">e</td>
                    <td>79</td>
                  </tr>
                  <tr>
                    <td>104</td>
                    <td>HOP!</td>
                    <td>France</td>
                    <td>42.9</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>46.7</td>
                    <td className="ek_e">e</td>
                    <td>83</td>
                    <td>38.0</td>
                    <td className="ek_e">e</td>
                    <td>107</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>104</td>
                    <td>Kuwait Airways</td>
                    <td>Kuwait</td>
                    <td>42.9</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>43.5</td>
                    <td className="ek_e">e</td>
                    <td>89</td>
                    <td>46.7</td>
                    <td className="ek_e">e</td>
                    <td>92</td>
                    <td>38.7</td>
                    <td className="ek_e">e</td>
                    <td>82</td>
                  </tr>
                  <tr>
                    <td>106</td>
                    <td>Ohana by Hawaiian</td>
                    <td>USA</td>
                    <td>42.8</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>42.8</td>
                    <td className="ek_e">e</td>
                    <td>93</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>107</td>
                    <td>J-Air</td>
                    <td>Japan</td>
                    <td>41.1</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>41.3</td>
                    <td className="ek_e">e</td>
                    <td>98</td>
                    <td>40.5</td>
                    <td className="ek_e">e</td>
                    <td>104</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>108</td>
                    <td>Emirates</td>
                    <td>VAE</td>
                    <td>40.7</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>36.4</td>
                    <td className="ek_e">e</td>
                    <td>106</td>
                    <td>46.7</td>
                    <td className="ek_e">e</td>
                    <td>92</td>
                    <td>39.5</td>
                    <td className="ek_e">e</td>
                    <td>81</td>

                  </tr>
                  <tr>
                    <td>109</td>
                    <td>Swiss Global Air Lines</td>
                    <td>Schweiz</td>
                    <td>40.3</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>39.4</td>
                    <td className="ek_e">e</td>
                    <td>102</td>
                    <td>40.9</td>
                    <td className="ek_e">e</td>
                    <td>102</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>110</td>
                    <td>Saudi Arabian Airlines</td>
                    <td>Saudi-Arabien</td>
                    <td>40.2</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>41.2</td>
                    <td className="ek_e">e</td>
                    <td>99</td>
                    <td>42.1</td>
                    <td className="ek_e">e</td>
                    <td>100</td>
                    <td>36.9</td>
                    <td className="ek_e">e</td>
                    <td>86</td>
                  </tr>
                  <tr>
                    <td>111</td>
                    <td>South African Airways</td>
                    <td>Südafrika</td>
                    <td>39.5</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>53.9</td>
                    <td className="ek_d">d</td>
                    <td>65</td>
                    <td>51.9</td>
                    <td className="ek_d">d</td>
                    <td>84</td>
                    <td>29.1</td>
                    <td className="ek_f">f</td>
                    <td>88</td>
                  </tr>
                  <tr>
                    <td>112</td>
                    <td>Aeromexico Connect</td>
                    <td>Mexico</td>
                    <td>38.6</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>34.1</td>
                    <td className="ek_f">f</td>
                    <td>110</td>
                    <td>40.9</td>
                    <td className="ek_e">e</td>
                    <td>102</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>113</td>
                    <td>Austral Lineas Aereas</td>
                    <td>Argentinien</td>
                    <td>37.7</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>37.3</td>
                    <td className="ek_e">e</td>
                    <td>105</td>
                    <td>37.8</td>
                    <td className="ek_e">e</td>
                    <td>108</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>114</td>
                    <td>Royal Jordanian</td>
                    <td>Jordanien</td>
                    <td>37.4</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>20.2</td>
                    <td className="ek_f">f</td>
                    <td>120</td>
                    <td>31.5</td>
                    <td className="ek_f">f</td>
                    <td>114</td>
                    <td>53.9</td>
                    <td className="ek_d">d</td>
                    <td>48</td>
                  </tr>
                  <tr>
                    <td>115</td>
                    <td>Ethiopian Airlines</td>
                    <td>Äthiopien</td>
                    <td>36.5</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>23.8</td>
                    <td className="ek_f">f</td>
                    <td>117</td>
                    <td>32.4</td>
                    <td className="ek_f">f</td>
                    <td>112</td>
                    <td>40.8</td>
                    <td className="ek_e">e</td>
                    <td>78</td>
                  </tr>
                  <tr>
                    <td>116</td>
                    <td>Virgin Australia Regional</td>
                    <td>Australien</td>
                    <td>36.0</td>
                    <td className="ek_e">e</td>
                    <td className="white_cell" />
                    <td>33.7</td>
                    <td className="ek_f">f</td>
                    <td>111</td>
                    <td>36.2</td>
                    <td className="ek_e">e</td>
                    <td>111</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>117</td>
                    <td>Air Astana</td>
                    <td>Kasachstan</td>
                    <td>34.8</td>
                    <td className="ek_f">f</td>
                    <td className="white_cell" />
                    <td>26.9</td>
                    <td className="ek_f">f</td>
                    <td>115</td>
                    <td>37.5</td>
                    <td className="ek_e">e</td>
                    <td>109</td>
                    <td>28.3</td>
                    <td className="ek_f">f</td>
                    <td>89</td>
                  </tr>
                  <tr>
                    <td>118</td>
                    <td>Mahan Air</td>
                    <td>Iran</td>
                    <td>33.9</td>
                    <td className="ek_f">f</td>
                    <td className="white_cell" />
                    <td>27.4</td>
                    <td className="ek_f">f</td>
                    <td>114</td>
                    <td>40.4</td>
                    <td className="ek_e">e</td>
                    <td>105</td>
                    <td>29.8</td>
                    <td className="ek_f">f</td>
                    <td>87</td>
                  </tr>
                  <tr>
                    <td>119</td>
                    <td>United Express</td>
                    <td>USA</td>
                    <td>31.1</td>
                    <td className="ek_f">f</td>
                    <td className="white_cell" />
                    <td>29.2</td>
                    <td className="ek_f">f</td>
                    <td>112</td>
                    <td>31.9</td>
                    <td className="ek_f">f</td>
                    <td>113</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>120</td>
                    <td>TAP Express</td>
                    <td>Portugal</td>
                    <td>30.6</td>
                    <td className="ek_f">f</td>
                    <td className="white_cell" />
                    <td>17.5</td>
                    <td className="ek_g">g</td>
                    <td>121</td>
                    <td>31.5</td>
                    <td className="ek_f">f</td>
                    <td>114</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>121</td>
                    <td>Delta Connection</td>
                    <td>USA</td>
                    <td>28.5</td>
                    <td className="ek_f">f</td>
                    <td className="white_cell" />
                    <td>21.6</td>
                    <td className="ek_f">f</td>
                    <td>118</td>
                    <td>31.3</td>
                    <td className="ek_f">f</td>
                    <td>116</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>122</td>
                    <td>Envoy</td>
                    <td>USA</td>
                    <td>28.2</td>
                    <td className="ek_f">f</td>
                    <td className="white_cell" />
                    <td>25.0</td>
                    <td className="ek_f">f</td>
                    <td>116</td>
                    <td>30.3</td>
                    <td className="ek_f">f</td>
                    <td>117</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>123</td>
                    <td>Kenya Airways</td>
                    <td>Kenia</td>
                    <td>27.6</td>
                    <td className="ek_f">f</td>
                    <td className="white_cell" />
                    <td>10.9</td>
                    <td className="ek_g">g</td>
                    <td>122</td>
                    <td>18.4</td>
                    <td className="ek_g">g</td>
                    <td>118</td>
                    <td>43.3</td>
                    <td className="ek_e">e</td>
                    <td>76</td>
                  </tr>
                  <tr>
                    <td>124</td>
                    <td>Egyptair Express</td>
                    <td>Ägypten</td>
                    <td>25.4</td>
                    <td className="ek_f">f</td>
                    <td className="white_cell" />
                    <td>28.3</td>
                    <td className="ek_f">f</td>
                    <td>113</td>
                    <td>17</td>
                    <td className="ek_g">g</td>
                    <td>119</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  <tr>
                    <td>125</td>
                    <td>South African Airlink</td>
                    <td>Südafrika</td>
                    <td>2.3</td>
                    <td className="ek_g">g</td>
                    <td className="white_cell" />
                    <td>3.7</td>
                    <td className="ek_g">g</td>
                    <td>123</td>
                    <td>1.6</td>
                    <td className="ek_g">g</td>
                    <td>120</td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="climate_airline_right">
            <img className="atmosfair_logo" src="images/atmosfair_logo.jpg" />
            <div className="clear" />
            <span className="tr fr f13 lght">atmosfair Airline Index 2018</span>
            <div className="clear" />
            <div className="climate_airline_right_block_header climate_airline_table_top">
              <div className="climate_airline_table_block_header">
                <span>Billigflieger</span>
              </div>
              <div className="climate_airline_table_header">
                <table border="1">
                  <thead>
                  <tr>
                    <th colSpan="2">&nbsp;</th>
                  </tr>
                  <tr>
                    <th style={{ width: 23 }}>&nbsp;</th>
                    <th>&nbsp;</th>
                  </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div className="climate_airline_right_table_block_body">
              <table border="1">
                <tbody>
                <tr>
                  <td className="ek_a">a</td>
                  <td>Keine Airline</td>
                </tr>
                <tr>
                  <td className="ek_b">b</td>
                  <td>IndiGo Air, Indonesia AirAsia, Lion Air, Norwegian, Ryanair, Scoot, SpiceJet, Spring Airlines, Transavia.com</td>
                </tr>
                <tr>
                  <td className="ek_c">c</td>
                  <td>Aer Lingus, Air Arabia, AirAsia, China United Airlines, Citilink Indonesia, Easyjet, Eurowings, Frontier Airlines, germanwings, Go Air, Jeju Airlines, Jetstar Airways, Lucky Air, Nok Air, Pegasus Airlines, Southwest Airlines, Spirit Airlines, Thai AirAsia, Tigerair Taiwan,</td>
                </tr>
                <tr>
                  <td className="ek_d">d</td>
                  <td>Airasia X, Allegiant Air, Azul Airlines, Cebu Pacific Air, Flydubai, JetBlue Airways, Virgin America, Westjet</td>
                </tr>
                <tr>
                  <td className="ek_e">e</td>
                  <td>Interjet</td>
                </tr>
                <tr>
                  <td className="ek_f">f</td>
                  <td>Keine Airline</td>
                </tr>
                <tr>
                  <td className="ek_g">g</td>
                  <td>Keine Airline</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="clear20" />
          <p className="climate_rate">
            <span><i className="icon_shamrock icon_shamrock_3" /> = atmosfair <span className="class_letter class_letter_b f14">b</span></span>
            <span><i className="icon_shamrock icon_shamrock_2" /> = atmosfair <span className="class_letter class_letter_c f14">c</span></span>
            <span><i className="icon_shamrock icon_shamrock_1" /> = atmosfair <span className="class_letter class_letter_d f14">d</span></span>
            <span><i className="icon_shamrock icon_shamrock_0" /> = atmosfair <span className="class_letter class_letter_e f14">e</span></span>
            <span className="f13"><b>EP*:</b> Effizienzpunkte von 100</span>
            <span className="f13"><b>EK*: </b> Effizienzklasse</span>
            <span className="f13">Bei Punktgleichheit alphabetisch sortiert</span>
          </p>
        </div>
        <div className="clear" />
      </div>
    </div>
  </div>
) : null);
