import React from 'react';

export default () => (
  <React.Fragment>
    <p>4.1 Angaben über vermittelte Beförderungen oder andere touristische Leistungen beruhen ausschließlich auf den Angaben der
      verantwortlichen Reiseveranstalter und/oder Leistungsträger gegenüber BLAUWEGE GMBH. Sie stellen keine eigene Angabe oder
      Zusicherung von BLAUWEGE GMBH gegenüber dem Nutzer dar. Bei den vermittelten Leistungen haftet BLAUWEGE GMBH nicht für die
      Leistungserbringung durch die Reiseveranstalter und/oder Leistungsträger, sondern lediglich für die ordnungsgemäße Weitergabe der
      ihm im Rahmen der Erteilung des Vermittlungsauftrages übermittelten Informationen des Nutzers an den jeweiligen Reiseveranstalter
      und/oder Leistungsträger. Die Haftung gegenüber dem Nutzer für die Richtigkeit der von dessen Vertragspartnern gemachten Angaben
      ist ebenfalls ausgeschlossen, sofern BLAUWEGE GMBH diese Daten nicht grob fahrlässig oder vorsätzlich falsch übermittelt.</p>
    <p>4.2 Die Erbringung von Leistungen, die dem jeweiligen Reiseveranstalter und/oder Leistungsträger obliegen, ist nicht Gegenstand
      des mit BLAUWEGE GMBH bestehenden Vertragsverhältnisses. Für diese haftet allein der jeweilige Reiseveranstalter und/oder
      Leistungsträger. Eine Haftung von BLAUWEGE GMBH für die von den Reiseveranstalter und/oder Leistungsträger zu erbringenden
      Leistungen besteht daher nicht. BLAUWEGE GMBH haftet dem Nutzer gegenüber jedoch für eine ordnungsgemäße Vermittlung im Rahmen
      der Sorgfaltspflichten eines ordentlichen Kaufmannes.</p>
    <p>4.3 BLAUWEGE GMBH übernimmt keine Haftung für die unterbrechungsfreie Verfügbarkeit des Systems und der Website, sowie für
      systembedingte Unterbrechungen, Ausfälle und Störungen der technischen Anlagen und des Services von BLAUWEGE GMBH. Die Haftung
      ist insbesondere ausgeschlossen für den Ausfall von Kommunikationsnetzen und Gateways.</p>
    <p>4.4 Für entstandene Schäden im Zusammenhang mit der von BLAUWEGE GMBH geleisteten Vermittlungstätigkeit haftet BLAUWEGE GMBH im
      Falle einfacher Fahrlässigkeit - gleich aus welchem Rechtsgrund - gegenüber dem Nutzer nur dann auf Schadensersatz, wenn ein
      Organ, Mitarbeiter oder Erfüllungsgehilfe von BLAUWEGE GMBH eine vertragswesentliche Pflicht verletzt hat. Die Haftung ist in
      diesem Fall jedoch begrenzt auf den typischer Weise entstehenden Schaden und betragsmäßig begrenzt auf die Höhe des Preises der
      vermittelten Leistung. Eine darüber hinausgehende Haftung übernimmt BLAUWEGE GMBH, wenn</p>
    <ul>
      <li>ein Schaden durch eine vorsätzliche oder grob fahrlässige Verletzung einer Vertragspflicht durch BLAUWEGE GMBH, ihre gesetzlichen Vertreter und/oder Erfüllungsgehilfen entstanden ist,</li>
      <li>BLAUWEGE GMBH vor oder bei Vertragsabschluss eine verkehrswesentliche Eigenschaft der vertraglichen Leistung zugesichert hat und diese Eigenschaften nach Erbringung der vertraglichen Leistungen nicht vorhanden ist oder</li>
      <li>der Schaden durch vorsätzliche oder grob fahrlässige Verletzung einer Hauptvertragspflicht verursacht wurde, d.h. einer für den Nutzer so bedeutenden Vertragspflicht, dass er den Vertrag nicht abgeschlossen hätte, ohne auf die Erfüllung dieser Vertragspflicht vertrauen zu können.</li>
    </ul>
    <p>4.5 Ansprüche gegen BLAUWEGE GMBH für entstandene Schäden im Zusammenhang mit der von BLAUWEGE GMBH geleisteten Vermittlungstätigkeit verjähren in einem Jahr ab dem jeweiligen gesetzlichen Verjährungsbeginn. Ansonsten gilt die gesetzliche Verjährungsfrist.</p>
  </React.Fragment>
);
