export const RESULT = {
  LIST: 'RESULT_LIST',
  LIST_STARTED: 'RESULT_LIST_STARTED',
  LIST_DONE: 'RESULT_LIST_DONE',
  QUEUE_DONE: 'RESULT_QUEUE_DONE',
  SELECTION_ID_DONE: 'RESULT_SELECTION_ID_DONE',
  LIST_FAILED: 'RESULT_LIST_FAILED',
  LIST_CANCEL: 'RESULT_LIST_CANCEL',
  PUT_PORTION: 'RESULT_PUT_PORTION',
  RESET: 'RESULT_RESET',
  SORT_BY_GROUP: 'RESULT_SORT_BY_GROUP',
  SORT_BY_GROUP_DONE: 'RESULT_SORT_BY_GROUP_DONE',
  LOAD_SELECTION_CODE: 'RESULT_LOAD_SELECTION_CODE',
  LOAD_SELECTION_CODE_DONE: 'RESULT_LOAD_SELECTION_CODE_DONE',
  LOAD_SELECTION_CODE_FAILED: 'RESULT_LOAD_SELECTION_CODE_FAILED',
};

export const load = () => ({ type: RESULT.LIST });
export const loadSelectionCode = () => ({ type: RESULT.LOAD_SELECTION_CODE });
export const cancel = () => ({ type: RESULT.LIST_CANCEL });
export const reset = () => ({ type: RESULT.RESET });
export const sortByGroup = groupId => ({ type: RESULT.SORT_BY_GROUP, groupId });
