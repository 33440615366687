import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import GoogleAnalytics from "react-ga";
import { setNotShown } from "../../actions/animation";
import { showIntro } from "../../utils/helper";
import FacebookButton from "./FacebookButton";
import TwitterButton from "./TwitterButton";
import EmailButton from "./EmailButton";

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setAnimationNotShown: setNotShown,
    },
    dispatch
  );

const Footer = ({ setAnimationNotShown }) => (
  <footer id="main_footer" className="main_footer">
    <div className="content tc">
      <p className="fl">&copy; 2019 holidayfind.de</p>
      {/* {showIntro && (
        <Link
          className="f17 intro_btn fr"
          to="/"
          onClick={() => {
            GoogleAnalytics.event({ category: 'Footer', action: 'Link', label: 'Intro' })
            setAnimationNotShown();
          }}
        >Intro</Link>
      )} */}
      <ul className="fr soc_block">
        <li className="soc soc_fb">
          <FacebookButton />
        </li>
        <li className="soc soc_tw">
          <TwitterButton />
        </li>
        <li className="soc soc_mail">
          <EmailButton />
        </li>
      </ul>
      <ul className="f_nav">
        <li>
          <Link
            to="/ueber-uns"
            onClick={() =>
              GoogleAnalytics.event({
                category: "Footer",
                action: "Link",
                label: "Über uns",
              })
            }
          >
            Über uns
          </Link>
        </li>{" "}
        <li>
          <Link
            to="/umweltschutz"
            onClick={() =>
              GoogleAnalytics.event({
                category: "Footer",
                action: "Link",
                label: "Umweltschutz",
              })
            }
          >
            Umweltschutz
          </Link>
        </li>{" "}
        <li>
          <Link
            to="/datenschutz"
            onClick={() =>
              GoogleAnalytics.event({
                category: "Footer",
                action: "Link",
                label: "Datenschutz",
              })
            }
          >
            Datenschutz
          </Link>
        </li>{" "}
        <li>
          <Link
            to="/allgemeine-geschaeftsbedingungen"
            onClick={() =>
              GoogleAnalytics.event({
                category: "Footer",
                action: "Link",
                label: "AGB",
              })
            }
          >
            AGB
          </Link>
        </li>{" "}
        <li>
          <Link
            to="/impressum"
            onClick={() =>
              GoogleAnalytics.event({
                category: "Footer",
                action: "Link",
                label: "Impressum",
              })
            }
          >
            Impressum
          </Link>
        </li>
      </ul>
    </div>
  </footer>
);

Footer.propTypes = {
  setAnimationNotShown: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
