import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GoogleAnalytics from 'react-ga';
import Ticker from 'react-ticker';
import { show } from '../../actions/popup';

const delay = 3; // seconds

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  showPopup: show,
}, dispatch);

const MyTicker = ({ showPopup }) => {
  const [delayed, setDelayed] = useState(false);

  useEffect(() => {
    // NOTE: we should use setTimeout anyway
    // with at least 300 ms as hotfix to remove unmanaged space between ticker elements
    const timeoutID = setTimeout(() => setDelayed(true), 1000 * delay);

    return () => clearTimeout(timeoutID);
  }, []);

  return (
    <Ticker move={delayed} speed={5} offset="run-in">{() => (
      <p style={{ marginRight: 70 }}>
        <button
          type="button"
          className="sm_logo sm_logo_ticker"
          onClick={() => {
            GoogleAnalytics.event({ category: 'Header', action: 'Ticker', label: 'Click' });
            showPopup('climatefriendly');
          }}
        >Klimafreundlicher reisen</button>
        NEU: Mit <span className="logo_inline" /> passende Urlaubsreisen finden,
        die alle Wünsche einer Familie erfüllen!
      </p>)}
    </Ticker>
  );
};

MyTicker.propTypes = {
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyTicker);
