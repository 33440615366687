import React from 'react';
import GoogleAnalytics from 'react-ga';
import buildQueryString from 'build-query-string';

const open = (url, params) => window.open(
  `${url}?${buildQueryString(params)}`,
  '_blank',
  'scrollbars=0,resizable=1,menubar=0,left=200,top=200,width=768,height=500,toolbar=0,status=0',
);

export default () => (
  <button
    type="button"
    onClick={() => {
      GoogleAnalytics.event({ category: 'Footer', action: 'Share', label: 'Twitter' });
      open('https://twitter.com/intent/tweet', {
        url: window.location.origin,
        text: `Holidayfind.de
  Schneller zum passenden Familien-Urlaub. Individuelle Urlaubssuche ganz nach Euren Interessen.`,
      })
    }}
  />
);
