import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import animation from './animation';
import categories from './categories';
import calendar from './options/calendar';
import person from './options/person';
import duration from './options/duration';
import budget from './options/budget';
import departures from './options/departures';
import countries from './countries';
import result from './result';
import hotel from './hotel';
import reviews from './reviews';
import wishlist from './wishlist';
import popup from './popup';
import logoHeader from "./logoHeader"
import cookiesStart from "./cookiesStart"


export default combineReducers({
  routing: routerReducer,
  animation,
  categories,
  calendar,
  person,
  duration,
  budget,
  departures,
  countries,
  result,
  hotel,
  reviews,
  wishlist,
  popup,
  logoHeader,
  cookiesStart,
});
