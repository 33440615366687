import React from 'react';

export default () => (
  <React.Fragment>
    <p>
      15.1 Diese Allgemeinen Geschäftsbedingungen sind abschließend für die Regelung der rechtlichen Beziehungen zwischen BLAUWEGE GMBH
      und dem Nutzer verantwortlich. Änderungen oder Ergänzungen dieser Allgemeinen Geschäftsbedingungen bedürfen zu ihrer Wirksamkeit
      der Schriftform, ebenso wie deren Aufhebung. BLAUWEGE GMBH behält sich das Recht vor, die AGB für die Zukunft jederzeit zu
      ändern. Es gelten jeweils die AGB zum Zeitpunkt des Vertragsabschlusses.
    </p>
    <p>
      15.2 Sollte eine Bestimmung dieser Allgemeinen Geschäftsbedingungen oder ein Teil einer solchen Bestimmung unwirksam sein oder
      werden, berührt dies nicht die Wirksamkeit dieser Allgemeinen Geschäftsbedingungen im Ganzen.
    </p>
  </React.Fragment>
);
