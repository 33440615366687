import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { show } from '../../actions/popup';

const Popup = styled.div`
  position: absolute;
  top: -20px;
  padding: 10px 12px;
  background: #fff;
  border: 1px solid #e30613;
  font-weight: normal;
  z-index: 10;
`;

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  showPopup: show,
}, dispatch);

const CountrySmall = ({
  id, name, count, leaves, isSelected, onChange, showPopup,
}) => {
  let timeoutId = null;
  const [isShowed, setIsShowed] = useState(false);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="country_block country_block_sm">
      <Popup style={{ display: isShowed ? 'block' : 'none' }}>
        Für die hell gekennzeichnet Länder wurden <b>keine Angebote</b> zu deinen Interessen gefunden.<br />
        <br />
        <b>Tipp:</b><br />
        Einfach Interessen oder/und
        Zeitraum*Budget <Link to="/urlaubsinteressen" style={{ textDecoration: 'underline' }}>hier</Link> modifizieren.
      </Popup>
      <input
        id={`country_${id}`}
        type="checkbox"
        checked={isSelected}
        onChange={() => {
          if (!count && !isSelected) {
            setIsShowed(true);
            timeoutId = setTimeout(() => setIsShowed(false), 5000);
          } else {
            onChange();
          }
        }}
      />
      <label htmlFor={`country_${id}`} className="checkbox_view" />
      <label htmlFor={`country_${id}`}>
        <span className="country_name">{name}</span>
      </label>
      <button
        type="button"
        className={`country_shamrocks country_shamrocks_${leaves}`}
        onClick={() => showPopup('climatemap')}
      />
    </div>
  );
};

CountrySmall.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  leaves: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CountrySmall);
