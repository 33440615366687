import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectCheckbox, unselectCheckbox, toggleCheckbox } from '../../../actions/departures';
import ListItem from './ListItem';

const mapStateToProps = state => ({
  selected: state.departures.selected,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  select: selectCheckbox,
  unselect: unselectCheckbox,
  toggle: toggleCheckbox,
}, dispatch);

class Category extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.isAllSelected = this.isAllSelected.bind(this);
    this.isSomeSelected = this.isSomeSelected.bind(this);
  }

  onChange() {
    const isAllSelected = this.isAllSelected();
    const { airports, select, unselect } = this.props;
    airports.forEach(({ id }) => {
      if (isAllSelected) unselect(id);
      else select(id);
    });
  }

  isAllSelected() {
    const { airports, selected } = this.props;
    return airports.reduce((allSelected, { id }) => (selected[id] ? allSelected : false), true);
  }

  isSomeSelected() {
    const { airports, selected } = this.props;
    return airports.reduce((allSelected, { id }) => (selected[id] ? true : allSelected), false);
  }

  render() {
    const {
      id, title, airports, selected, toggle,
    } = this.props;

    return (
      <div className="data_block">
        <button className="open_data_block_btn" onClick={this.onChange}>{title}</button>
        <div className="category_checkbox">
          <input
            readOnly
            id={`category_${id}`}
            type="checkbox"
            checked={this.isAllSelected()}
            onChange={this.onChange}
          />
          <label htmlFor={`category_${id}`} className="checkbox_view" />
        </div>
        <ul className="data_block_body">{airports.map(airport => (
          <ListItem
            key={airport.id}
            {...airport}
            isSelected={!!selected[airport.id]}
            select={toggle}
          />))}
        </ul>
        <div className="clear" />
      </div>
    );
  }
}

Category.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  airports: PropTypes.arrayOf(PropTypes.any),
  selected: PropTypes.objectOf(PropTypes.bool),
  select: PropTypes.func.isRequired,
  unselect: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
