import {
  select, fork, takeLatest, put, call,
} from 'redux-saga/effects';
import { RESTORE_SELECTION } from '../actions/restoreSelection';
import { CATEGORIES } from '../actions/categories';
import { CALENDAR } from '../actions/calendar';
import { PERSON } from '../actions/person';
import { DURATION } from '../actions/duration';
import { BUDGET } from '../actions/budget';
import { DEPARTURES } from '../actions/departures';
import { COUNTRIES } from '../actions/countries';
import { getSelectedMylikes } from '../selectors/categories';
import { getUserParams2 } from '../selectors/result';
import { loadCountries, prepareCountries, loadPreparedCountries } from '../utils/Api';

function* prepareList() {
  try {
    const { bufferid } = yield call(prepareCountries, {
      mylikes: (yield select(getSelectedMylikes)).map(({ id }) => id).join(','),
      reqmylikes: yield select(({ categories: { req } }) => req),
    });
    if (bufferid) {
      yield put({ type: CATEGORIES.PREPARE_DONE, bufferid });
    } else {
      yield put({ type: CATEGORIES.PREPARE_FAILED, message: 'error preparing countries' });
    }
  } catch (message) {
    yield put({ type: CATEGORIES.PREPARE_FAILED, message });
  }
}

function* loadList() {
  try {
    const { req, bufferid } = yield select(({ categories }) => categories);
    const {
      top8_countries, countries, selectionid, status, error,
    } = yield call(
      bufferid ? loadPreparedCountries : loadCountries,
      {
        ...(bufferid ? { bufferid } : {}),
        mylikes: (yield select(getSelectedMylikes)).map(({ id }) => id).join(','),
        ...(req ? { reqmylikes: req } : {}),
        ...yield select(getUserParams2),
      }
    );
    if (countries && status !== 'error') {
      yield put({
        type: COUNTRIES.LIST_DONE, list: countries, listTop: top8_countries, selectionid,
      });
    } else {
      yield put({ type: COUNTRIES.LIST_FAILED, message: error || 'error loading countries' });
    }
  } catch (message) {
    yield put({ type: COUNTRIES.LIST_FAILED, message });
  }
}

function* setOptionsChanged() {
  yield put({ type: COUNTRIES.SET_OPTIONS_CHANGED });
}

export default function* () {
  yield [
    fork(function* () { yield takeLatest(CATEGORIES.PREPARE, prepareList); }),
    fork(function* () { yield takeLatest(COUNTRIES.LIST, loadList); }),
    fork(function* () { yield takeLatest(RESTORE_SELECTION.LOAD_DONE, setOptionsChanged); }),
    fork(function* () { yield takeLatest(CATEGORIES.TOGGLE_CHECKBOX, setOptionsChanged); }),
    fork(function* () { yield takeLatest(CATEGORIES.SET_REQ, setOptionsChanged); }),
    fork(function* () { yield takeLatest(CALENDAR.TOGGLE_CHECKBOX_DONE, setOptionsChanged); }),
    fork(function* () { yield takeLatest(PERSON.SELECT_COUNTERS, setOptionsChanged); }),
    fork(function* () { yield takeLatest(DURATION.SELECT, setOptionsChanged); }),
    fork(function* () { yield takeLatest(BUDGET.TOGGLE_CHECKBOX, setOptionsChanged); }),
    fork(function* () { yield takeLatest(DEPARTURES.TOGGLE_CHECKBOX, setOptionsChanged); }),
    fork(function* () { yield takeLatest(DEPARTURES.SELECT_CHECKBOX, setOptionsChanged); }),
    fork(function* () { yield takeLatest(DEPARTURES.UNSELECT_CHECKBOX, setOptionsChanged); }),
  ];
}
