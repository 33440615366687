import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const mapStateToProps = ({ categories: { req } }) => ({
  req,
});

const ScoreBlock = ({ matchlist, mismatchlist, showScorePopup, req }) => {
  const orderedMathes = req
    ? matchlist
      .filter(({ id }) => id === req)
      .map(item => ({ ...item, isReq: true }))
      .concat(
        matchlist.filter(({ id }) => id !== req)
      )
    : matchlist;

  return (
    <div className="score_block">
      <p className="matches_interests_text">Übereinstimmungen mit euren Interessen:</p>
      <ul>{(orderedMathes.length > 5 ? orderedMathes.slice(0, 5) : orderedMathes).map(({ id, name, isReq }) =>
        <li key={id} className={isReq ? 'red' : ''}>{name}</li>)}
      </ul>
      <ul>{orderedMathes.length > 5 && orderedMathes.slice(5, 9).map(({ id, name }) =>
        <li key={id}>{name}</li>)}
        <li className="more">
          <button type="button" onClick={() => showScorePopup(matchlist, mismatchlist)}>Details / alle anzeigen</button>
        </li>
      </ul>
    </div>
  );
};

ScoreBlock.propTypes = {
  matchlist: PropTypes.arrayOf(PropTypes.object).isRequired,
  mismatchlist: PropTypes.arrayOf(PropTypes.object).isRequired,
  showScorePopup: PropTypes.func.isRequired,
  req: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(ScoreBlock);
