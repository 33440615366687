import { PERSON } from '../../actions/person';
import { RESTORE_SELECTION } from '../../actions/restoreSelection';
import { getPeople } from '../../utils/APIMapper';

// get array of selected duration from previous session
const storageKey = 'person';
const storage = localStorage.getItem(storageKey);

const defaultState = {
  counters: { 1: 'Erwachsene', 2: 'Kinder 2-11', 3: 'Kleinkinder <2' },
  sex: { 1: 'Mann', 2: 'Frau' },
  info: {
    1: 'mit Familie', 2: 'mit Partner', 3: 'mit Freunden', 4: 'mit Haustier',
  },
  selected: {
    counters: { 1: 2 },
    sex: {},
    info: {},
  },
};
if (storage) {
  // restore selected
  const storedJson = JSON.parse(storage);
  if (storedJson.counters && storedJson.counters.length) {
    storedJson.counters.forEach((value, i) => {
      defaultState.selected.counters[i + 1] = value;
    });
  }
  if (storedJson.sex && storedJson.sex.length) {
    defaultState.selected.sex[storedJson.sex[0]] = true;
  }
  if (storedJson.info && storedJson.info.length) {
    storedJson.info.forEach((key) => {
      defaultState.selected.info[key] = true;
    });
  }
}

export default (state = defaultState, { type, ...action }) => {
  const { selected } = state;
  const { id, value } = action;
  let params;
  let counters;

  switch (type) {
    case PERSON.SELECT_COUNTERS:
      return {
        ...state,
        selected: { ...selected, counters: { ...selected.counters, [id]: value } },
      };

    case RESTORE_SELECTION.LOAD_DONE:
      params = getPeople(action.params);
      if (!params || !params.persons) {
        return state;
      }
      counters = { 1: params.persons };
      if (params.teenager) counters[2] = params.teenager;
      if (params.kids) counters[3] = params.kids;
      localStorage.setItem(
        storageKey,
        JSON.stringify({ counters: Object.keys(counters).map(key => counters[key]) }),
      );
      return { ...state, selected: { ...selected, counters } };

    case PERSON.SELECT_SEX:
      return { ...state, selected: { ...selected, sex: { [id]: true } } };

    case PERSON.SELECT_INFO:
      return {
        ...state,
        selected: { ...selected, info: { ...selected.info, [id]: !selected.info[id] } },
      };

    case PERSON.SUBMIT:
      localStorage.setItem(
        storageKey,
        JSON.stringify({
          counters: Object.keys(selected.counters).map(key => selected.counters[key]),
          sex: Object.keys(selected.sex).filter(key => selected.sex[key]),
          info: Object.keys(selected.info).filter(key => selected.info[key]),
        }),
      );
      return state;

    default:
      return state;
  }
};
