import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import { show } from '../../actions/popup';

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  showPopup: show,
}, dispatch);

const Contact = ({ history, showPopup }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content">
      <Helmet>
        <title>Familienurlaub buchen: Ihr Kontakt zu uns | holidayfind.de</title>
        <meta name="description" content="Sie haben Fragen zu Ihrer Buchung oder zum Buchungsportal Holidayfind?
         Ihre Spezialisten für Familienurlaub helfen gern." />
        <meta name="keywords" content="holidayfind, holidayfind kontakt" />
      </Helmet>
      <div className="triple section_title_block section_title_block_landing">
        <button type="button" onClick={() => showPopup('bestprice')}>
          <img className="partner_link" src="images/partner_logo.svg" alt="Logo" />
        </button>
        <div className="family_top_block">
          <div className="family_img header_family_img"><img src="images/animation/family3.svg" /></div>
        </div>
        <div className="section_title">
          <h1>Kontakt</h1>
          <div className="clear" />
        </div>
        <div className="clear" />
      </div>
      <div className="clear" />
      <div className="text_content">
        <button type="button" className="f17 back_close_btn" onClick={history.goBack} />
        <div className="half_block">
          <h5 className="f20" style={{ minHeight: 50 }}><b>Bei Fragen und Feedback zum Portal oder zu Deiner Buchung/Reise:</b></h5>
          <div className="clear10" />
          <img src="/images/kontakt_160_220.jpg" className="img_left" style={{ maxHeight: 140 }} alt="Logo" />
          <p className="f18">Ruf uns an oder schreib eine E-Mail:</p>
          <p className="nt_mb f18">Telefon: 08847 6955994</p>
          <p className="nt_mb f18"><a href="mailto:reisen@holidayfind.de">reisen@holidayfind.de</a></p>
          <p>Mo. – So. 08.00 – 22.00 Uhr<br />24.12. und 31.12.: 09.00 – 13.00 Uhr</p>
        </div>
        <div className="half_block">
          <h5 className="f20" style={{ minHeight: 50 }}><b>Bei geschäftlichen Anfragen:</b></h5>
          <div className="clear10" />
          <img src="/images/klaus_ehrl_160_220.jpg" className="img_left" style={{ maxHeight: 140 }} alt="Logo" />
          <p className="nt_mb f18">Telefon: 08847 6955992</p>
          <p className="nt_mb f18"><a href="mailto:klaus.ehrl@holidayfind.de">klaus.ehrl@holidayfind.de</a></p>
          <p className="f18">Mo. – Fr. 08.00 und 18.00 Uhr</p>
        </div>
        <div className="clear20" />
      </div>
    </div>
  );
};

Contact.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
