import Moment from 'moment';
import { getSelectedAirports } from '../utils/helper';

export const getDate = ({ calendar }) => ({ from: calendar.from, to: calendar.to });
export const getPeople = ({ person }) => ({
  adult: person.selected.counters[1] || 0,
  child: person.selected.counters[2] || 0,
  baby: person.selected.counters[3] || 0,
});
export const getDuration = ({ duration }) => (
  Object.keys(duration.selected)[0]
    ? duration.list.find(item => item.id === +Object.keys(duration.selected)[0])
    : null
);
export const getBudget = ({ budget }) => (
  Object.keys(budget.selected)[0]
    ? budget.list.find(item => item.id === +Object.keys(budget.selected)[0])
    : null
);
export const getAirports = ({ departures }) => (
  getSelectedAirports(departures.selected).map(item => item.code).join(',')
);

const getSearchDate = (state) => {
  const { date, duration } = {
    date: getDate(state),
    duration: getDuration(state),
  };
  if (!duration || !date.from || !date.to) {
    return '';
  }

  const format = 'DD.MM.YYYY';
  const from = Moment(date.from);
  const to = Moment(date.to);
  const range = to.diff(from, 'days');
  const rangeStr = `${from.format(format)},${to.format(format)}`;

  const searchDate = [rangeStr];
  if (duration.getExact) {
    searchDate.push(duration.getExact(range));
    if (duration.getMin) {
      searchDate.push(duration.getMin(range));
      if (duration.getMax) {
        searchDate.push(duration.getMax(range));
      }
    }
  }
  return searchDate.join(',');
};


// For internal API call:
export const getCountryIds = ({ countries }) => (
  Object.keys(countries.selected).filter(key => countries.selected[key]).join(',')
);

export const isDeepDive = ({ countries: { listTop, list, selected } }) => (
  Object.keys(selected).length === 1
  && listTop.concat(list).find(({ id, count }) => selected[id] && count > 0)
);

// For external API call:
export const getUserParams = (state) => {
  const { adult, child, baby } = getPeople(state);
  const airports = getAirports(state);
  const userParams = {
    searchDate: getSearchDate(state),
    adults: adult,
  };
  if (airports) {
    userParams.departureAirportList = airports;
  }

  const children = [];
  for (let i = 0; i < child; i++) children.push(10); // pseudo random from 2 to 12
  for (let i = 0; i < baby; i++) children.push(1); // pseudo random from 0 to 2
  if (children.length) userParams.children = children.join(',');

  const { min, max } = getBudget(state);
  if (min) userParams.minPricePerPerson = min;
  if (max) userParams.maxPricePerPerson = max;

  return userParams;
};
const getFomattedDate = (date) => {
  let res = date.getFullYear();
  res += `0${date.getMonth() + 1}`.slice(-2);
  res += `0${date.getDate()}`.slice(-2);
  return res;
};
const getSearchDate2 = (state) => {
  const searchDate = {};
  const date = getDate(state);
  const duration = getDuration(state);
  if (date.from && date.to) {
    searchDate.begindate = getFomattedDate(date.from);
    searchDate.enddate = getFomattedDate(date.to);
    if (duration) {
      const dateRange = Math.round((date.to - date.from) / 86400000);
      searchDate.traveltime = duration.getExact(dateRange);
    }
  }
  return searchDate;
};
export const getUserParams2 = (state) => {
  const { adult, child, baby } = getPeople(state);
  const airports = getAirports(state);
  const userParams = {
    ...getSearchDate2(state),
    persons: adult,
  };
  if (airports) {
    userParams.airports = airports;
  }
  if (child) userParams.teenager = child; // from 2 to 12
  if (baby) userParams.kids = baby; // from 0 to 1

  const { min, max } = getBudget(state);
  // if (min) userParams.minPricePerPerson = min;
  if (max) userParams.budget = `till${max}`;

  return userParams;
};

export const getDetailedList = (result, hotel) => (
  result.map(item => ({ ...item, ...hotel[item.giata] }))
);

export const getSortedList = ({ categories, result: { list, queue, sortByGroup } }) => {
  const selectedCategory = categories.list.find(({ id }) => id === sortByGroup);
  if (!selectedCategory) return list.length > queue.length ? list : queue.slice(0, list.length);

  const primaryMylikes = [];
  selectedCategory.subcategories.forEach(({ id, subdetails }) => {
    if (categories.selected[id]) primaryMylikes.push(id);
    subdetails.forEach((subdetail) => {
      if (categories.selected[subdetail.id]) primaryMylikes.push(subdetail.id);
    });
  });

  const sortResults = (a, b) => {
    const aMatches = a.matchlist.filter(({ id }) => primaryMylikes.includes(id));
    const bMatches = b.matchlist.filter(({ id }) => primaryMylikes.includes(id));
    return aMatches.length > bMatches.length ? -1 : 1;
  };

  const sortedList = [...(list.length > queue.length ? list : queue)];
  sortedList.sort(sortResults);

  return list.length > queue.length
    ? sortedList
    : sortedList.slice(0, list.length);
};
