import { RESULT } from '../actions/result';
import { getItem } from '../utils/ResultMapper';

const defaultState = {
  sortByGroup: 0,
  list: [],
  queue: [],
  page: 1,
  selectioncode: '',
  isLoading: false,
  listFinished: false,
  isCancelled: false,
  lastActivity: 0,
};

export default (state = defaultState, { type, ...action }) => {
  const lastActivity = Math.round(new Date().getTime() / 1000 / 60); // min

  switch (type) {
    case RESULT.SORT_BY_GROUP:
      return { ...state, sortByGroup: action.groupId };

    case RESULT.SORT_BY_GROUP_DONE:
      return { ...state, list: action.list };

    case RESULT.LIST:
      return {
        ...state, lastActivity, isLoading: true, isCancelled: false,
      };

    case RESULT.LIST_CANCEL:
      return { ...state, lastActivity: defaultState.lastActivity, isCancelled: true };

    case RESULT.LIST_STARTED: // increment page for next call
      return { ...state, page: state.page + 1 };

    case RESULT.LIST_DONE:
      return {
        ...state,
        lastActivity,
        list: state.list.concat(action.portion.map(({
          giata, item, offer, tourOperatorList,
        }) => getItem({
          giata, item, offer, tourOperatorList,
        }))),
        selectioncode: action.selectioncode,
        isLoading: false,
      };

    case RESULT.QUEUE_DONE:
      return {
        ...state,
        queue: state.queue.concat(action.queue.map(({
          giata, item, offer, tourOperatorList,
        }) => getItem({
          giata, item, offer, tourOperatorList,
        }))),
      };

    case RESULT.LOAD_SELECTION_CODE:
      return { ...state, selectioncode: '' };

    case RESULT.LOAD_SELECTION_CODE_DONE:
      return { ...state, selectioncode: action.selectioncode };

    case RESULT.LIST_FAILED:
      console.log(action.message || 'network error');
      return {
        ...state, lastActivity: defaultState.lastActivity, listFinished: true, isLoading: false,
      };

    case RESULT.RESET:
      return { ...defaultState };

    default:
      return state;
  }
};
