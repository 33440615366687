import React, { useState } from 'react';
import PropTypes from 'prop-types';

const PopupWelcome = ({ button }) => {
  const [isShowed, setIsShowed] = useState(true);

  return (
    <div className={`popup${isShowed ? '' : ' hidden'}`}>
      <div className="popup_block_welcome">
        <h2>Willkommen bei <span className="logo_inline" />!</h2>
        <button type="button" className="close_btn" onClick={() => setIsShowed(false)} />
        <div className="popup_body">
          <div>Wir haben soeben die gespeicherten Interessen und Reisedaten aufgerufen.
            <br />
            Da sich Reiseangebote laufend verändern, steig einfach bei der Ländersuche wieder ein.
          </div>
          <div className="submit_btn">{button}</div>
        </div>
      </div>
    </div>
  );
};

PopupWelcome.propTypes = {
  button: PropTypes.objectOf(PropTypes.any),
};

export default PopupWelcome;
