import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import ImageSlider from './ImageSlider';
import Ratings from './Ratings';
import Price from './Price';

const CarouselItem = ({
  giata, selectioncode, name, location, stars, images, matchlist, mismatchlist, ratings,
  personPrice, duration, tourOperator, showScorePopup, greenRating,
}) => (
  <div className="item" style={{ margin: 0, boxShadow: '0 0 0 1px #31b5a9' }}>
    <Header name={name} location={location} stars={stars} giata={giata} greenRating={greenRating} />
    <div className="img_block">{images && <ImageSlider images={images} />}</div>
    <div className="btns_block">
      <a
        className="book_now"
        href={`/reisebuchung?g=${giata}&s=${selectioncode}`}
        target="_blank"
        rel="noopener noreferrer"
      >Mehr Infos
      </a>
    </div>
    <div className="clear" />{ratings.holidaycheck || ratings.tripadvisor
      ? <Ratings {...ratings} />
      : <div className="clear45" />}
    <div className="clear" />
    <div>
      <button
        className="percent"
        type="button"
        onClick={() => showScorePopup(matchlist, mismatchlist)}
      >{matchlist.length}
      </button>
      <p className="matches_header" style={{ height: 30 }}><b>Top-Treffer:</b></p>
      <div className="clear10" />
      <div className="score_block">
        <p className="matches_interests_text">Übereinstimmungen mit euren Interessen:</p>
        {matchlist.length < 6 ? (
        <React.Fragment>
          <ul>{matchlist.map(item => <li key={item.id}>{item.name}</li>)}</ul>
          <ul>
            <li className="more">
              <button type="button" onClick={() => showScorePopup(matchlist, mismatchlist)}>Details / alle anzeigen</button>
            </li>
          </ul>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ul>{matchlist.slice(0, 5).map(item => <li key={item.id}>{item.name}</li>)}</ul>
          <ul>{matchlist.slice(5, 9).map(item => <li key={item.id}>{item.name}</li>)}
            <li className="more">
              <button type="button" onClick={() => showScorePopup(matchlist, mismatchlist)}>Details / alle anzeigen</button>
            </li>
          </ul>
        </React.Fragment>)}
      </div>
      <Price
        giata={giata}
        selectioncode={selectioncode}
        personPrice={personPrice}
        duration={duration}
        tourOperator={tourOperator}
      />
    </div>
    <div className="clear" />
  </div>
);

CarouselItem.propTypes = {
  giata: PropTypes.string.isRequired,
  selectioncode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  stars: PropTypes.arrayOf(PropTypes.bool),
  images: PropTypes.arrayOf(PropTypes.object),
  matchlist: PropTypes.arrayOf(PropTypes.object),
  mismatchlist: PropTypes.arrayOf(PropTypes.object),
  ratings: PropTypes.objectOf(PropTypes.any),
  personPrice: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  tourOperator: PropTypes.string.isRequired,
  showScorePopup: PropTypes.func.isRequired,
};

export default CarouselItem;
