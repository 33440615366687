import React from 'react';
import BottomPopupTemplate from '../_BottomPopupTemplate';

export default ({ delay, onClose }) => (
  <BottomPopupTemplate delay={delay} onClose={onClose}>
    <div style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
      <img style={{ width: 60, marginRight: 5 }} src="/images/kontakt_160_220.jpg" />
      <div>
        <div style={{ marginTop: 10 }}>Tipp:</div>
        <div style={{ marginTop: 14, marginBottom: -10, color: '#e30613' }}>alternativ nach</div>
      </div>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      Kategorien sortieren
      <div
        style={{
          width: 31,
          height: 47,
          padding: '8px 0',
          backgroundColor: '#fff',
          marginRight: -16,
          marginBottom: -8,
        }}
      >
        <div style={{ height: 31, border: '1px solid #0c3f6c' }}>
          <span className="select_interests" style={{ padding: '10px 18px 10px 18px', border: 'none' }} />
        </div>
      </div>
    </div>
  </BottomPopupTemplate>
);
