export const getBudget = ({ budget }) => {
  if (!budget) return undefined;

  if (budget.includes('till')) {
    return { max: +budget.replace('till', '') };
  }
  if (budget.includes('from')) {
    return { min: +budget.replace('from', '') };
  }
  return undefined;
};

export const getCalendar = ({ begindate, enddate }) => {
  if (!begindate || !enddate) return undefined;
  const getDateFromString = str => new Date(
    Date.UTC(+str.slice(0, 4), +str.slice(4, 6) - 1, +str.slice(6, 8)),
  );
  return { from: getDateFromString(begindate), to: getDateFromString(enddate) };
};

export const getCountries = ({ country }) => (country ? country.split(',') : undefined);

export const getDepartures = ({ airports }) => (airports ? airports.split(',') : undefined);

export const getDuration = ({ traveltime }) => (traveltime ? +traveltime : undefined);

export const getPeople = ({ persons, teenager, kids }) => {
  if (!persons) return undefined;
  const people = { persons: +persons };
  if (teenager) people.teenager = +teenager;
  if (kids) people.kids = +kids;
  return people;
};

export default params => ({
  budget: getBudget(params),
  calendar: getCalendar(params),
  countries: getCountries(params),
  departures: getDepartures(params),
  duration: getDuration(params),
  person: getPeople(params),
});
