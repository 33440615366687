import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

const Percent = ({ location: { pathname } }) => {
  const media = useMediaQuery("(max-width: 828px)")
  if ('/reisebuchung' === pathname) {
    return null;
  }

  return (
    <div id='percent'>
      <div className="strip" />
      <div style={{ fontWeight: 'normal' }}><span style={{ color: '#fff' }}>Heute</span> bis zu</div>
      <div style={{ fontSize: 26 }}>52 %</div>
      <div style={{ marginTop: 2, lineHeight: 1 }}>Frühbucher Rabatt</div>
      {/* <a
        href='https://holidayfind.de/individual/allgemeine-geschaeftsbedingungen#prüfung_18'
        target='_blank'
      >
        <img src='/images/Button-KI.svg'/>
      </a> */}
    </div>
  );
};

Percent.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withRouter(Percent);
