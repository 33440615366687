import React from 'react';
import GoogleAnalytics from 'react-ga';

export default () => (
  <button
    type="button"
    onClick={() => {
      GoogleAnalytics.event({ category: 'Footer', action: 'Share', label: 'Facebook' });
      if (!window.FB) {
        console.log('FB is not defined');
        return;
      }
      window.FB.ui({
        method: 'share',
        href: window.location.origin,
      });
    }}
  />
);
