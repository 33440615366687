import React from 'react';
import BottomPopupTemplate from '../_BottomPopupTemplate';
import styled from 'styled-components';

const Div1 = styled.div`
  display: flex;
  align-items: center;

  img { width: 60px; margin-right: 5px; }
  .line1 { color: #e30613; }
  .line2 { margin-top: -3px; }
  .line2 span { color: #e30613; }
`;

const Div2 = styled.div`
  display: flex;
  align-items: center;
  justify-сontent: space-between;

  .checkbox_wrapper {
    width: 45px;
    height: 61px;
    padding: 6px;
    margin: 0 -16px -8px 6px;
    background-color: #26ba99;
    border-top: 8px solid #fff;
    border-bottom: 8px solid #fff;
  }
`;

export default () => (
  <BottomPopupTemplate>
    <Div1>
      <img src="/images/kontakt_160_220.jpg" />
      <div>
        <div className="line1">Jetzt</div>
        <div className="line2">weiter <span>konfigurieren:</span></div>
      </div>
    </Div1>
    <Div2>
      z.B.Reiseziele reduzieren
      <div className="checkbox_wrapper">
        <div className="checkbox_view" />
      </div>
    </Div2>
  </BottomPopupTemplate>
);
