import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { remove } from '../../../actions/wishlist';
import { show } from '../../../actions/popup';

const mapStateToProps = ({ wishlist }, { giata }) => ({
  selectioncode: wishlist.list.find(item => item.giata === giata).selectioncode,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  removeItem: remove,
  showPopup: show,
}, dispatch);

const Header = ({
  name, location, stars, giata, selectioncode, removeItem, showPopup, greenRating,
}) => (
  <header>
    <div className="fl">
      <p className="title">
        {name}
        <span className="hotel_stars">{stars.map((item, i) => (
          <FontAwesomeIcon key={i} icon={item ? faStar : faStarHalf} size="xs" />))}
        </span>
      </p>
      <div className="clear" />
      <p className="second_title">{location}</p>
    </div>
    {greenRating !== undefined && (
      <button
        type="button"
        className="results_shamrock results_shamrock_0"
        onClick={() => showPopup('climatehotel')}
      />
    )}
    <button type="button" className="hotel_wish active" onClick={() => removeItem(giata, selectioncode)} />
    <div className="clear" />
  </header>
);

Header.propTypes = {
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  stars: PropTypes.arrayOf(PropTypes.bool),
  giata: PropTypes.string.isRequired,
  selectioncode: PropTypes.string.isRequired,
  removeItem: PropTypes.func.isRequired,
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
