import { DEPARTURES } from '../../actions/departures';
import { RESTORE_SELECTION } from '../../actions/restoreSelection';
import { getDepartures } from '../../utils/APIMapper';
import { categories } from '../../assets/data/departures.json';

const excludeId = (obj, id) => Object.keys(obj)
  .filter(key => +key !== id)
  .reduce((res, key) => ({ ...res, [key]: true }), {});

// get array of selected categories from previous session
const storageKey = 'departures';
const storage = localStorage.getItem(storageKey);

const defaultState = {
  isNewUser: true,
  list: categories,
  selected: {},
};
if (storage) {
  defaultState.isNewUser = false;
  // restore selected
  JSON.parse(storage).forEach((id) => { defaultState.selected[id] = true; });
}

export default (state = defaultState, { type, ...action }) => {
  let codes;
  let newSelected;

  switch (type) {
    case DEPARTURES.TOGGLE_CHECKBOX:
      return {
        ...state,
        isNewUser: false,
        selected: state.selected[action.id]
          ? { ...excludeId(state.selected, action.id) }
          : { ...state.selected, [action.id]: true },
      };

    case DEPARTURES.SELECT_CHECKBOX:
      return { ...state, isNewUser: false, selected: { ...state.selected, [action.id]: true } };

    case DEPARTURES.UNSELECT_CHECKBOX:
      return { ...state, isNewUser: false, selected: excludeId(state.selected, action.id) };

    case DEPARTURES.UNSELECT_ALL:
      return { ...state, isNewUser: false, selected: {} };

    case RESTORE_SELECTION.LOAD_DONE:
      codes = getDepartures(action.params);
      if (!codes) {
        return state;
      }
      newSelected = {};
      state.list.forEach(({ airports }) => {
        airports.forEach(({ id, code }) => {
          if (codes.includes(code)) newSelected[id] = true;
        });
      });
      localStorage.setItem(
        storageKey,
        JSON.stringify(Object.keys(newSelected).map(key => +key)),
      );
      return { ...state, isNewUser: false, selected: newSelected };

    case DEPARTURES.SUBMIT:
      localStorage.setItem(
        storageKey,
        JSON.stringify(Object.keys(state.selected).map(key => +key)),
      );
      return state;

    default:
      return state;
  }
};
