import { REVIEWS } from '../actions/reviews';

const defaultState = {};

export default (state = defaultState, { type, ...action }) => {
  const { giata, list, message } = action;

  switch (type) {
    case REVIEWS.LOAD_DONE:
      return { ...state, [giata]: list };

    case REVIEWS.LOAD_FAILED:
      console.log(message || 'network error');
      return { ...state, [giata]: [] };

    default:
      return state;
  }
};
