export const getSelectedMylikes = ({ categories: { list, selected } }) => {
  const mylikes = [];
  list.forEach((category) => {
    category.subcategories.forEach((subcategory) => {
      if (selected[subcategory.id]) {
        mylikes.push({ id: subcategory.id, name: subcategory.name });
      }
      subcategory.subdetails.forEach((subdetail) => {
        if (selected[subdetail.id]) {
          mylikes.push({ id: subdetail.id, name: subdetail.name });
        }
      });
    });
  });

  return mylikes;
};

export const getSelectedGroups = ({ categories: { list, selected } }) => {
  const groups = {};
  list.forEach((category) => {
    category.subcategories.forEach((subcategory) => {
      if (selected[subcategory.id]) {
        groups[category.id] = category.name;
      }
      subcategory.subdetails.forEach((subdetail) => {
        if (selected[subdetail.id]) {
          groups[category.id] = category.name;
        }
      });
    });
  });

  return Object.entries(groups).map(([id, name]) => ({ id: +id, name }));
};

export default {};
