import { RESULT } from '../actions/result';
import { getHotel } from '../utils/ResultMapper';

const defaultState = {};

export default (state = defaultState, { type, ...action }) => {
  switch (type) {
    case RESULT.LIST_DONE:
      return {
        ...state,
        ...action.portion.reduce((res, { giata, item, hotel }) => (
          { ...res, [giata]: getHotel({ item, hotel }) }
        ), {}),
      };

    case RESULT.QUEUE_DONE:
      return {
        ...state,
        ...action.queue.reduce((res, { giata, item, hotel }) => (
          { ...res, [giata]: getHotel({ item, hotel }) }
        ), {}),
      };

    default:
      return state;
  }
};
