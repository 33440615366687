import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import {
  getAirports, getBudget, getDate, getDuration, getPeople, getDetailedList,
} from '../../selectors/result';
import Header from './Header';
import Details from './Details';

const mapStateToProps = (state, ownProps) => {
  const list = getDetailedList(state.result.list, state.hotel);
  return {
    list,
    selectioncode: state.result.selectioncode,
    ibeParams: {
      tourOperatorList: (list.find(({ giata }) => giata === ownProps.giata) || {}).tourOperatorList,
      airports: getAirports(state),
      budget: getBudget(state),
      date: getDate(state),
      duration: getDuration(state),
      people: getPeople(state),
    },
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

class Book extends React.Component {
  constructor(props) {
    super(props);

    const { list, giata } = props;
    this.state = {
      item: list.find(item => giata === item.giata),
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(item) {
    this.setState({ item });
  }

  render() {
    const { item } = this.state;
    const { list, selectioncode, ibeParams } = this.props;
    if (!item) {
      return <Redirect to="/unsere-angebote" />;
    }

    return (
      <div className="content">
        <Header list={list} item={item} onChange={this.onChange} />
        <div className="clear" />
        <Details item={item} selectioncode={selectioncode} ibeParams={ibeParams} />
      </div>
    );
  }
}

Book.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  ibeParams: PropTypes.objectOf(PropTypes.any),
  giata: PropTypes.string.isRequired,
  selectioncode: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Book);
