import React from 'react';

export default () => (
  <React.Fragment>
    <p>
      Bei Streitigkeiten, die im Zusammenhang mit der Vermittlertätigkeit von BLAUWEGE GMBH gegenüber einem Verbraucher im Rahmen eines
      Vertragsabschlusses stehen, weisen wir darauf hin, dass wir weder bereit noch verpflichtet sind, an Streitbeilegungsverfahren vor
      einer Verbraucherschlichtungsstelle teilzunehmen. Sollten sich hingegen die Streitigkeiten, die im Zusammenhang mit der
      Vermittlungstätigkeit von BLAUWEGE GMBH gegenüber einem Verbraucher im Rahmen eines Vertragsabschlusses auf die Bereiche
      Versicherungen und/oder Finanzdienstleistungen beziehen, besteht auf Antrag die Möglichkeit der außergerichtlichen Streitschlichtung
      bei dem Versicherungsombudsmann e.V. oder dem OMBUDSMANN Private Kranken- und Pflegeversicherung
    </p>
    <p>
      Anschriften der außergerichtlichen Streitschlichtungsstellen:
    </p>
    <ul className="list_style_none">
      <li>Versicherungsombudsmann e. V.</li>
      <li>Postfach 080632</li>
      <li>10006 Berlin</li>
      <li>Telefon: 030 2060 58-0</li>
      <li>E-Mail: <a href="mailto:beschwerde@versicherungsombudsmann.de">beschwerde@versicherungsombudsmann.de</a></li>
      <li>Internet: <a href="www.versicherungsombudsmann.de" target="_blank">www.versicherungsombudsmann.de</a></li>
    </ul>
    <ul className="list_style_none">
      <li>OMBUDSMANN Private Kranken- und Pflegeversicherung</li>
      <li>Postfach 060222</li>
      <li>10052 Berlin </li>
      <li>Telefon: 0800 2550 444 (kostenfrei aus deutschen Telefonnetzen) </li>
      <li>Telefax: 030 2045 8931</li>
      <li>E-Mail: <a href="mailto:ombudsmann@pkv-ombudsmann.de">ombudsmann@pkv-ombudsmann.de</a></li>
      <li>Internet: <a href="www.pkv-ombudsmann.de" target="_blank">www.pkv-ombudsmann.de</a></li>
    </ul>
  </React.Fragment>
);
