import React from "react";
// import { useHistory } from "react-router-dom";

import "./chooseSearch.module.css";

import { individual_link, classic_link } from "../../../config";

export default function ChooseSearch() {
  return (
    <>
      <div className="select_search">
        <a href="https://holidayfind.de/individual/allgemeine-geschaeftsbedingungen#prüfung_18" target="_blank">
          <img className="crowd-investing-mobile" style={{ width: "142px" }} src="/images/Button-KI.svg" />
        </a>
        <div className="select">
          <img
            src="/images/Button-Start_v2.svg"
            onClick={() => {
              location.href = classic_link;
            }}
          />
          <p>
            Du nennst uns – wie üblich – dein Reiseziel und wir zeigen <b>alle verfügbaren Angebote</b> an.
          </p>
        </div>
        <div className="select">
          <img
            src="/images/Button-Los-gehts_v2.svg"
            onClick={() => {
              location.href = "https://holidayfind.de/individual";
            }}
          />
          <p>
            Du wählst deine Reisewünsche/-interessen – und wir finden <b>optimal passende Reisen</b> für dich.
          </p>
        </div>
      </div>
    </>
  );
}
