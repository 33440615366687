import React from 'react';
import types from '../../assets/data/types';

class Home extends React.Component {
  componentDidMount() {
    document.getElementById('ship_block').classList.add('hidden');
    document.getElementById('main').classList.add('home_main');
  }

  componentWillUnmount() {
    document.getElementById('ship_block').classList.remove('hidden');
    document.getElementById('main').classList.remove('home_main');
  }

  render() {
    return (
      <div className="content homepage">{types.map(({ id, slug, title }) => (
        <a href={`//${slug}.holidayfind.de`} key={id} className={`bottom_btns categories_btns ${slug}_block`}>
          <span href={`//${slug}.holidayfind.de`} className="landing_btn btn">{title}</span>
        </a>))}
      </div>
    );
  }
}
export default (Home);
