import React from 'react';
import PropTypes from 'prop-types';

const ListItem = ({
  id, name, isSelected, select,
}) => (
  <li>
    <input
      id={`budget_${id}`}
      type="radio"
      checked={isSelected}
      onChange={() => { select(id); }}
    />
    <label htmlFor={`budget_${id}`}><span>{name}</span><i className="list_checked" /></label>
  </li>
);

ListItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired,
};

export default ListItem;
