import React from 'react';
import GoogleAnalytics from 'react-ga';

const getEmailSubject = () => encodeURIComponent(`Betreff: Individueller Familien-Urlaub`);
const getEmailBody = () => encodeURIComponent(`Hallo...

Hier ein interessanter Link: ${window.location.origin}
Dieses Reiseportal hat eine einmalige Suchfunktion zum Finden eines passenden ${
  'Familien'
}-Urlaubs - es geht von individuellen Urlaubsinteressen aus.

Herzlichst`);

export default () => (
  <a
    href={`mailto:?subject=${getEmailSubject()}&body=${getEmailBody()}`}
    onClick={() => GoogleAnalytics.event({ category: 'Footer', action: 'Share', label: 'Email' })}
  >
    <button type="button" />
  </a>
);
