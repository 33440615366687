import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { load, cancel } from '../../actions/result';
import {
  getDate, getPeople, getDuration, getBudget, getCountryIds, getDetailedList, isDeepDive,
} from '../../selectors/result';
import '../../assets/css/image-gallery.css';
import Item from './Item';
import Video from './Item/Video';
import AdditionalButton from './Item/AdditionalButton';
import Title from './Title';
import Spinner from '../Spinner';
import PopupScore from './Popup/Score';
import PopupNoResult from './Popup/NoResult';
import PopupNotActive30 from './Popup/NotActive30';

const Buttons = styled.div`
  margin-top: 15px;

  .btn {
    float: none;
    margin: 10px auto;
    display: block;
    font-weight: 700;
  }

  @media(min-width: 900px) {
    display: flex;
    justify-content: space-around;
  }
`;

const mapStateToProps = state => {
  const date = getDate(state);
  return ({
    list: getDetailedList(state.result.list, state.hotel),
    listFinished: state.result.listFinished,
    isLoading: state.result.isLoading,
    isDD: isDeepDive(state),
    valid: getCountryIds(state) && date.from && date.to && getPeople(state).adult
      && getDuration(state) && getBudget(state) ? true : false,
  });
};
const mapDispatchToProps = dispatch => bindActionCreators({
  loadResults: load,
  cancelLoading: cancel,
}, dispatch);

const Result = ({ valid, isDD, list, isLoading, listFinished, loadResults, cancelLoading }) => {
  if (!valid)
    return <Redirect to="/reiselaender" />;

  const [isScorePopupShowed, setIsScorePopupShowed] = useState(false);
  const [popupDetails, setPopupDetails] = useState({ matchlist: [], mismatchlist: [] });
  const loadPortion = () => {
    if (!listFinished)
      loadResults();
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!list.length)
      loadPortion();

    return cancelLoading; // to prevent requests when user gone
  }, []);

  if (!list.length && !listFinished)
    return (
      <Spinner showLogo>
        <div>Wir checken aktuell Tausende von Hotels + 33 Veranstalter.</div>
        <div>{isDD
          ? 'Die Suche in nur einem Land kann bis zu 30 Sekunden dauern...'
          : 'Der Vorgang kann bis zu 15 Sekunden dauern...'
        }</div>
      </Spinner>
    );

  return (
    <div className="content">
      <Helmet>
        <title>Der Traumurlaub für die ganze Familie | holidayfind.de</title>
        <meta name="description" content="Entspannt am Meer oder aktiv unterwegs: holidayfind.de findet die
         perfekten Angebote für einen Traumurlaub, der alle Wünsche Ihrer Familie erfüllt." />
        <meta name="keywords" content="traumurlaub buchen, urlaub am meer" />
      </Helmet>
      <PopupNotActive30 />
      <Title count={list.length} reload={loadPortion} />
      <div className="results_block">{list.map((item, index) => (
        <React.Fragment key={item.giata}>
          <Item
            index={index}
            {...item}
            showScorePopup={(matchlist, mismatchlist) => {
              setPopupDetails({ matchlist, mismatchlist });
              setIsScorePopupShowed(true);
            }}
          />
          {index === 2 && (
            <div className="result_additional_text">
              Bitte beachten: Die Veranstalter-Preise können sich je nach
              Nachfrage kurzfristig ändern – wir bitten um Verständnis.
            </div>)}
          {index === 3 && <Video id="322216494" />}
        </React.Fragment>))}{(
          list.length === 2
          || ((list.length + 1) % 3 !== 0 && list.length !== 3) // !3 && !5,!8,!11,!14
        ) && <AdditionalButton />}
        <div className="clear" />
      </div>
      <div className="clear5" />
      {listFinished && (
        <React.Fragment>
          <div style={{ textAlign: 'center' }}>Keine weiteren Ergebnisse gefunden</div>
          <Buttons>
            <Link to="/urlaubsinteressen" className="btn">Interessen modifizieren</Link>
            <Link to="/reisedaten" className="btn">Zeitraum/Budget modifizieren</Link>
          </Buttons>
        </React.Fragment>
      )}
      <div className="bottom_btns">
        <p className="comment">&#160;</p>
        {!listFinished && (isLoading
          ? <div className="loading">Weitere Urlaubsvorschläge werden geladen...</div>
          : <button type="button" className="btn" onClick={loadPortion}>Weitere Ergebnisse</button>
        )}
        <p className="tr"><Link className="f17 back_btn" to="/reiselaender">zurück</Link></p>
        <div className="clear" />
      </div>
      <div className={`popup${isScorePopupShowed ? '' : ' hidden'}`}>
        <div className="popup_block">
          <button
            type="button"
            className="close_btn"
            onClick={() => setIsScorePopupShowed(false)}
          />
          <PopupScore {...popupDetails} />
        </div>
      </div>
      <div className={`popup${list.length ? ' hidden' : ''}`}><PopupNoResult /></div>
    </div>
  );
};

Result.propTypes = {
  valid: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(PropTypes.object),
  listFinished: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadResults: PropTypes.func.isRequired,
  cancelLoading: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Result);
