import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import { show } from '../../actions/popup';

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  showPopup: show,
}, dispatch);

const About = ({ history, showPopup }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content">
      <Helmet>
        <title>Ihre Spezialisten für Familienurlaub | holidayfind.de</title>
        <meta name="description" content="Familienurlaub nach Maß: holidayfind.de ist die Online-Reisesuche,
         die alle Wünsche Ihrer Familie bei der Suche einbezieht." />
        <meta name="keywords" content="holidayfind, familienurlaub passend" />
      </Helmet>
      <div className="triple section_title_block section_title_block_landing">
        <button type="button" onClick={() => showPopup('bestprice')}>
          <img className="partner_link" src="/images/partner_logo.svg" alt="Logo" />
        </button>
        <div className="family_top_block">
          <div className="family_img header_family_img"><img src="images/animation/family3.svg" /></div>
        </div>
        <div className="section_title">
          <h1>Über uns</h1>
          <div className="clear" />
          <h5><span className="logo_inline logo_inline_sm" /> unterstützt <b>atmosfair</b> und andere NGOs jährlich mit 10% vom Überschuss.</h5>
        </div>
        <div className="clear" />
      </div>
      <div className="clear" />
      <div className="text_content">
        <button type="button" className="f17 back_close_btn" onClick={history.goBack} />
        <h4 className="static_header">Mit holidayfind.de schneller zum individuell passenden und klimafreundlicheren Familienurlaub.</h4>
        <div className="clear20"></div>
        <figure className="half_block fr">
          <p><img src="/images/about_us_family.jpg" className="img_left"/></p>
          <figcaption>Klaus Ehrl und Philippe Gessner – Gründer von holidayfind.de</figcaption>
        </figure>
        <p>Wer kennt das nicht? Das Suchen, Vergleichen und Buchen einer Reise nimmt fast mehr Zeit in Anspruch als die Reise selbst. Erst recht wenn man nach einer klimafreundlicheren Alternative Ausschau hält.</p>
        <p>Besonders beim Familienurlaub gibt es oft eine Vielzahl unterschiedlicher Interessen. Deshalb ist es gar nicht so einfach Reisevorschläge zu finden, die ganz auf die persönlichen Bedürfnisse und Wünsche aller zugeschnitten ist. Das wollen wir ändern!</p>
        <p className="nt_mb"><b>NEU: holidayfind.de ist die erste Online-Reisesuche, die sich konsequent an euren Interessen zur Reise orientiert.</b></p>
        <p>Mit unserer einzigartigen Suchtechnologie finden wir in kürzester Zeit aus Millionen Möglichkeiten genau das Urlaubsangebot heraus, das möglichst optimal zu euren Bedürfnissen passt, aktuell buchbar ist – und das zu eurem Budget. Auch um Thema Klimafreundlichkeit geben wir – ganz neu - eine Reihe praktischer Hinweise.</p>
        <p className="nt_mb">Noch etwas:</p>
        <p>Wir fragen vor einer eventuellen Buchung niemals Daten (auch nicht die E-Mail-Adresse) ab. Das heißt: Ihr könnt jederzeit anonym bei uns stöbern. Weitere Infos zu unserem Datenschutz findet Ihr <a href="/datenschutz"><u>hier</u></a>.</p>
        <div className="clear20"></div>
      </div>
    </div>
  );
};

About.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  showPopup: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
