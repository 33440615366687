import { POPUP } from '../actions/popup';

const defaultState = {
  name: '',
};

export default (state = defaultState, { type, ...action }) => {
  switch (type) {
    case POPUP.SHOW:
      return { ...state, name: action.name };

    case POPUP.HIDE:
      return { ...state, name: '' };

    default:
      return state;
  }
};
