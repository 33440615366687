import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Header from './Header';
import ImageSlider from './ImageSlider';
import Ratings from './Ratings';
import Price from './Price';
import ScoreBlock from './ScoreBlock';

const CarouselItem = ({
  giata, name, location, stars, images, matchlist, mismatchlist, ratings, greenRating,
  personPrice, oldPrice, duration, tourOperator, showScorePopup,
}) => (
  <div className="item">
    <Header name={name} location={location} stars={stars} giata={giata} greenRating={greenRating} />
    <div className="img_block">{images && <ImageSlider images={images} />}</div>
    <div className="btns_block">
      <Link to={{ pathname: '/reisebuchung', giata }} className="book_now">Mehr Infos</Link>
    </div>
    <div className="clear" />{ratings.holidaycheck || ratings.tripadvisor
      ? <Ratings {...ratings} />
      : <div className="clear45" />}
    <div className="clear" />
    <div>
      <button
        className="percent"
        type="button"
        onClick={() => showScorePopup(matchlist, mismatchlist)}
      >{matchlist.length}
      </button>
      <p className="matches_header"><b>Top-Treffer:</b></p>
      <div className="clear10" />
      <ScoreBlock matchlist={matchlist} mismatchlist={mismatchlist} showScorePopup={showScorePopup} />
      <Price
        giata={giata}
        personPrice={personPrice}
        oldPrice={oldPrice}
        duration={duration}
        tourOperator={tourOperator}
      />
      <div className="clear" />
    </div>
    <div className="clear" />
  </div>
);

CarouselItem.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  stars: PropTypes.arrayOf(PropTypes.bool),
  images: PropTypes.arrayOf(PropTypes.object),
  matchlist: PropTypes.arrayOf(PropTypes.object),
  mismatchlist: PropTypes.arrayOf(PropTypes.object),
  ratings: PropTypes.objectOf(PropTypes.any),
  personPrice: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  tourOperator: PropTypes.string.isRequired,
  showScorePopup: PropTypes.func.isRequired,
};

export default CarouselItem;
