import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const BottomPopupTemplate = ({ children, delay = 3, duration = 8, cbStyle = {}, onClose = () => {} }) => {
  const [isShowed, setIsShowed] = useState(delay === 0);

  if (duration !== 0) {
    useEffect(() => {
      const timeoutShowId = setTimeout(() => setIsShowed(true), delay * 1000);
      const timeoutHideId = setTimeout(() => setIsShowed(false), (delay + duration) * 1000);

      return () => {
        clearTimeout(timeoutShowId);
        clearTimeout(timeoutHideId);
      };
    }, []);
  }

  if (!isShowed) {
    return null;
  }

  return (
    <div id="bottom-popup">
      <div>
        <button
          type="button"
          className="close_btn"
          onClick={() => {
            onClose();
            setIsShowed(false);
          }}
          style={cbStyle}
        />
        {children}
      </div>
    </div>
  );
};

BottomPopupTemplate.propTypes = {
  delay: PropTypes.number,
  duration: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BottomPopupTemplate;
