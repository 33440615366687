import React from 'react';
import PropTypes from 'prop-types';

const ListItem = ({
  id, name, isSelected, select,
}) => (
  <li>
    <input
      readOnly
      id={`departure_${id}`}
      type="checkbox"
      checked={isSelected}
      onChange={() => { select(id); }}
    />
    <label htmlFor={`departure_${id}`} className="checkbox_view" />
    <label htmlFor={`departure_${id}`}><span>{name}</span></label>
  </li>
);

ListItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired,
};

export default ListItem;
