import { LOGO_HEADER } from "../actions/logoHeader";

const defaultState = "oldLogo";

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case LOGO_HEADER.CHANGE:
      return payload;

    default:
      return state;
  }
};
