import React from 'react';
import PropTypes from 'prop-types';

const Ratings = ({ holidaycheck, tripadvisor }) => (
  <div className="raiting_block">
    <span className="like_btn" />
    <div className="holid_check">
      {holidaycheck && <p>HolidayCheck <b>{holidaycheck}</b></p>}
      {tripadvisor && <p>Tripadvisor <b>{tripadvisor}</b></p>}
    </div>
  </div>
);

Ratings.propTypes = {
  holidaycheck: PropTypes.string.isRequired,
  tripadvisor: PropTypes.string,
};

export default Ratings;
