import React from 'react';

const imageClass = 'popup_family';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.delay = 5;

    this.state = {
      isShowed: false,
    };

    this.listen = this.listen.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      window.addEventListener('mouseout', this.listen, false);
    }, this.delay * 1000);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseout', this.listen, false);
  }

  listen({
    target, clientX, clientY, relatedTarget, toElement,
  }) {
    // If this is an autocomplete element
    if (target.tagName.toLowerCase() === 'input') return;

    // If the current mouse X position is within 50px of the right edge of the viewport, return
    const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (clientX >= viewportWidth - 50) return;

    // If the current mouse Y position is not within 50px of the top edge of the viewport, return
    if (clientY >= 50) return;

    // Reliable, works on mouse exiting window and user switching active program
    if (!relatedTarget && !toElement) {
      this.setState({ isShowed: true });
      window.removeEventListener('mouseout', this.listen, false);
    }
  }

  close() {
    this.setState({ isShowed: false });
  }

  render() {
    const { isShowed } = this.state;

    if (!isShowed) return null;

    return (
      <div className="popup exit_popup">
        <div className="popup_block">
          <button type="button" className="close_btn" onClick={this.close} />
          <div className="popup_content tc">
            <div className="popup_body">
              <div className={imageClass} />
              <h1>Bevor du abtauchst und woanders schaust:</h1>
              <p className="nt_mb">Die Preise der Veranstalter sind laut Gesetz (bei detailgleichen Reisen)
                überall gleich - nur der individuelle Such-Service ist unterschiedlich.
              </p>
              <p>
                <b>Wo findest du Urlaubsvorschläge,
                  die sich so leidenschaftlich an deinen Interessen orientieren?
                </b>
              </p>
              <div className="clear20" />
              <p>Nur bei <span className="logo_inline" /></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
