import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Rating from './Rating';

const Review = ({
  author, age, title, travelDate, rating,
  textLocation, textRoom, textService, textFood, textHotel,
}) => (
  <div className="singl_review">
    <div className="review_title_block">
      <p className="review_title">{title}</p>
      <p className="review_age">{author} (<span>{age}</span>)</p>
      <p className="review_date">{`Verreist als Paar - ${
        Moment(travelDate, 'YYYY-MM-DD').locale('de').format('MMMM YYYY')
      }`}
      </p>
    </div>
    <table className="review_table">
      <tbody>
        <Rating title="GESAMTBEWERTUNG" number={rating.overall} />
        <Rating title="LAGE" number={rating.location} comment={textLocation} />
        <Rating title="ZIMMER" number={rating.room} comment={textRoom} />
        <Rating title="SERVICE" number={rating.service} comment={textService} />
        <Rating title="GASTRONOMIE" number={rating.food} comment={textFood} />
        <Rating title="HOTEL" number={rating.hotel} comment={textHotel} />
      </tbody>
    </table>
    <div className="clear h_line" />
  </div>
);

Review.propTypes = {
  author: PropTypes.string.isRequired,
  age: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  travelDate: PropTypes.string.isRequired,
  rating: PropTypes.objectOf(PropTypes.any),
  textLocation: PropTypes.string,
  textRoom: PropTypes.string,
  textService: PropTypes.string,
  textFood: PropTypes.string,
  textHotel: PropTypes.string,
};

export default Review;
