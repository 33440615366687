export default [
  { code: 'FLYD', origin: '1-2-FLY', short: '1-2-FLY' },
  { code: '5VF', origin: '5vorFlug', short: '5vorFlug' },
  { code: 'AIR', origin: 'air marin', short: 'air marin' },
  { code: 'ATID', origin: 'Airtours', short: 'Airtours' },
  { code: 'ALD', origin: 'Aldiana', short: 'Aldiana' },
  { code: 'ALL', origin: 'alltours', short: 'alltours' },
  { code: 'XALL', origin: 'alltours-x', short: 'alltours-x' },
  { code: 'AME', origin: 'Ameropa', short: 'Ameropa' },
  { code: 'ATK', origin: 'Attika Reisen', short: 'Attika Reisen' },
  { code: 'BAV', origin: 'Bavaria Fernreisen', short: 'Bavaria Reise' }, // diff
  { code: 'BIG', origin: 'BigXtra Touristik', short: 'BigXtra' }, // diff
  { code: 'BXCH', origin: 'BigXtra Touristik CH', short: 'BigXtra' }, // diff
  { code: 'XBIG', origin: 'BigXtra Touristik XBIG', short: 'BigXtra' }, // diff
  { code: 'BU', origin: 'Bucher Reisen', short: 'Bucher' }, // diff
  { code: 'CBM', origin: 'Club Blaues Meer', short: 'Blaues Meer' }, // diff
  { code: 'DANS', origin: 'dansommer', short: 'dansommer' },
  { code: 'DER', origin: 'DERTOUR', short: 'DERTOUR' },
  { code: 'ECC', origin: 'Ecco-Reisen', short: 'Ecco-Reisen' },
  { code: 'FER', origin: 'FERIEN Touristik', short: 'FERIEN TOUR' }, // diff
  { code: 'FIT', origin: 'FIT Reisen', short: 'FIT Reisen' },
  { code: 'FOR', origin: 'For You Travel', short: 'ForYouTravel' }, // diff
  { code: 'FTI', origin: 'FTI Touristik', short: 'FTI Touristik' },
  { code: 'XFTI', origin: 'FTI Touristik XFTI', short: 'FTI Touristik' }, // diff
  { code: 'IHOM', origin: 'Interhome', short: 'Interhome' },
  { code: 'ITS', origin: 'ITS', short: 'ITS' },
  { code: 'ITSB', origin: 'ITS Billa', short: 'ITS Billa' },
  { code: 'ITSX', origin: 'ITS Indi', short: 'ITS Indi' },
  { code: 'JAHN', origin: 'Jahn Reisen', short: 'Jahn Reisen' },
  { code: 'JAHA', origin: 'Jahn Reisen Österreich', short: 'Jahn Reisen' }, // diff
  { code: 'LMX', origin: 'LMX Touristik', short: 'LMX' }, // diff
  { code: 'MLA', origin: 'maltadirekt', short: 'maltadirekt' },
  { code: 'MED', origin: 'Medina Reisen', short: 'Medina' }, // diff
  { code: 'MPR', origin: 'Mediplus Reisen', short: 'Mediplus' }, // diff
  { code: 'MON', origin: 'Mondial', short: 'Mondial' },
  { code: 'NEC', origin: 'Neckermann Reisen', short: 'Neckermann' }, // diff
  { code: 'NOSO', origin: 'Novasol A/S', short: 'Novasol' }, // diff
  { code: 'OGE', origin: 'Öger Tours', short: 'Öger Tours' },
  { code: 'OLI', origin: 'OLIMAR Reisen', short: 'OLIMAR Reisen' },
  { code: 'PHX', origin: 'Phoenix Reisen', short: 'Phoenix' }, // diff
  { code: 'RIVA', origin: 'Riva Tours', short: 'Riva Tours' },
  { code: 'SLR', origin: 'schauinsland reisen', short: 'schauinsland' }, // diff
  { code: 'SLRD', origin: 'schauinsland reisen dynamisch', short: 'schauinsland' }, // diff
  { code: 'TOC', origin: 'Thomas Cook', short: 'Thomas Cook' },
  { code: 'TOR', origin: 'Thomas Cook Reisen Individual', short: 'Thomas Cook' }, // diff
  { code: 'TUID', origin: 'TUI', short: 'TUI' },
  { code: 'VTO', origin: 'Vtours', short: 'Vtours' },
];
