import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SubSubCategory from './SubSubCategory';
import { toggleCheckbox } from '../../actions/categories';

const mapStateToProps = ({ categories: { selected } }, { id }) => ({
  isSelected: !!selected[id],
});
const mapDispatchToProps = dispatch => bindActionCreators({
  toggleInput: toggleCheckbox,
}, dispatch);

const SubCategory = ({ id, name, subdetails, isSelected, toggleInput }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const htmlId = `angeln_${id}`;
  const toggle = () => setIsExpanded(!isExpanded);

  return (
    <div className={`parameters_item${isExpanded ? ' show_subcategory' : ''}`}>
      <div className="checkbox">
        <input
          id={htmlId}
          type="checkbox"
          checked={isSelected}
          onChange={() => {
            toggleInput(id);
            if (subdetails.length && !isExpanded) toggle();
          }}
        />
        <label htmlFor={htmlId} className="checkbox_view" />
        <label htmlFor={htmlId} className={name.length > 20 ? 'label_line active' : 'label_line'}>
          <span>{name}</span>
        </label>
        {!!subdetails.length && (
          <button
            type="button"
            className={isExpanded ? 'close_additional' : 'open_additional'}
            onClick={toggle}
          />)}
      </div>
      <div className="clear10" />
      {!!subdetails.length && (
        <div className={isExpanded ? '' : 'hidden'}>
          <div className="additional_body">
            <ul>{subdetails.map(sub => (
              <SubSubCategory key={sub.id} {...sub} toggle={toggleInput} />))}
            </ul>
            <div className="clear" />
          </div>
          <div className="clear10" />
        </div>)}
    </div>
  );
};

SubCategory.propTypes = {
  toggleInput: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  subdetails: PropTypes.arrayOf(PropTypes.any),
};

export default connect(mapStateToProps, mapDispatchToProps)(SubCategory);
