import { fork } from 'redux-saga/effects';
import calendar from './calendar';
import countries from './countries';
import result from './result';
import reviews from './reviews';
import restoreSelection from './restoreSelection';
import wishlist from './wishlist';

export default function* rootSaga() {
  yield [
    calendar,
    countries,
    result,
    reviews,
    restoreSelection,
    wishlist,
  ].map(saga => fork(saga));
}
