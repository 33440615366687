import React from 'react';
import PropTypes from 'prop-types';

const AcceptButton = ({ onAccept }) => (
  <div className="data_list_action tc">
    <button className="btn apply_bnt" onClick={onAccept}>
      <span>Anwenden</span>
      <i className="list_checked" />
    </button>
  </div>
);

AcceptButton.propTypes = {
  onAccept: PropTypes.func.isRequired,
};

export default AcceptButton;
