import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleCounters } from '../../../actions/person';

const mapStateToProps = (state, ownProps) => ({
  title: state.person.counters[ownProps.id],
  value: state.person.selected.counters[ownProps.id] || 0,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  onChange: toggleCounters,
}, dispatch);

const Counter = ({
  id, title, value, onChange,
}) => (
  <tr>
    <td>{title}</td>
    <td><button className="count_minus" onClick={() => { onChange(id, Math.max(0, value - 1)); }}>-</button></td>
    <td><input readOnly type="text" className="number_input" value={value} /></td>
    <td><button className="count_plus" onClick={() => { onChange(id, value + 1); }}>+</button></td>
  </tr>
);

Counter.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Counter);
