import React from 'react';
import PropTypes from 'prop-types';

const activeHotel = 'Eure Auswahl';

const CarouselItem = ({ location }) => (
  <div className="slider_thubnails slider_book">
    <div className="owl-item">
      <div>
        <button type="button" className="favorit active" style={{ float: 'none', margin: '0 auto', width: 'auto' }}>
          <p className="day_favorit">{activeHotel}</p>
          <p className="active_hotel_location"><b>{location}</b></p>
        </button>
      </div>
    </div>
  </div>
);

CarouselItem.propTypes = {
  location: PropTypes.string.isRequired,
};

export default CarouselItem;
