import React from 'react';
import PropTypes from 'prop-types';

const ScorePopup = ({ matchlist, mismatchlist }) => (
  <React.Fragment>
    <div className="popup_content">
      <div className="popup_header">
        <h1 className="matches_header">
          <span className="red">{matchlist.length}</span> Top-Treffer / Übereinstimmungen
          mit deinen Interessen/Wünschen
        </h1>
      </div>
      <div className="popup_body">
        <ul className="interests">{matchlist.map(({ id, name }) => <li key={id}>{name}</li>)}</ul>
        <span className="approve_label">Budget & Reisezeit geprüft</span>
        <div className="clear h_line" />
        <ul className="not_interests">{mismatchlist.map(({ id, name }) => <li key={id}>{name}</li>)}</ul>
        <div className="clear h_line" />
        <p className="f13 lght nt_mb">Die Informationen beruhen auf einer der aktuellsten unud umfangreichsten
          Tourismusdatenbanken der Welt. Bitte haben Sie jedoch Verständnis, dass wir keine
          Garantie geben können für die den Hotels und der jeweiligen Umgebung zugeschriebenen
          Interessensangeboten – dazu ändert sich ständig zu viel und die Quellen können nur auf
          Plausibilität geprüft werden. Dennoch haben Sie mit dem Service von holidayfind.de
          einen noch nie dagewesenen Service für Ihre möglichst punktgenaue Urlaubsauswahl.
        </p>
      </div>
    </div>
  </React.Fragment>
);

ScorePopup.propTypes = {
  matchlist: PropTypes.arrayOf(PropTypes.object),
  mismatchlist: PropTypes.arrayOf(PropTypes.object),
};

export default ScorePopup;
