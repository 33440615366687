export const COUNTRIES = {
  LIST: 'COUNTRIES_LIST',
  LIST_DONE: 'COUNTRIES_LIST_DONE',
  LIST_FAILED: 'COUNTRIES_LIST_FAILED',
  SELECT_ALL: 'COUNTRIES_SELECT_ALL',
  UNSELECT_ALL: 'COUNTRIES_UNSELECT_ALL',
  TOGGLE_CHECKBOX: 'COUNTRIES_TOGGLE_CHECKBOX',
  SUBMIT: 'COUNTRIES_SUBMIT',
  SET_OPTIONS_CHANGED: 'COUNTRIES_OPTIONS_CHANGED',
};

export const load = () => ({ type: COUNTRIES.LIST });
export const selectAll = () => ({ type: COUNTRIES.SELECT_ALL });
export const unselectAll = () => ({ type: COUNTRIES.UNSELECT_ALL });
export const toggleCheckbox = id => ({ type: COUNTRIES.TOGGLE_CHECKBOX, id });
