import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Single from './Single';
import Carousel from './Carousel';

const Book = ({ list, item, onChange }) => (
  <React.Fragment>
    <p className="fr nt_mb mobile_show"><Link className="f17 back_btn" to="/unsere-angebote">zurück</Link></p>
    <div className="clear10 mobile_show" />
    {list.length < 4
      ? (
        list.length === 1
          ? <Single location={item.location} />
          : <Carousel list={list} item={item} onChange={onChange} /> // TODO: show without carousel
      )
      : <Carousel list={list} item={item} onChange={onChange} />}
    </React.Fragment>
);

Book.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  item: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
};

export default Book;
