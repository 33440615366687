import React from 'react';

export default ({ isShown, onClose }) => (isShown ? (
  <div className="popup">
    <div className="popup_block">
      <button type="button" className="close_btn" onClick={onClose} />
      <div className="popup_content" style={{ overflow: 'unset' }}>
        <div className="popup_body tl">
          <h1>Hotels und Ressorts: Nachhaltiges Wirtschaften im Fokus</h1>
          <div className="climate_hotel_left">
            <div className="clear20" />
            <p className="f16">
              Immer mehr Hotels und Ressorts achten immer öfter darauf, besonders nachhaltig und umweltbewusst zu wirtschaften.
              Für ihre Umweltbilanz kann beispielsweise ausschlaggebend sein, ob eine Unterkunft zentral gelegen ist und vieles
              am Urlaubsort mit öffentlichen Verkehrsmitteln erreichbar ist.
            </p>
            <p className="f16">
              Viele Unterkünfte sind bereits umweltzertifiziert, andere haben ihren Ressourcen-Einsatz nachweisbar optimiert,
              beispielsweise im Energie-, im Nutz- Trinkwasser- und im Abfall- Management, im Einkauf und in der Verwendung
              von Lebensmitteln oder mit der Auswahl umweltfreundlicher Putzmittel.
            </p>
            <p className="f16">
              <span className="logo_inline" style={{ marginLeft: -2 }} />
              <b>hilft beim Finden umweltfreundlicher Unterkünfte.</b>
              <br />Während des Auswahl- und Buchungsverfahrens kannst du sehen, welche Hotels/Ressorts schon heute
              Nachhaltigkeitskriterien beachten. Die Kennzeichnungen:
            </p>
            <div style={{ fontWeight: 300 }}>
              <ul style={{ listStyle: 'none', marginLeft: -2, marginRight: 40 }}>
                <li style={{ marginBottom: 7 }}>
                  <i className="icon_shamrock icon_shamrock_4" /> GSTC anerkannte Nachhaltigkeitslabel
                </li>
                <li style={{ marginBottom: 7 }}>
                  <i className="icon_shamrock icon_shamrock_3" /> Internationale - /Nationale Label
                </li>
                <li style={{ marginBottom: 7 }}>
                  <i className="icon_shamrock icon_shamrock_2" /> auf einem nachhaltigen Weg
                </li>
              </ul>
              <ul style={{ listStyle: 'none', marginLeft: -2 }}>
                <li style={{ marginBottom: 7 }}>
                  <i className="icon_shamrock icon_shamrock_1" /> eventuell nachhaltig (z.B. neu/renoviert)
                </li>
                <li>
                  <i className="icon_shamrock icon_shamrock_0" /> keine Infos vorliegend
                </li>
              </ul>
            </div>
          </div>
          <div className="climate_hotel_right">
            <p className="f13 tr nt_mb">Beispiele nachhaltigen Wirtschaftens:</p>
            <img src="images/climate_hotel.svg" />
            <div className="clear" />
          </div>
        </div>
      </div>
    </div>
  </div>
) : null);
