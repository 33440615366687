import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Counter from './Counter';

const mapStateToProps = state => ({
  counters: state.person.counters,
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const Counters = ({ counters }) => (
  <table className="count_table">
    <tbody>{Object.keys(counters).map(key => (
      <Counter key={key} id={key} />))}
    </tbody>
  </table>
);

Counters.propTypes = {
  counters: PropTypes.objectOf(PropTypes.string),
};

export default connect(mapStateToProps, mapDispatchToProps)(Counters);
