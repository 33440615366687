import React from 'react';
import PropTypes from 'prop-types';

class Info extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowed: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { isShowed } = this.state;
    this.setState({ isShowed: !isShowed });
  }

  render() {
    const { isShowed } = this.state;
    const { hotel, html, name } = this.props;

    return (
      <React.Fragment>
        <button
          type="button"
          className="hotel_info"
          onClick={this.toggle}
        >HOTELINFOS
        </button>{isShowed && (
        <div className="popup">
          <div className="hotel_info_block">
            <button type="button" className="close_block_btn" onClick={this.toggle} />
            <div>
              <h1>{name}</h1>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </div>)}
      </React.Fragment>
    );
  }
}

Info.propTypes = {
  hotel: PropTypes.objectOf(PropTypes.string),
  html: PropTypes.string.isRequired,
};

export default Info;
