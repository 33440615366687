import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { Redirect } from 'react-router-dom';
import Details from './Details';
import { loadGiata, loadOffers } from '../../utils/Api';
import APIMapper from '../../utils/APIMapper';
import ResultMapper from '../../utils/ResultMapper';

const getSearchDate = ({ calendar: date, duration }) => {
  if (!duration || !date || !date.from || !date.to) return '';
  const format = 'DD.MM.YYYY';
  const from = Moment(date.from);
  const to = Moment(date.to);
  return `${from.format(format)},${to.format(format)},${duration}`;
};

class Dynamic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { giata, selectioncode } = this.props;
    loadGiata(giata, selectioncode).then((item) => {
      if (!item.giata) {
        console.log('giata does not loaded');
        return;
      }
      const selectedParams = APIMapper(item.params);
      const userParams = {
        giataIdList: giata,
        searchDate: getSearchDate(selectedParams),
        ...(selectedParams.departures ? { departureAirportList: selectedParams.departures } : {}),
      };
      let people = {};
      const { person } = selectedParams;
      if (person) {
        people = {
          adult: person.persons,
          child: person.teenager || 0,
          baby: person.kids || 0,
        };
        userParams.adults = people.adult;
        const children = [];
        for (let i = 0; i < people.child; i++) children.push(10); // pseudo random from 2 to 12
        for (let i = 0; i < people.baby; i++) children.push(1); // pseudo random from 0 to 2
        if (children.length) userParams.children = children.join(',');
      }

      const { min, max } = selectedParams.budget;
      if (min) userParams.minPricePerPerson = min;
      if (max) {
        userParams.maxPricePerPerson = max;
      }

      loadOffers(userParams).then(({
        offer, tourOperatorList, hotel, error,
      }) => {
        if (!offer || !hotel) {
          console.log('offer does not loaded', error);
          return;
        }

        const mappedItem = ResultMapper({
          giata, item, offer, tourOperatorList, hotel,
        });
        console.log(mappedItem);
        this.setState({
          item: mappedItem,
          ibeParams: {
            tourOperatorList: mappedItem.tourOperatorList,
            ...(selectedParams.departures ? { airports: selectedParams.departures } : {}),
            budget: selectedParams.budget,
            date: selectedParams.calendar,
            duration: { getExact: () => selectedParams.duration },
            people,
          },
        });
      });
    });
  }

  render() {
    const { item, ibeParams } = this.state;

    return (
      <div className="content">
        {/* NOTE: we don't pass selectioncode because we can't store hotel into wishlist */}
        {item
          ? <Details item={item} selectioncode="" ibeParams={ibeParams} />
          : <div style={{ textAlign: 'center' }}>Loading...</div>}
      </div>
    );
  }
}

Dynamic.propTypes = {
  giata: PropTypes.string.isRequired,
  selectioncode: PropTypes.string.isRequired,
};

export default Dynamic;
