import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

const Sidebar = ({ location: { pathname } }) => {
  if (['/', '/reisebuchung'].includes(pathname)) {
    return null;
  }

  const [isShowed, setIsShowed] = useState(window.innerWidth > 1330);
  const handleResize = ({ target: { innerWidth } }) => setIsShowed(innerWidth > 1330);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div id="links_block" className={`links_block${isShowed ? ' show' : ''}`}>
      <div className="links_block_body">
        <h6>Unsere starken Partner:</h6>
        <div className="links_block_slide">
          <div className="links_block_link">
            <img src="/images/links/logo_1.jpg" alt="Logo" />
          </div>
          <div className="links_block_link">
            <img src="/images/links/logo_2.jpg" alt="Logo" />
          </div>
          <div className="links_block_link">
            <img src="/images/links/logo_3.jpg" alt="Logo" />
          </div>
          <div className="clear" />
        </div>
      </div>
      <button type="button" className="links_block_event" />
    </div>
  );
};

Sidebar.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
};

export default withRouter(Sidebar);
