import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const mapStateToProps = ({ categories: { selected } }, { id }) => ({
  isSelected: !!selected[id],
});

const SubSubCategory = ({
  id, name, isSelected, toggle,
}) => (
  <li>
    <div className="checkbox">
      <input
        id={`tauchkurs_${id}`}
        type="checkbox"
        checked={isSelected}
        onChange={() => toggle(id)}
      />
      <label htmlFor={`tauchkurs_${id}`} className="checkbox_view" />
      <label htmlFor={`tauchkurs_${id}`}>{name}</label>
    </div>
  </li>
);

SubSubCategory.propTypes = {
  toggle: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(SubSubCategory);
