import React from 'react';

export default ({ isShown, onClose }) => (isShown ? (
  <div className="popup">
    <div className="popup_block">
      <button type="button" className="close_btn" onClick={onClose} />
      <div className="popup_content" style={{ overflow: 'unset' }}>
        <div className="popup_body tl">
          <div className="best_price_popup_icon" />
          <div className="best_price_popup_text">
            <h1><span className="logo_inline logo_inline_lg" />Garantie</h1>
            <div className="clear40" />
            <p><b>Wir zahlen dir den Differenzbetrag, wenn du am Buchungstag ein bei uns gebuchtes Pauschalreise-Angebot mit identischer Leistung vom selben Veranstalter preisgünstiger über ein anderes Reisebüro oder Reiseportal buchen kannst.</b></p>
            <p className="bright_green"><b>Maßgeblich ist der in der Buchungsmaschine vom Veranstalter ausgewiesene buchbare Preis.</b></p>
            <p>Kurzfristige Veranstalter-Preissenkungen nach Buchung oder unrechtmäßig gewährte Rabatte sind von der Preisgarantie ausgeschlossen.</p>
            <div className="clear" />
          </div>
          <div className="clear h_line" />
          <div className="clear20" />
          <div id="percent-popup">
            <div className="strip" />
            <div style={{ fontWeight: 'normal' }}><span style={{ color: '#fff' }}>Heute</span> bis zu</div>
            <div style={{ position: 'relative', zIndex: 1, fontSize: 39 }}>52 %</div>
            <div style={{ position: 'relative', zIndex: 1, marginTop: 2, lineHeight: 1 }}>Frühbucher Rabatt</div>
          </div>
          <div style={{ marginLeft: 112 }}>
            <p className="nt_mb green"><b>So einfach geht’s</b></p>
            <p>Solltest du ein besseres Angebot finden, sende uns deine Buchungsbestätigung und die Informationen zum günstigeren Angebot direkt an uns. Nenne uns auch das Reisebüro/Reiseportal, bei dem du dasselbe Angebot zum günstigeren Preis gesehen hast und hättest buchen können. Nach Prüfung geben wir dir umgehend Antwort und ggf. eine entsprechende Gutschrift!</p>
            <p className="nt_mb"><b>Die Kontaktadresse für die Preisgarantie:</b> <a href="mailto:besterpreis@holidayfind.de">besterpreis@holidayfind.de</a></p>
          </div>
        </div>
        <div className="clear" />
      </div>
    </div>
  </div>
) : null);
