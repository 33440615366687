import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 4px;
  padding: 5px;
  color: #fff;
  font-size: 20px;
  line-height: 1.1;
  text-align: left;
  background: #8ccc03;
  transform: rotate(-5deg);
  z-index: 999;
  display: none;

  @media(max-width: 640px) {
    display: block;
  }

  div {
    padding: 2px 5px 5px 5px;
    border: 5px solid #fff;
    background: #8ccc03;
  }
  img {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
  }
  span {
    font-size: 10px;
    display: block;
    color: #000;
  }
`;

export default () => (
  <Wrapper>
    <div>
      <Link to="/allgemeine-geschaeftsbedingungen#coronavirus">
        <img src="/images/logo_top.svg" alt="Logo" />
      </Link>
      Storno-Garantie
      <span>auch gültig bis kurz vor Reiseantritt</span>
      wegen des
      <br />
      Coronavirus
    </div>
  </Wrapper>
);
