export const COOKIES_SETTINGS = {
  SOME_CHECKED: "COOKIES_SETTINGS_SOME",
  ALL_CHECKED: "COOKIES_SETTINGS_ALL",
};

export const setSomeCookies = (setting) => ({
  type: COOKIES_SETTINGS.SOME_CHECKED,
  setting,
});

export const setAllCookies = () => ({ type: COOKIES_SETTINGS.ALL_CHECKED });
