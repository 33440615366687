import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, withRouter } from "react-router-dom";
import withTracker from "../utils/withTracker";
import { getUrlParams } from "../utils/helper";

import "../assets/css/font.css";
import "../assets/css/style.css";
import "../assets/css/popup.css";
import "../assets/css/media.css";

import Header from "./_Header";
import Sidebar from "./_Sidebar";
import AtmosfairBar from "./_AtmosfairBar";
import Percent from "./_Percent";
import Popups from "./_Popups";
import Footer from "./_Footer";
import Debug from "./_Debug";

import Home from "./Home";
import Categories from "./Categories";
import Options from "./Options";
import Result from "./Result";
import Contact from "./Contact";
import Imprint from "./Imprint";
import About from "./About";
import Agb from "./Agb";
import Datenschutz from "./Datenschutz";
import Protection from "./Protection";
import NotFound from "./NotFound";
import Countries from "./Countries";
import Spinner from "./Spinner";
// import Intro from './Intro';
// import Animation from './Animation';

import NewStart from "./NewStart"


import Book from "./Book";
import RestoreSelection from "./RestoreSelection";

const Index = ({ location: { pathname, search } }) => (
  <div
    id="screen_block"
    className={`screen_block${pathname === "/" ? " screen_animation" : ""}`}
  >
    <Header key={`header_${pathname}`} />
    <div id="main" className="main">
      <Switch>
        <Route exact path="/" component={withTracker(NewStart)} />       
{/*         
        <Route
          exact
          path="/urlaubsinteressen"
          component={withTracker(Categories)}
        />
        <Route exact path="/reisedaten" component={withTracker(Options)} />
        <Route exact path="/reiselaender" component={withTracker(Countries)} />
        <Route exact path="/unsere-angebote" component={withTracker(Result)} />
        <Route exact path="/reisebuchung" component={withTracker(Book)} /> */}
        <Route exact path="/s" component={withTracker(RestoreSelection)} />
        <Route exact path="/kontakt" component={withTracker(Contact)} />
        <Route exact path="/impressum" component={withTracker(Imprint)} />
        <Route exact path="/ueber-uns" component={withTracker(About)} />
        <Route
          exact
          path="/allgemeine-geschaeftsbedingungen"
          component={withTracker(Agb)}
        />
        <Route exact path="/umweltschutz" component={withTracker(Protection)} />
        <Route exact path="/datenschutz" component={withTracker(Datenschutz)} />
        <Route exact path="/spinner" component={Spinner} />
        <Route exact path="/home" component={withTracker(Home)} />
        <Route component={withTracker(NotFound)} />
      </Switch>
    </div>
    {!["/"].includes(pathname) && <Footer />}
    <Sidebar />
    {pathname === "/reisebuchung" && <AtmosfairBar key="atmosfairbar" />}
    <Percent key={`percent_${pathname}`} />
    <Popups />
    {/* <div id="ship_block" className="ship_block">
      <div className="content">
        <div className="ship" />
      </div>
    </div> */}
    {getUrlParams(search).debug === "y" && <Debug />}
  </div>
);

Index.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
};

export default withRouter(Index);
