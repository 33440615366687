import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import GoogleAnalytics from 'react-ga';

export default () => {
    const [isShowed, setIsShowed] = useState(false);

    return (
      <React.Fragment>
        <button
          type="button"
          className="call_btn"
          onClick={() => {
            GoogleAnalytics.event({ category: 'Header', action: 'Headset', label: 'Open' });
            setIsShowed(prev => !prev);
          }}
        />
        <div id="call_block" className={`call_block${isShowed ? ' show' : ''}`}>
          <Link
            to="/kontakt"
            className="nav_btn nav_btn_mob"
            onClick={() => GoogleAnalytics.event({ category: 'Header', action: 'Link', label: 'Contact' })}
          >Hilfe & Kontakt</Link>
          <button
            type="button"
            className="close_block_btn"
            onClick={() => {
              GoogleAnalytics.event({ category: 'Header', action: 'Headset', label: 'Close' });
              setIsShowed(prev => !prev);
            }}
          />
          <h4>Bei Fragen rund ums Portal oder zu Deiner Buchung/Reise</h4>
          <div className="clear" />
          <img src="/images/manager_01.png" className="img_left nt_mb" style={{maxHeight: '160px'}} />
          <div className="fl">
            <p className="nt_mb">ruf uns an:</p>
            <a href="tel:088476955994">08847 6955994</a>
            <div className="clear10" />
            <p className="nt_mb">Mo. – So. 08.00 – 22.00 Uhr</p>
            <p />
            <p>Am 24.12. und 31.12.:<br /> 09.00 – 13.00 Uhr</p>
          </div>
        </div>
      </React.Fragment>
    );
};
