import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Price = ({
  giata, personPrice, oldPrice, duration, tourOperator,
}) => (
  <div className="price_block">
    {oldPrice && (
      <div className="early_price">
        <div className="red_line"><span /></div>
        <div className="price">ab {oldPrice}<span>&euro;</span></div>
      </div>
    )}
    <Link to={{ pathname: '/reisebuchung', giata }} style={{ color: '#fff' }}>
      <div className="price_block_top">
        <span className="ab">ab</span>
        <p className="price">{personPrice}<span>&euro;</span></p>
        <div className="clear" />
        <p className="days">p.P. für {duration} Tage</p>
      </div>
      <p className="price_description">{tourOperator}</p>
    </Link>
  </div>
);

Price.propTypes = {
  giata: PropTypes.string.isRequired,
  personPrice: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  tourOperator: PropTypes.string.isRequired,
};

export default Price;
