import React from 'react';

export default () => (
  <div
    style={{
      margin: '25px 25px 16px',
      color: '#0c3f6c',
      fontFamily: '\'Encode Sans Condensed\', sans-serif',
      fontSize: 17,
      fontWeight: 'normal',
      lineHeight: '25px',
    }}
  >
    <h2
      style={{
        margin: 0,
        fontFamily: 'inherit', /* override main h2 */
        fontSize: 26,
        lineHeight: '28px',
      }}
    >Dein Merkzettel ist noch leer ...
    </h2>
    <div style={{ marginTop: 11 }}>
      Mit dem <i
        className="wish_list"
        style={{
          width: 48,
          height: 48,
          display: 'inline-block',
          verticalAlign: 'middle',
        }}
      /> - Symbol kannst du favorisierte Hotels in deinen persönlichen Merkzettel aufnehmen.
    </div>
  </div>
);
