import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { load } from '../../../actions/result';

const mapStateToProps = ({ result }) => ({
  listFinished: result.listFinished,
  isLoading: result.isLoading,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  loadResults: load,
}, dispatch);

const AdditionalButton = ({ listFinished, isLoading, loadResults }) => (
  <div
    className="item"
    style={{
      paddingTop: 80,
      display: 'flex',
      justifyContent: 'center',
      background: 'none',
    }}
  >{listFinished
    ? <Link className="btn" to="/reisedaten">Neue Suche</Link>
    : (!isLoading && <button type="button" className="btn" onClick={loadResults}>Weitere Ergebnisse</button>)}
  </div>
);

AdditionalButton.propTypes = {
  listFinished: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadResults: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalButton);
