/* eslint-disable no-alert */
import "whatwg-fetch";
import buildQueryString from "build-query-string";
import { apiInternal, apiExternal, quid } from "../config";
// import { getCountries, getHotels } from './mockup';

const sendErrorAlarm = (message) =>
  fetch(`${apiExternal}/email/error`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ message }),
  });

const call = (url, method, body) =>
  fetch(url, {
    method: method || "GET",
    headers: { "Content-Type": "application/json" },
    ...(body ? { body: JSON.stringify(body) } : {}),
  })
    .then((r) => {
      if (r.status === 200) return r.json();

      const message = `Error<br>Incorrect status: ${r.status}<br>Url: ${url}`;
      sendErrorAlarm(message);

      alert(message.replace(/<br>/g, "\n"));
      return { error: true };
    })
    .catch((error) => {
      const message = `Error<br>Message: ${error}<br>Url: ${url}`;
      // eslint-disable-next-line no-console
      sendErrorAlarm(message).then((result) => console.log(result));
      alert(message.replace(/<br>/g, "\n"));
      return { error: true };
    });

const get = (path, params) => call(`${path}?${buildQueryString(params)}`);
const post = (path, params, data) =>
  call(`${path}?${buildQueryString(params)}`, "POST", data);

// 1st API
export const prepareCountries = (params) =>
  get(`${apiInternal}/api_v5/prepare_reqmylikes`, { quid, ...params });
export const loadPreparedCountries = (params) =>
  get(`${apiInternal}/api_v5/countries`, { quid, ...params });

export const loadCountries = (params) =>
  get(`${apiInternal}/api_v4/countries`, { quid, ...params });

export const loadGiatas = (selectionid, params) =>
  get(`${apiInternal}/api_v2/${selectionid}/hotelsnew`, params);
export const loadGiatasForCountry = (selectionid, params) =>
  get(`${apiInternal}/api_v4/${selectionid}/check_hotels`, params);
export const loadGiata = (giata, selectioncode) =>
  call(`${apiInternal}/api_v2/selectioncode/${selectioncode}/hotel/${giata}`);
export const loadParams = (selectioncode) =>
  call(`${apiInternal}/api_v2/selectioncode/${selectioncode}/params`);

// 2nd API
export const sendWishlist = (wishlist, email) =>
  post(`${apiExternal}/email/w`, { email }, { wishlist });
export const loadOffers = (params) => get(`${apiExternal}/offers`, params);
export const loadHotelReviews = (giata) =>
  call(`${apiExternal}/hotels/${giata}/reviews`);
