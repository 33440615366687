import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { unselectAll } from '../../actions/countries';

const Title = styled.div`
  padding: 0 60px;
  font-size: 24px;
  line-height: 1.4;

  span {
    color: #000;
  }

  @media(max-width: 900px) {
    padding: 0;
  }

  @media(max-width: 480px) {
    font-size: 22px;
  }
`;

const Buttons = styled.div`
  margin-top: 15px;

  .btn {
    float: none;
    margin: 10px auto;
    display: block;
    font-weight: 700;
  }

  @media(min-width: 900px) {
    display: flex;
    justify-content: space-around;
  }
`;

const BottomText = styled.div`
  margin: 30px 0 -30px;
  color: #000;
  text-align: center;
  font-size: 16px;

  span {
    text-decoration: underline;
  }
`;

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => bindActionCreators({
  reset: unselectAll,
}, dispatch);

const PopupZeroSelected = ({ isZeroMatches, reset, close }) => (
  <div className="popup">
    <div className="popup_block_inactivity">
      <button type="button" className="close_btn" onClick={close} />
      <Title>
        Bitte beachte: <span><b>Du hast eben ein Land aktiviert</b>,
        bei dem wir zu deinen aktuell gewählten Interessen und/oder Reisedaten keine Matches gefunden haben.</span>
      </Title>
      <Buttons>
        <Link to="/urlaubsinteressen" className="btn">Interessen modifizieren</Link>
        <Link to="/reisedaten" className="btn">Zeitraum/Budget modifizieren</Link>
        {!isZeroMatches && (
          <button className="btn" onClick={() => { reset(); close(); }}>Das Land wieder deaktivieren</button>
        )}
      </Buttons>
      <BottomText>
        <b>Tipp:</b> Bei der Suche nach <span>nur einem speziellen Reiseland</span> alle anderen Länder deaktiven.
      </BottomText>
    </div>
  </div>
);

PopupZeroSelected.propTypes = {
  close: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupZeroSelected);
