import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const mapStateToProps = ({ categories: { req } }) => ({
  req,
});

const ScoreBlock = ({ req, matchlist, mismatchlist, showScorePopup }) => {
  const orderedMathes = req
    ? matchlist
      .filter(({ id }) => id === req)
      .map(item => ({ ...item, isReq: true }))
      .concat(
        matchlist.filter(({ id }) => id !== req)
      )
    : matchlist;

    return (
      <div className="hotel_score">
        <button
          type="button"
          className="percent"
          onClick={() => showScorePopup(matchlist, mismatchlist)}
        >{matchlist.length}
        </button>
        <p className="matches_header"><b>Top-Treffer</b></p>
        <div className="clear10" />
        <p className="matches_interests_text">Übereinstimmungen mit euren Interessen:</p>
        <div className="clear" />
        <div className="score_block">
          <ul>{orderedMathes.slice(0, 3).map(({ id, name, isReq }) =>
            <li key={id} className={isReq ? 'red' : ''}>{name}</li>)}
          </ul>
          <ul>{orderedMathes.slice(3, 6).map(({ id, name }) =>
            <li key={id}>{name}</li>)}
          </ul>
          <ul>{orderedMathes.slice(6, 8).map(({ id, name }) =>
            <li key={id}>{name}</li>)}
            <li className="more">
              <button type="button" onClick={() => showScorePopup(matchlist, mismatchlist)}>Details / alle anzeigen</button>
            </li>
          </ul>
        </div>
      </div>
    );
};

ScoreBlock.propTypes = {
  matchlist: PropTypes.arrayOf(PropTypes.object).isRequired,
  mismatchlist: PropTypes.arrayOf(PropTypes.object).isRequired,
  showScorePopup: PropTypes.func.isRequired,
  req: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(ScoreBlock);
