import React from 'react';

export default () => (
  <React.Fragment>
    <p>
      16.1 Hinweise zu Hotelbuchungen
    </p>
    <p>
      Die auf der Website beschriebenen Hotelangebote werden durch die, bei der jeweiligen Buchung auf der Hotelseite deutlich
      dargestellten, Reiseveranstalter zur Verfügung gestellt (welche diese ihrerseits für das entsprechende Hotel oder dessen Betreiber
      vermitteln).
    </p>
    <p>
      Beispiele für Reiseveranstalter sind: DERTOUR, TUI, 5vorFlug etc.
    </p>
    <p>
      Hotels erheben gegebenenfalls zusätzliche örtliche Steuern, die nicht im Buchungssystem dargestellt werden können. Insbesondere
      fallen diese lokalen Steuern bei Hotelbuchungen in den USA, Kanada sowie den Vereinigten Emiraten und Rom an.
    </p>
    <p>
      Zudem wird beim Zeitpunkt der Buchung der tagesaktuelle Wechselkurs verwendet. Durch eventuelle Wechselkursschwankungen ist bei
      Zahlung vor Ort eine Differenz des zu zahlenden Betrags möglich.
    </p>
    <p>
      16.2 Hinweise zu Pauschalreisebuchungen
    </p>
    <p>
      Diese Produkte werden von den verschiedenen Veranstaltern angeboten, die bei der jeweiligen Buchung auf der Last-Minute- und
      Pauschalreise-Seite gut erkennbar sind.
    </p>
    <p>
      Die Traffics GMBH (Mollstraße 32 in 10249 Berlin) wickelt dabei den technischen Teil der Buchung ab. Etwaige Kosten bei Umbuchung,
      Rücktritt oder zu zahlende Teilreisevergütungen vor Reiseantritt richten sich allein nach den Bedingungen des jeweiligen
      Reiseveranstalters, die im Buchungsprozess angezeigt werden.
    </p>
  </React.Fragment>
);
