import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleCheckbox, toggle } from '../../../actions/duration';
import AcceptButton from '../AcceptButton';
import ListItem from './ListItem';

const mapStateToProps = ({ duration }) => ({
  list: duration.list,
  selected: duration.selected,
  isExpanded: duration.isExpanded,
});
const mapDispatchToProps = dispatch => bindActionCreators({
  select: toggleCheckbox,
  toggleBlock: toggle,
}, dispatch);

const Duration = ({
  list, selected, isExpanded, select, toggleBlock,
}) => {
  const selectedId = +Object.keys(selected)[0];
  const title = (list.find(item => item.id === selectedId) || {}).name || 'Bitte wählen';

  return (
    <div className={`content_block${isExpanded ? ' open' : ''} ${selectedId ? '' : 'content_block_empty'}`}>
      <header onClick={toggleBlock}>
        <p>
          <span>Urlaubs-Dauer</span>
        </p>
      </header>
      <div className="content_block_body">
        <button
          type="button"
          className={`empty_block${isExpanded ? ' hidden' : ''}`}
          onClick={toggleBlock}
        >
          <span>{title}</span>
          <i className="icon_down" />
        </button>
        <div className={`open_content_block headered${isExpanded ? '' : ' hidden'}`}>
          <button
            type="button"
            className="close_content_block_btn"
            onClick={toggleBlock}
          ><i className="icon_down" />
          </button>
          <div className="head" />
          <div className="open_content_block_body buttoned">
            <ul className="data_list">{list.map(item => (
              <ListItem
                key={item.id}
                {...item}
                isSelected={item.id === selectedId}
                select={select}
              />))}
            </ul>
          </div>
          <AcceptButton onAccept={toggleBlock} />
        </div>
      </div>
    </div>
  );
};

Duration.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any),
  selected: PropTypes.objectOf(PropTypes.bool),
  isExpanded: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired,
  toggleBlock: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Duration);
