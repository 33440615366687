export default class {
  constructor(key) {
    this.key = key;
  }

  get() {
    const storage = localStorage.getItem(this.key);
    return storage && JSON.parse(storage);
  }

  set(obj) {
    localStorage.setItem(this.key, JSON.stringify(obj));
  }

  update(obj) {
    this.set({ ...(this.get() || {}), ...obj });
  }
}
